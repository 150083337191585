import { BuildOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Select,
	Switch,
	Table,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './style.css';
import { currentUser, deleteAPI, getAPI, updateAPI } from '../../api/api';
import { isEmpty } from 'lodash';
type CreatePageProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>
}
const CreatePage: FC<CreatePageProps> = ({ setFullScreen }) => {
	const { application_uid } = useParams();
	const [pages] = Form.useForm();
	const [pageData, setPageData] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [pageID, setPageID] = useState('');
	const [pagesJson, setPagesJson] = useState('[]');
	const [workFlowData, setWorkFlowData] = useState<any>([]);
	const [workFlowActions, setWorkFlowActions] = useState<any>([]);

	const getWorkFlowActions = (value: string): void => {
		setLoading(true);
		getAPI(`/workflow/${value}`).then(res => {
			if (res.data.success) {
				const {data = []} = res.data;
				// eslint-disable-next-line no-negated-condition
				if (!isEmpty(data)) {
					const {wfActions = []} = data[0];
					const options = [];
					for (const item of wfActions) {
						options.push({
							label: item.name,
							value: item.action_id,
						});
					}

					setWorkFlowActions(options);
				} else {
					setWorkFlowActions([]);
				}
			} else {
				message.error('Something went wrong');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	const fetchData = (): void => {
		setLoading(true);
		if (application_uid) {
			getAPI(`/pages/${application_uid}`).then(res => {
				if (res.data.success) {
					const {data = []} = res.data;
					setPageData(data);
					getAPI('/workflow').then(res => {
						if (res.data.success) {
							const {data = []} = res.data;
							if (!isEmpty(data)) {
								const options = [];
								for (const item of data) {
									options.push({
										label: item.workflowName,
										value: item.workflowId,
									});
								}

								setWorkFlowData(options);
							}
						}
					}).catch(() => {
						message.error('Something went wrong');
						setLoading(false);
					});
				} else {
					message.error('Something went wrong');
				}

				setLoading(false);
			}).catch(() => {
				message.error('Something went wrong');
				setLoading(false);
			});
		} else {
			getAPI('/pages').then((res: any) => {
				if (res.data.success) {
					setPageData(res.data.data);
				} else {
					message.error('Something went wrong');
				}

				setLoading(false);
			}).catch(() => {
				message.error('Something went wrong');
				setLoading(false);
			});
		}
	};

	const onFinish = (values: any): void => {
		const {
			page_name = '',
			page_route = '',
			home_page = false,
			action_id = '',
		} = values;
		setLoading(true);
		let data: any = {
			application_uid,
			page_name,
			base_page_uid: '',
			page_route,
			home_page,
			is_active: 1,
			created_by: currentUser,
			modified_by: currentUser,
			action_id,
		};
		if (isEdit) {
			data = {...data, page_uid: pageID, page_json: pagesJson};
		} else {
			data = {...data, page_uid: uuid(), page_json: '[]'};
		}

		updateAPI('/pages', { data }).then((res: any) => {
			if (res.data.success) {
				fetchData();
				message.success('Page saved successfully');
			} else {
				message.error('Something went wrong');
			}

			pages.resetFields();
			setLoading(false);
			setIsModalOpen(false);
		}).catch(() => {
			message.error('Something went wrong');
			pages.resetFields();
			setLoading(false);
			setIsModalOpen(false);
		});
	};

	const handleEdit = (page: any): void => {
		const {
			pageName = '',
			pageRoute = '',
			pageUid = '',
			pageJson = '[]',
			action_id = '',
		} = page;
		const actionId = '';
		if (action_id) {
			getAPI(`/workflow?action_id=${action_id}`).then(res => {
				if (res.data.success) {
					// eslint-disable-next-line no-unused-vars
					const {data = []} = res.data;
				} else {
					message.error('Something went wrong');
				}

				setLoading(false);
			}).catch(() => {
				message.error('Something went wrong');
				setLoading(false);
			});
		}

		pages.setFieldsValue({
			page_name: pageName,
			page_route: pageRoute,
			action_id: actionId,
		});
		setPageID(pageUid);
		setPagesJson(pageJson);
		setIsEdit(true);
		setIsModalOpen(true);
	};

	const deletePage = (page_uid: string): void => {
		setLoading(true);
		const data = { page_uid };
		deleteAPI('/pages', { data }).then((res: any) => {
			if (res.data.success) {
				fetchData();
				message.success('Page deleted successfully');
			} else {
				message.error('Something went wrong');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	useEffect(() => {
		setFullScreen(true);
		fetchData();
	}, []);
	// eslint-disable-next-line max-len
	const dataSource = application_uid ? pageData.filter((item: any) => item.applicationUid === application_uid) : pageData;
	const columns = [
		{
			title: 'Page Name',
			dataIndex: 'pageName',
			width: '40%',
		},
		{
			title: 'Route',
			dataIndex: 'pageRoute',
			width: '25%',
		},
		{
			title: 'Design',
			dataIndex: '',
			width: '10%',
			render: (page: any) =>
				<Link
					style={{ color: 'inherit' }}
					to={`/page_creator/${page.pageUid}`}>
					<BuildOutlined key={page.pageUid} />
				</Link>,
		},
		{
			title: 'Edit',
			dataIndex: '',
			width: '10%',
			render: (page: any) =>
				<EditOutlined onClick={() => handleEdit(page)} />,
		},
		{
			title: 'Delete',
			dataIndex: '',
			width: '10%',
			render: (page: { pageUid: string }) => (
				<Popconfirm
					title='Are you sure to delete this page?'
					onConfirm={() => {
						deletePage(page.pageUid);
					}}
					onCancel={() => null}
					okText='Yes'
					cancelText='No'
				>
					<DeleteOutlined key={page.pageUid} />
				</Popconfirm>),
		},
	];
	return (
		<div className='pages dynamic-app-container'>
			<Row>
				<Col span={24}>
					<Button
						type='primary'
						icon={<PlusOutlined />}
						size='large'
						onClick={() => setIsModalOpen(true)}
						disabled={!application_uid}
					>
						Create Page
					</Button>
				</Col>
				<Col span={24} style={{ marginTop: '10px' }}>
					<Table
						loading={loading}
						dataSource={dataSource}
						columns={columns}
					/>
				</Col>
			</Row>
			<Modal
				title={`${isEdit ? 'Update Page' : 'Create Page'}`}
				open={isModalOpen}
				onCancel={() => {
					setIsModalOpen(false);
					pages.resetFields();
				}}
				footer={false}
			>
				<Col span={24}>
					<Form
						name='applications'
						autoComplete='off'
						layout='vertical'
						form={pages}
						onFinish={onFinish}
					>
						<Form.Item
							label='Page Name'
							name={'page_name'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please Enter App Name!' }]}
						>
							<Input placeholder='Enter App Name' />
						</Form.Item>
						<Form.Item
							label='Page Route'
							name={'page_route'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please input Application Basic Name!' }]}
						>
							<Input placeholder='Enter App Basic Name' />
						</Form.Item>
						<Form.Item
							label='Set As Home Page'
							name={'home_page'}
						>
							<Switch />
						</Form.Item>
						<Form.Item
							label='Workflow'
							name={'workflow'}
						>
							<Select
								options={workFlowData}
								onChange={getWorkFlowActions}
							/>
						</Form.Item>
						<Form.Item
							label='Actions'
							name={'action_id'}
						>
							<Select
								options={workFlowActions}
							/>
						</Form.Item>
						<Form.Item>
							<Col span={24}>
								<Row style={{
									justifyContent: 'end', gap: '10px'}}>
									<Button type='default'
										onClick={() => {
											setIsModalOpen(false);
											pages.resetFields();
										}}
									>
										Cancel
									</Button>
									<Button type='primary' htmlType='submit'>
										Submit
									</Button>
								</Row>
							</Col>
						</Form.Item>
					</Form>
				</Col>
			</Modal>
		</div>
	);
};

export default CreatePage;

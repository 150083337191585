import { Col, Switch } from 'antd';
import React, { FC } from 'react';
import Icon from '../icons/get-icon';

type SwitchCompProps = {
	properties: any;
	dataSource: any;
}

const SwitchComp: FC<SwitchCompProps> = ({properties}) => {
	const {
		label,
		toggle = {
			default_checked: false,
			checked_name: '',
			unchecked_name: '',
			checked_icon: '',
			unchecked_icon: '',
		},
	} = properties.column_properties;
	const {
		default_checked,
		checked_name,
		checked_icon,
		unchecked_name,
		unchecked_icon,
	} = toggle;
	return (
		<Col span={24} className='d-flex-column'>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...label.label_style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Switch
				checked={default_checked}
				// eslint-disable-next-line max-len
				checkedChildren={checked_name ? checked_name : <Icon type={checked_icon} />}
				// eslint-disable-next-line max-len
				unCheckedChildren={unchecked_name ? unchecked_name : <Icon type={unchecked_icon} />}
			/>
		</Col>
	);
};

export default SwitchComp;

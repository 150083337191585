import * as React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const MailContent = (props: any) => {
	const {data = [], handleChange} = props;
	return data.map((item: any) =>
		<FormControlLabel
			key={item.email}
			control={
				<Checkbox
					value={item.email}
					onChange={handleChange}
					defaultChecked={item.checked || false}
					checked={item.checked || false}
				/>}
			label={item.email} />);
};

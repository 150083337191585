import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Snackbar} from '@mui/material';
type MessageProps = {
    severity: 'error' | 'warning' | 'info' | 'success' ;
    message: string;
    open: boolean;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Alerts = ({severity, message, open}:MessageProps) => {
	const [isOpen, setIsOpen] = React.useState(open);
	return (
		<Stack sx={{width: '100%'}} spacing={2}>
			<Snackbar
				autoHideDuration={2000}
				open={isOpen}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				onClose={() => setIsOpen(false)}>
				<Alert variant='filled' severity={severity}>{message}</Alert>
			</Snackbar>
		</Stack>
	);
};

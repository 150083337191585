/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Profile from '../../Profile/Profile';
import { Grid } from '@mui/material';
import './Topbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getPath } from '../../shared/formatting';
import { capitalCase } from 'text-case';

export let profileTrackerRoute: any;
const Topbar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname } = location;
	profileTrackerRoute = getPath(pathname);
	const appName = capitalCase(profileTrackerRoute);

	const handleTitleClick = () => {
		if (profileTrackerRoute) {
			navigate(`/${profileTrackerRoute}`);
		}
	};

	return (
		<Grid container style={{ backgroundColor: '#fff', padding: '10px' }}>
			<Grid item xs={11}>
				<h1 onClick={handleTitleClick}
					style={{ cursor: 'pointer', width: 'fit-content' }} >
					{appName}
				</h1>
			</Grid>
			<Grid item xs={1} direction='row' justifyContent='flex-end' >
				<Profile />
			</Grid>
		</Grid>
	);
};

export default Topbar;

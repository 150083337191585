/* eslint-disable max-len */
import React, { Dispatch, SetStateAction, useEffect, useState, FC } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Widgets from './widgets/widgets';
import { Row, Col, Button, message, Spin, Switch } from 'antd';
import './style.css';
import EditorSection from './editor/editor-section';
import AddSection from './editor/add-section/add-section';
import { isEmpty } from 'lodash';
import Settings from './settings/setting';
import { widgets } from './widget-list';
import { useParams } from 'react-router-dom';
import { getAPI, updateAPI, currentUser } from '../api/api';
import { createPropetyForWidget, widget_type } from './properties-obj/widget-properties-obj';
import { createSection } from './properties-obj/properties-obj';
import { v4 as uuid } from 'uuid';

type PageCreatorProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>
};

const PageCreator: FC<PageCreatorProps> = ({ setFullScreen }) => {
	const { page_uid } = useParams();
	const [sectionValues, setSectionValues] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [pageRoute, setPageRoute] = useState('');
	const [propertiesType, setPropertiesType] = useState('');
	const [activeElement, setActiveElement] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [isCustomComponent, setCustomComponent] = useState(false);
	const [componentName, setComponentName] = useState('');
	const [componentIcon, setComponentIcon] = useState('');
	const [component, setComponent] = useState<{
		componentUid: string;
		componentName: string;
		componentIcon: string;
	}[]>([]);

	const handleOnDragEnd = (result: DropResult): void => {
		const { destination, source, draggableId } = result;

		if ((!destination) || (destination.droppableId === source.droppableId && destination.index === source.index)) {
			return;
		}

		const widgetElement: any = widgets.find(
			widget => widget.id === draggableId,
		);
		const componentElement: any = component.find(
			component => component.componentUid === draggableId,
		);

		const copySectionValues = sectionValues;
		const rowRecursion = (row: any): void => {
			row.forEach((row: any) => {
				row.column.forEach((col: any) => {
					if (destination.droppableId === col.column_uid) {
						if (widgetElement && widgetElement.type === 'form') {
							col.form_uid = col.column_uid;
							col.form_wrapper = true;
							setSectionValues(copySectionValues);
							message.success('Form Added Successfully');
							return;
						}

						if (widgetElement) {
							col.widget_uid = widgetElement?.id;
							col.widget_type = widgetElement?.type;
							col.column_properties[widgetElement?.type] = createPropetyForWidget(widgetElement.type);
							const keys = Object.keys(col.column_properties);
							const commonKeys = ['required', 'label', 'dataField', 'action'];
							for (const i of keys) {
								if (i !== widgetElement?.type && !(commonKeys.includes(i))) {
									delete col.column_properties[i];
								}
							}
						} else {
							col.widget_uid = componentElement.componentUid;
							col.widget_type = '';
							const componentJson = JSON.parse(componentElement.componentJson);
							col.row = [...componentJson.row];
						}
					} else if (widget_type.includes(col.widget_type)) {
						rowRecursion(col.column_properties[col.widget_type].row);
					} else if (col.row) {
						rowRecursion(col.row);
					}
				});
			});
		};

		copySectionValues.forEach((sec: { row: [] }) => rowRecursion(sec.row));
		setSectionValues(copySectionValues);
	};

	const fetchData = (): void => {
		setLoading(true);
		getAPI(`/pages/page-json/${page_uid}`).then((res: any) => {
			if (res.data.success) {
				const data = res.data.data[0];
				// eslint-disable-next-line no-negated-condition
				if (!isEmpty(JSON.parse(data.pageJson))) {
					setSectionValues(JSON.parse(data.pageJson));
				} else {
					setSectionValues([createSection()]);
				}
			} else {
				message.error('Something went wrong when loading data');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong when loading data');
			setLoading(false);
		});
		getAPI('/custom-components').then((res: any) => {
			const component = res.data.data;
			setComponent(component);
		});
	};

	const handleSaveSection = (): void => {
		setLoading(true);
		if (isCustomComponent) {
			const componentData = {
				component_uid: uuid(),
				component_name: componentName,
				component_icon: componentIcon,
				component_json: JSON.stringify(sectionValues[0]),
			};
			const user = {
				created_by: currentUser,
				modified_by: currentUser,
			};
			getAPI(`/pages/page-json/${page_uid}`).then((res: any) => {
				const application_uid = res.data.data[0].applicationUid;
				updateAPI('/custom-components/create', { componentData, application_uid, user }).then((res: any) => {
					if (res) {
						message.success('Component Created Successfully');
					} else {
						message.error('Something went wrong');
					}

					setLoading(false);
				}).catch(() => {
					message.error('Something went wrong when loading data');
					setLoading(false);
				});
			}).catch(() => {
				message.error('Something went wrong when loading data');
				setLoading(false);
			});
		} else {
			const data = {
				page_uid,
				page_json: JSON.stringify(sectionValues),
			};
			updateAPI('/pages/page-json', { data }).then((res: any) => {
				if (res.data.success) {
					fetchData();
					message.success('Page saved successfully');
				} else {
					message.error('Something went wrong while saving sections');
				}

				setLoading(false);
				// Navigate(-1);
			}).catch(() => {
				message.error('Something went wrong while saving sections');
				setLoading(false);
			});
		}
	};

	useEffect(() => {
		setFullScreen(true);
		fetchData();
	}, []);

	return (
		<Spin spinning={loading}>
			<Row style={{ padding: '0' }}>
				<Col
					className='inner-container form-creator'
					span={24}
					style={{ display: 'flex' }}>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Col span={4} className='pad'>
							<Col span={24} className='card'>
								<Widgets widgets={widgets} component={component} identifier='elements' />
							</Col>
						</Col>
						<Col span={15} className='pad'>
							<Col span={24}>
								<div className='editor'>
									<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Col style={{ display: 'flex', alignItems: 'center' }}>
											<Switch
												checked={isCustomComponent}
												onChange={() => setCustomComponent(!isCustomComponent)} />
											Create Components
										</Col>
										<Col style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
											<Button type='default' size='large'>Cancel</Button>
											<Button
												type='primary'
												size='large'
												onClick={handleSaveSection}
											>Save</Button>
										</Col>
									</Col>
									<Col span={24} className='elements'>
										{
											(sectionValues && !isEmpty(sectionValues)) && (
												<div className='card'>
													<Row>
														{
															sectionValues.map((value: any, index: number) => (
																<EditorSection
																	value={value}
																	key={index}
																	setActiveElement={setActiveElement}
																	setPropertiesType={setPropertiesType}
																	setSectionValues={setSectionValues}
																	sectionValues={sectionValues}
																/>
															))
														}
													</Row>
												</div>
											)
										}
										<AddSection
											sectionValues={sectionValues}
											setSectionValues={setSectionValues}
										/>
									</Col>
								</div>
							</Col>
						</Col>
						<Col span={5} className='pad'>
							<Col span={24}>
								<div className='settings'>
									<div className='card'>
										<Col
											className='card-title'
											span={24}
											style={{ padding: '7px 10px' }}
										>
											<p>Properties</p>
										</Col>
										{
											(sectionValues && !isEmpty(sectionValues)) && (
												(activeElement) ? (
													sectionValues.map((value: { section_uid: string }, index: number) => {
														if (((propertiesType === 'section') && (value.section_uid === activeElement.element.section_uid)) || (value.section_uid === activeElement?.section_uid)) {
															return (
																<Settings
																	value={value}
																	key={index}
																	setSectionValues={setSectionValues}
																	sectionValues={sectionValues}
																	activeElement={activeElement}
																	propertiesType={propertiesType}
																	pageRoute={pageRoute}
																	isCustomComponent={isCustomComponent}
																	componentName={componentName}
																	componentIcon={componentIcon}
																	setComponentName={setComponentName}
																	setComponentIcon={setComponentIcon}
																/>
															);
														}

														return null;
													})
												) : (
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															minHeight: '100px',
															height: '100%',
															width: '100%',
														}}>
														<h3>Select Section</h3>
													</div>
												)
											)
										}
									</div>
								</div>
							</Col>
						</Col>
					</DragDropContext>
				</Col>
			</Row>
		</Spin>
	);
};

export default PageCreator;

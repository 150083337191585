import {
	CheckOutlined,
	CopyOutlined,
	DeleteOutlined,
	MenuOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import {
	Popover,
	Row,
	Tooltip,
} from 'antd';
import { isEmpty } from 'lodash';
import React, {useState, Dispatch, SetStateAction, FC} from 'react';
import EditorColumn from './editor-column';

type EditorRowProps = {
    indexRow: number;
    row: any;
    getActiveRowColumn: any;
    addRowColumn: any;
	deleteColumn: any;
	deleteRow: any;
	dataSource: any;
	setPropertiesType: Dispatch<SetStateAction<string>>;
}

const EditorRow: FC<EditorRowProps> = (
	{
		indexRow,
		row,
		getActiveRowColumn,
		deleteColumn,
		deleteRow,
		addRowColumn,
		dataSource,
		setPropertiesType,
	}) => {
	const style = row.row_style;
	const [showIcon, setShowIcon] = useState(false);

	return (
		<div
			key={indexRow}
			className={showIcon ? 'editor-row show' : 'editor-row'}
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				setPropertiesType('row');
				getActiveRowColumn(row.row_uid, 'row');
			}}
			onMouseOver = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(true);
				}
			}
			onMouseOut = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(false);
				}
			}
		>
			<Row
				key={indexRow}
				id={row.row_uid}
				style={{
					...style,
					width: '100%',
				}}
			>
				<div
					className='icon row'
					style={{
						display: showIcon ? 'block' : 'none',
					}}
				>
					<Popover placement='top' trigger='hover' content={(
						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px', cursor: 'pointer',
						}}>
							<span>
								<Tooltip title='Add Column' placement='topLeft'>
									<PlusOutlined onClick={() => addRowColumn({
										element_uid: row.row_uid,
										type: 'add-column',
									})} />
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Delete Row' placement='topLeft'>
									<DeleteOutlined onClick={
										() => deleteRow(row.row_uid)}
									/>
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Select Row' placement='topLeft'>
									<CheckOutlined onClick={() => {
										setPropertiesType('row');
										getActiveRowColumn(row.row_uid, 'row');
									}} />
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Copy Row' placement='topLeft'>
									<CopyOutlined onClick={() => addRowColumn({
										element_uid: row.row_uid,
										type: 'copy-row',
									})} />
								</Tooltip>
							</span>
						</div>
					)}>
						<MenuOutlined />
					</Popover>
				</div>
				{(row.column && !isEmpty(row.column))
				&& row.column.map((col: any, indexCol: number) => (
					<EditorColumn
						col={col}
						key={indexCol}
						indexCol={indexCol}
						col_count={row.column.length}
						getActiveRowColumn={getActiveRowColumn}
						dataSource={dataSource}
						deleteColumn={deleteColumn}
						deleteRow={deleteRow}
						addRowColumn={addRowColumn}
						setPropertiesType={setPropertiesType}
					/>
				),
				)}
			</Row>
		</div>
	);
};

export default EditorRow;

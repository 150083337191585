import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber, Popover } from 'antd';
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
	FC,
} from 'react';
import PaddingImg from '../../../assets/img/padding.png';

type PaddingProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const Padding: FC<PaddingProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	// eslint-disable-next-line max-len
	const {padding = '0px 0px 0px 0px'} = activeElement.element[`${type}_style`];
	const colPadd = padding.split(' ');
	const [
		paddingTop,
		setPaddingTop,
	] = useState(parseInt(colPadd[0].replace('px', ''), 10));
	const [
		paddingRight,
		setPaddingRight,
	] = useState(parseInt(colPadd[1].replace('px', ''), 10));
	const [
		paddingBottom,
		setPaddingBottom,
	] = useState(parseInt(colPadd[2].replace('px', ''), 10));
	const [
		paddingLeft,
		setPaddingLeft,
	] = useState(parseInt(colPadd[3].replace('px', ''), 10));
	const handlePadding = (value: number | null, position: string): any => {
		if (value !== null && position) {
			// eslint-disable-next-line default-case
			switch (position) {
				case 'top': setPaddingTop(value);
					break;
				case 'right': setPaddingRight(value);
					break;
				case 'bottom': setPaddingBottom(value);
					break;
				case 'left': setPaddingLeft(value);
					break;
			}
		} else {
			return null;
		}
	};

	useEffect(() => {
		// eslint-disable-next-line max-len
		const padding = `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
		setChangeType('padding');
		setValue(padding);
	}, [
		paddingTop,
		paddingRight,
		paddingBottom,
		paddingLeft,
	]);

	return (
		<table>
			<thead>
				<tr>
					<th colSpan={2}>
						<div style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'center',
							gap: '10px',
						}}>
							<p>Padding</p>
							<Popover
								content={<img
									src={PaddingImg}
									alt='padding'
									width='300px' />}
								trigger='hover'
							>
								<ExclamationCircleOutlined />
							</Popover>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Top</td>
					<td>
						<InputNumber min={0}
							value={paddingTop}
							onChange={
								(e: number | null) => handlePadding(e, 'top')
							}
						/>
					</td>
				</tr>
				<tr>
					<td>Right</td>
					<td>
						<InputNumber min={0}
							value={paddingRight}
							onChange={
								(e: number | null) => handlePadding(e, 'right')
							}
						/>
					</td>
				</tr>
				<tr>
					<td>Bottom</td>
					<td>
						<InputNumber min={0}
							value={paddingBottom}
							onChange={
								(e: number | null) => handlePadding(e, 'bottom')
							}
						/>
					</td>
				</tr>
				<tr>
					<td>Left</td>
					<td>
						<InputNumber min={0}
							value={paddingLeft}
							onChange={
								(e: number | null) => handlePadding(e, 'left')
							}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default Padding;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card } from 'antd';
import { startCase } from 'lodash';
import * as React from 'react';

const CardComponent = (props: any) => {
	const { result, path } = props;
	const renderAsset = () => (
		<>
			<Card style={{ minWidth: 250 }}
				title={<a>{result.name}</a>}
				actions={[<div
					style={
						{ color: '#1890ff'}}
					key='h1'>
					{result.status}</div>]}>
				<p
					className
						='text-ellipsis'>
					<i
						className='fa fa-regular fa-phone icon-padding-rigt' />
					{result.serialNumber}</p>
				<p className='text-ellipsis'>
					<i className='fa fa-regular fa-envelope icon-padding-rigt' />
					{result.assetNumber}</p>
				<p className='text-ellipsis'>
					<i className='fa fa-regular fa-suitcase  icon-padding-rigt'>
					</i>{result.assetGroup}</p>
			</Card>
		</>
	);

	const renderProfile = () => (
		<>
			<Card style={{ maxWidth: 250}}
				title={<a>{startCase(result.name)}</a>}
				actions={[<div
					style={
						{ color: '#1890ff'}}
					key='h1'>
					{result.status}</div>]}>
				<p
					className
						='text-ellipsis'>
					<i
						className='fa fa-regular fa-phone icon-padding-rigt' />
					{result.mobileNumber}</p>
				<p className='text-ellipsis'>
					<i className='fa fa-regular fa-envelope icon-padding-rigt' />
					{result.emailId}</p>
				<p className='text-ellipsis'>
					<i className='fa fa-regular fa-suitcase  icon-padding-rigt'>
					</i>{result.positionApplied}</p>
			</Card>
		</>
	);

	const renderDevopsService = () => (
		<>
			<Card style={{ minWidth: 250 }}
				title={<a>{startCase(result.projectName)}</a>}
				actions={[<div
					style={
						{ color: '#1890ff' }}
					key='h1'>
					{result.status}</div>]}>
				<p
					className
						='text-ellipsis'>
					<i
						className='fa fa-regular fa-phone icon-padding-rigt' />{result.projectName}</p>
				<p className='text-ellipsis'><i className='fa fa-regular fa-envelope icon-padding-rigt' />{result.description}</p>
			</Card>
		</>
	);

	const renderUi: any = {
		'asset-tracker': renderAsset(),
		'profile-tracker': renderProfile(),
		'tfw-tracker': renderAsset(),
		'devops-service-request': renderDevopsService(),
	};

	return (
		<>
			{renderUi[path]}
		</>
	);
};

export default CardComponent;

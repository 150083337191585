/* eslint-disable max-len */
import { Col, Row, Switch } from 'antd';
import { isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { widget_type } from '../../../properties-obj/widget-properties-obj';
import BackgroundColor from './background-color';
import Border from './border';
import BorderRadius from './border-radius';
import ColumnIterableProps from './column_iterable';
import DataField from './data-field';
import FieldName from './fieldName';
import Margin from './margin';
import Padding from './padding';
import FieldRequired from './required';
import Width from './width';
import { common_styles } from '../../../properties-obj/properties-obj';
import Height from './height';
import Position from './position';

type CommonPropertiesProps = {
	setSectionValues: Dispatch<SetStateAction<any>>;
	sectionValues: any;
	activeElement: any;
	type: string;
}

const CommonProperties = ({
	setSectionValues,
	sectionValues,
	activeElement,
	type,
}: CommonPropertiesProps): any => {
	const {show_style = false} = activeElement.element[`${type}_style`];
	const [showStyle, setShowStyle] = useState(show_style);
	const [changeType, setChangeType] = useState('');
	const [value, setValue] = useState<string | number | string[] | null | boolean>(null);

	const handleChange = (value: boolean): void => {
		setShowStyle(value);
		setChangeType('show_style');
		setValue(value);
	};

	useEffect(() => {
		if (changeType) {
			if (type === 'column') {
				setSectionValues(
					sectionValues.map((sec:any) => {
						const columRecursion = (row: any):any => {
							row.forEach((row: any) => {
								row.column.forEach((col: any) => {
									if (col.column_uid === activeElement.element.column_uid) {
										const types = ['field_name', 'data_field', 'column_iterable', 'odd_bg', 'even_bg', 'required'];
										if (types.includes(changeType)) {
											if (['column_iterable', 'odd_bg', 'even_bg'].includes(changeType)) {
												col.column_properties.iterable[changeType] = value;
											} else {
												col.column_properties[changeType] = value;
											}
										} else if (changeType === 'show_style') {
											col.column_style[changeType] = value;
											col.column_style = {...col.column_style, ...common_styles};
										} else {
											col.column_style[changeType] = value;
										}
									} else if (widget_type.includes(col.widget_type)) {
										if (col.column_properties[col.widget_type].row) {
											columRecursion(col.column_properties[col.widget_type].row);
										}
									} else if (col.row) {
										columRecursion(col.row);
									}
								});
							});
						};

						columRecursion(sec.row);

						return sec;
					}),
				);
			} else if (type === 'row') {
				setSectionValues(
					sectionValues.map((sec: any) => {
						const rowRecursion = (row: any): any => {
							row.forEach((row: any) => {
								if (row.row_uid === activeElement.element.row_uid) {
									if (changeType === 'show_style') {
										row.row_style[changeType] = value;
										row.row_style = {...row.row_style, ...common_styles};
									} else {
										row.row_style[changeType] = value;
									}
								} else if (row.column) {
									row.column.forEach((col: any) => {
										if (widget_type.includes(col.widget_type)) {
											if (col.column_properties[col.widget_type].row) {
												rowRecursion(col.column_properties[col.widget_type].row);
											}
										} else if (col.row) {
											rowRecursion(col.row);
										}
									});
								}
							});
						};

						rowRecursion(sec.row);

						return sec;
					}),
				);
			} else if (type === 'section') {
				setSectionValues(
					sectionValues.map((sec: any) => {
						if (sec.section_uid === activeElement.element.section_uid) {
							if (changeType === 'show_style') {
								sec.section_style[changeType] = value;
								sec.section_style = {...sec.section_style, ...common_styles};
							} else {
								sec.section_style[changeType] = value;
							}
						}

						return sec;
					}),
				);
			}
		}
	}, [changeType, value]);
	return (
		<Row>
			{
				!isEmpty(activeElement.element) && (
					<>
						<Col span={24}>
							{
								(type === 'column') && (
									<>
										<DataField
											key={`dataField_${activeElement.element[`${type}_uid`]}`}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
											type={type}
										/>
										{
											(!activeElement.element.widget_type) && (
												<ColumnIterableProps
													key={`column_iterable_${activeElement.element[`${type}_uid`]}`}
													setChangeType={setChangeType}
													activeElement={activeElement}
													setValue={setValue}
													type={type}
												/>
											)
										}
										{
											(activeElement.element.widget_type) && (
												<>
													<FieldName
														key={`field_name_${activeElement.element[`${type}_uid`]}`}
														setChangeType={setChangeType}
														activeElement={activeElement}
														setValue={setValue}
														type={type}
													/>
													<FieldRequired
														key={`required_${activeElement.element[`${type}_uid`]}`}
														setChangeType={setChangeType}
														activeElement={activeElement}
														setValue={setValue}
														type={type}
													/>
												</>
											)
										}
									</>
								)
							}
							<table>
								<tbody>
									<tr>
										<td>
											<span>Show Styles</span>
										</td>
										<td>
											<Switch
												onChange={handleChange}
												checked={showStyle}
											/>
										</td>
									</tr>
									<tr>
										<td colSpan={2} style={{padding: '0'}}>
											{
												(showStyle && type) && (
													<Col span={24}>
														<Col span={24}>
															<Padding
																key={`padding_${activeElement.element[`${type}_uid`]}`}
																setChangeType={setChangeType}
																activeElement={activeElement}
																setValue={setValue}
																type={type}
															/>
														</Col>
														<Col span={24}>
															<Margin
																key={`margin_${activeElement.element[`${type}_uid`]}`}
																setChangeType={setChangeType}
																activeElement={activeElement}
																setValue={setValue}
																type={type}
															/>
														</Col>
														<Col span={24}>
															<BorderRadius
																key={`b_radius_${activeElement.element[`${type}_uid`]}`}
																setChangeType={setChangeType}
																activeElement={activeElement}
																setValue={setValue}
																type={type}
															/>
														</Col>
														<Col span={24}>
															<BackgroundColor
																key={`bg_color_${activeElement.element[`${type}_uid`]}`}
																setChangeType={setChangeType}
																activeElement={activeElement}
																setValue={setValue}
																type={type}
															/>
														</Col>
														<Col span={24}>
															<Border
																key={`border_${activeElement.element[`${type}_uid`]}`}
																setChangeType={setChangeType}
																activeElement={activeElement}
																setValue={setValue}
																type={type}
															/>
														</Col>
														{
															(type === 'column' || type === 'row') && (
																<>
																	<Height
																		key={`height_${activeElement.element[`${type}_uid`]}`}
																		setChangeType={setChangeType}
																		activeElement={activeElement}
																		setValue={setValue}
																		type={type} /><Position
																		key={`position_${activeElement.element[`${type}_uid`]}`}
																		setChangeType={setChangeType}
																		activeElement={activeElement}
																		setValue={setValue}
																		type={type}
																	/>
																</>
															)
														}
														{
															(type === 'column' || type === 'section') && (
																<Width
																	key={`width_${activeElement.element[`${type}_uid`]}`}
																	setChangeType={setChangeType}
																	activeElement={activeElement}
																	setValue={setValue}
																	type={type}
																/>
															)
														}
													</Col>
												)
											}
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</>
				)
			}
		</Row>
	);
};

export default CommonProperties;


import * as React from 'react';
import { Table } from 'antd';
import { startCase } from 'lodash';
import { SizeType } from 'antd/es/config-provider/SizeContext';
interface iprop {
	data: ({})[],
	columnDef: ({})[],
	size: SizeType,
	bordered: boolean,
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const TableComp = (props: iprop) => {
	const { data, size, bordered, columnDef } = props;
	const column = data.length
		? Object.keys(data[0]).map(val => (
			{
				title: startCase(val),
				dataIndex: val,
				key: val,
				render: (text: String) => <div>{text ? text : 'NA'}</div>,
			}
		))
		: [];

	return (
		<>
			<Table columns={columnDef || column}
				dataSource={data}
				bordered={bordered}
				size={size}
				scroll={{ x: true }}
				style={{width: '100%'}} />
		</>
	);
};

TableComp.defaultProps = {
	bordered: true,
	size: 'small',
};

export default TableComp;

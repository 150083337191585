/* eslint-disable complexity */

/* eslint-disable max-len */

import React, { useState, useEffect } from 'react';
import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import { camelCase, capitalCase } from 'text-case';
import logo from '../icons/logo.svg';
import TextFieldComp from '../shared/components/textfield/textfield';
import './style.css';
import { AttachFile } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { get } from 'lodash';
import { lookupActionResponse } from '../api/lookup-response';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const EditForm = (props: any) => {
	const { name = '', loading, error, disabled = false }: any = props;
	const [formValues, setFormValues] = useState<any | []>();
	const [form, setForms] = useState(props.form || []);
	const [fileNames, setFileNames] = useState<any | {}>();
	const [loaded, setloaded] = useState(false);
	const obj: any = {};

	useEffect(() => {
		// eslint-disable-next-line array-callback-return
		form?.map((item: any) => {
			obj[camelCase(item.fieldName)] = '';
		});

		setloaded(true);
		const args = {
			form,
			setform: setForms,
			loader: setloaded,
		};
		lookupActionResponse(args);
		setFormValues(obj);
	}, []);

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.currentTarget.value.trim();
		const newFormData = { ...formValues };
		newFormData[fieldName] = fieldValue;
		setFormValues(newFormData);
	};

	const handleSelectChange = (e: SelectChangeEvent): void => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		const newFormData = { ...formValues };
		newFormData[fieldName] = fieldValue;

		setFormValues(newFormData);
	};

	const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const { files }: any = e.currentTarget;
		const fieldName = e.currentTarget.name;
		const newFormData = { ...formValues };
		newFormData[fieldName] = files[0];
		setFileNames({ ...fileNames, [fieldName]: files[0].name });
		setFormValues(newFormData);
	};

	const handleFileDelete = (fieldName: any): void => {
		const newFormData = { ...formValues };
		const filename = { ...fileNames };
		delete newFormData[fieldName];
		delete filename[fieldName];
		setFormValues(newFormData);
		setFileNames(filename);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleKeyDown = (event: any) => {
		const { key } = event;
		const validChars = /^[\w\s@.-]+$/;

		if (!validChars.test(key)) {
			event.preventDefault();
		}
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleDateChange = (e: any, name: string) => {
		const date = moment(get(e, '$d')).format('YYYY-MM-DD');
		setFormValues({ ...formValues, [name]: date });
	};

	useEffect(() => {
		const doc: any = document;
		document.addEventListener('wheel', () => {
			if (get(document, 'activeElement.type') === 'number') {
				doc.activeElement?.blur();
			}
		});
		return () => {
			document.removeEventListener('wheel', () => {
				if (get(document, 'activeElement.type') === 'number') {
					doc.activeElement?.blur();
				}
			});
		};
	}, []);

	const editFormConditionally = name === 'Profile Tracker';
	const className = name === 'Profile Tracker' ? 'form-container-profile' : 'form-container';
	if (loaded) {
		return <></>;
	}

	return (
		<>
			{editFormConditionally
				&& <Grid
					style={{ display: 'flex', justifyContent: 'space-between' }}
					className={className}>
					<h2>Profile Tracker</h2>
					<IconButton color='primary' aria-label='upload picture' component='span'>
						<Close onClick={() => props.onClose(formValues)} />
					</IconButton >
				</Grid>
			}
			{!editFormConditionally && <div className='form-title' style={{ display: 'flex', alignItems: 'center', gap: '12rem' }}>
				<div style={{ width: '68px', height: '40px' }}>
					<img style={{ width: '100%', height: '100%', objectFit: 'fill' }} src={logo} />
				</div>
				<div>
					<h2>{name}</h2>
				</div>
			</div>}
			{form.map((item: any, i: number) => (
				<Grid container key={i} className={className}>
					{(item.fieldProps.type === 'text' || item.fieldProps.type === 'email')
						? (
							<Grid item xs={12}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
									<TextFieldComp {...item.fieldProps} className='form-group-input' name={camelCase(item.fieldName)} placeholder={`Enter ${item.fieldName}`} onChange={handleChange}
										onKeyDown={handleKeyDown} />
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'number' || item.fieldProps.type === 'tel')
						? (
							<Grid item xs={12}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
									<TextFieldComp {...item.fieldProps} className='form-group-input input-scroll' name={camelCase(item.fieldName)} placeholder={`Enter ${item.fieldName}`} onChange={handleChange}
										onScroll={e => e.preventDefault()} />
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'date')
						? (
							<Grid item xs={12}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker label='' value={get(formValues, `${camelCase(item.fieldName)}`, null)} onChange={(e: any) => handleDateChange(e, camelCase(item.fieldName))}
											className='form-group-input'
											inputFormat='DD/MM/YYYY'
											renderInput={params => <TextField {...params} error={false}/>} />
									</LocalizationProvider>
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'fileupload')
						? (<Grid item xs={12}>
							<Card key={item.key}>
								<CardContent>
									<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
									{!get(fileNames, camelCase(item.fieldName)) && <label htmlFor='icon-button-file'>
										<input className='display-none' name={camelCase(item.fieldName)} onChange={handleFileChange} accept='*' id='icon-button-file' type='file' />
										{<IconButton color='primary' aria-label='upload picture' component='span'>
											<AttachFile />
										</IconButton>}
									</label>}
									{get(fileNames, camelCase(item.fieldName), '') && <span style={{ paddingLeft: '10px' }}>
										{get(fileNames, camelCase(item.fieldName), '')}
										<IconButton aria-label='upload picture' onClick={() => handleFileDelete(camelCase(item.fieldName))} component='span'>
											<Close />
										</IconButton>
									</span>}
								</CardContent>
							</Card>
						</Grid>
						)
						: null}
					{(item.fieldProps.type === 'textarea')
						? (
							<Grid item xs={12} key={item.key}>
								<FormGroup>
									<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
									<TextFieldComp {...item.fieldProps} name={camelCase(item.fieldName)} placeholder={`Enter ${item.fieldName}`} multiline={true} minRows={4} onChange={handleChange} />
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'radio')
						? (<Grid item xs={12}>
							<FormGroup>
								<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
								<RadioGroup {...item.fieldProps} row name={camelCase(item.fieldName)} onChange={handleChange} value={get(formValues, `${camelCase(item.fieldName)}`, null)}
									className='radio-group'>
									{item.options.map((val: any) => (
										<>
											<FormControlLabel name={camelCase(item.fieldName)} control={<Radio />} label={camelCase(val.name)} value={val.value}/>
										</>
									))}
								</RadioGroup>
								{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
							</FormGroup>
						</Grid>
						)
						: null}
					{(item.fieldProps.type === 'checkbox')
						? (<Grid item xs={12}>
							<Card>
								<CardContent>
									<FormGroup>
										<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
										{item.value.map((val: any) => (
											<>
												<FormControlLabel value={val} name={camelCase(item.fieldName)} control={<Checkbox onChange={handleChange} />} label={val} />
											</>
										))}
										{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
									</FormGroup>
								</CardContent>
							</Card>
						</Grid>
						)
						: null}
					{(item.fieldProps.type === 'select')
						? (<Grid item xs={12}>
							<FormGroup>
								<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
								<Select name={camelCase(item.fieldName)} onChange={handleSelectChange} className='dropDown' placeholder={`Enter ${item.fieldName}`}>
									{(item.value || []).map((val: any) => (
										// eslint-disable-next-line react/jsx-key
										<MenuItem value={val}>{val}</MenuItem>
									))}
								</Select>
								{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
							</FormGroup>
						</Grid>
						)
						: null}
				</Grid>
			))}

			{!loading
				&& <Grid
					style={{ textAlign: 'end' }}
					className={className}>
					{error && <p className='form-error'>Please Fill All Required Fields</p>}
					{!disabled && <Button variant='contained' onClick={() => props.onClick(formValues)}>Save</Button>}
				</Grid>
			}
		</>
	);
};

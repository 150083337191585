import {
	LoadingOutlined,
	SmileOutlined,
	SolutionOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Steps } from 'antd';
import React, { FC } from 'react';

type StepsCompProps = {
	properties: any;
	dataSource: any;
}

const StepsComp: FC<StepsCompProps> = () => (
	<Steps
		items={[
			{
				title: 'Login',
				status: 'finish',
				icon: <UserOutlined />,
			},
			{
				title: 'Verification',
				status: 'finish',
				icon: <SolutionOutlined />,
			},
			{
				title: 'Pay',
				status: 'process',
				icon: <LoadingOutlined />,
			},
			{
				title: 'Done',
				status: 'wait',
				icon: <SmileOutlined />,
			},
		]}
	/>

);

export default StepsComp;

/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { widget_type } from '../../properties-obj/widget-properties-obj';
import LabelStyle from './label/label';

type ModalPropertiesProps = {
	setSectionValues: Dispatch<SetStateAction<any>>;
	sectionValues: any;
	activeElement: any;
}

const ModalProperties: FC<ModalPropertiesProps> = ({
	setSectionValues,
	activeElement,
	sectionValues,
}) => {
	const {modal} = activeElement.element.column_properties;
	const {
		modal_width = '',
		cancel_text = '',
		ok_text = '',
		footer = false,
	} = modal;
	const [modalFooter, setModalFooter] = useState(footer);
	const [modalCancelText, setModalCancelText] = useState(cancel_text);
	const [modalOkText, setModalOkText] = useState(ok_text);
	const [modalWidth, setModalWidth] = useState(modal_width);
	const [changeType, setChangeType] = useState('');

	useEffect(() => {
		setSectionValues(
			sectionValues.map((sec: any) => {
				const columnRecursion = (rows: any): void => {
					rows.forEach((row: any) => {
						row.column.forEach((col: any) => {
							const obj: any = {
								footer: modalFooter,
								modal_width: modalWidth,
								cancel_text: modalCancelText,
								ok_text: modalOkText,
							};
							// eslint-disable-next-line max-len
							if (col.column_uid === activeElement.element.column_uid) {
								// eslint-disable-next-line max-len
								col.column_properties.modal[changeType] = obj[changeType];
							} else if (widget_type.includes(col.widget_type)) {
								// eslint-disable-next-line max-len
								if (col.column_properties[col.widget_type].row) {
									// eslint-disable-next-line max-len
									columnRecursion(col.column_properties[col.widget_type].row);
								}
							} else if (col.row) {
								columnRecursion(col.row);
							}
						});
					});
				};

				if (sec.section_uid === activeElement.section_uid) {
					columnRecursion(sec.row);
				}

				return sec;
			}),
		);
	}, [
		modalCancelText,
		modalFooter,
		modalOkText,
		modalWidth,
	]);

	return (
		<div>
			<Row>
				<Col span={24}>
					<table>
						<thead>
							<tr>
								<th colSpan={2}>Modal</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={2} style={{padding: '0'}}>
									<LabelStyle
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	);
};

export default ModalProperties;

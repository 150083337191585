/* eslint-disable max-len */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Row, Col,
	Switch, Input,
	InputNumber, Radio,
	RadioChangeEvent } from 'antd';
import { uploadFile } from '../../../api/api';
type SettingsProps = {
  layoutProperties: any;
  propertyType: string;
  setLayoutProperties: Dispatch<SetStateAction<any>>;
};

const Settings = ({	layoutProperties, setLayoutProperties,
	propertyType }: SettingsProps): any => {
	const {
		layout_type,
		logo,
		menu,
		header,
		sider,
		footer,
		show_breadcrumb } = layoutProperties;
	const [showHeader, setHeader] = useState(header.show_header);
	const [showHeaderMenu, setHeaderMenu] = useState(header.show_header_menu);
	const [appLogo, setLogo] = useState(logo.app_logo);
	const [logoChoosenMethod, setLogoChoosenMethod] = useState(1);
	const [headerHeight, setHeaderHeight] = useState(header.header_height);
	const [logoHeight, setLogoHeight] = useState(logo.logo_height);
	const [logoWidth, setLogoWidth] = useState(logo.logo_width);
	const [menuWidth, setMenuWidth] = useState(menu.menu_width);
	const [siderWidth, setSiderWidth] = useState(sider.sider_width);
	const [isBreadcrumb, setBreadcrumb] = useState(show_breadcrumb);
	const [showFooter, setFooter] = useState(footer.show_footer);
	const [changeType, setChangeType] = useState('');
	const [padding, setLogoPadding] = useState(logo.logo_style.padding);
	const colPadd = padding.split(' ');
	const [paddingTop, setPaddingTop] = useState(parseInt(colPadd[0].replace('px', ''), 10));
	const [paddingRight, setPaddingRight] = useState(parseInt(colPadd[1].replace('px', ''), 10));
	const [paddingBottom, setPaddingBottom] = useState(parseInt(colPadd[2].replace('px', ''), 10));
	const [paddingLeft, setPaddingLeft] = useState(parseInt(colPadd[3].replace('px', ''), 10));

	const handlePadding = (value: number | null, position: string): any => {
		if (value !== null && position) {
			// eslint-disable-next-line default-case
			switch (position) {
				case 'top': setPaddingTop(value);
					break;
				case 'right': setPaddingRight(value);
					break;
				case 'bottom': setPaddingBottom(value);
					break;
				case 'left': setPaddingLeft(value);
					break;
			}

			setChangeType('padding');
		} else {
			return null;
		}
	};

	const toggleHeaderProperties = (value: boolean): any => {
		setChangeType('showHeader');
		setHeader(value);
	};

	const toggleFooterProperties = (value: boolean): any => {
		setChangeType('showFooter');
		setFooter(value);
	};

	const toggleBreadcrumbProperties = (value: boolean): any => {
		setChangeType('showBreadcrumb');
		setBreadcrumb(value);
	};

	const toggleHeaderMenu = (value: boolean): any => {
		setChangeType('showHeaderMenu');
		setHeaderMenu(value);
	};

	const handleMenuWidth = (value: number | null): void => {
		if (value !== null) {
			setChangeType('menuWidth');
			setMenuWidth(value);
		}
	};

	const handleHeaderHeight = (value: number | null): void => {
		if (value !== null) {
			setChangeType('headerHeight');
			setHeaderHeight(value);
		}
	};

	const handleLogo = (e: any): any => {
		setChangeType('appLogo');
		setLogo(e.target.value);
	};

	const handleLogoWidth = (value: number | null): void => {
		if (value !== null) {
			setChangeType('logoWidth');
			setLogoWidth(value);
		}
	};

	const handleLogoHeight = (value: number | null): void => {
		if (value !== null) {
			setChangeType('logoHeight');
			setLogoHeight(value);
		}
	};

	const handleSiderWidth = (value: number | null): void => {
		if (value !== null) {
			setChangeType('siderWidth');
			setSiderWidth(value);
		}
	};

	const handleLogoChoose = (e:RadioChangeEvent): void => {
		setLogoChoosenMethod(e.target.value);
	};

	const handleFileInput = (e:any): void => {
		uploadFile(e.target.files[0]).then(async res => {
			setChangeType('appLogo');
			setLogo(`${res}`);
		});
	};

	useEffect(() => {
		if (changeType === 'showHeader') {
			setLayoutProperties({
				...layoutProperties,
				header: { ...layoutProperties.header, show_header: showHeader },
			});
		} else if (changeType === 'showFooter') {
			setLayoutProperties({
				...layoutProperties,
				footer: { ...layoutProperties.footer, show_footer: showFooter },
			});
		} else if (changeType === 'showBreadcrumb') {
			setLayoutProperties({
				...layoutProperties, show_breadcrumb: isBreadcrumb },
			);
		} else if (changeType === 'logoHeight') {
			setLayoutProperties({
				...layoutProperties,
				logo: {
					...layoutProperties.logo,
					logo_height: logoHeight,
				},
			});
		} else if (changeType === 'menuWidth') {
			if (layout_type === 'layout_4') {
				setLayoutProperties({
					...layoutProperties,
					menu: {
						...layoutProperties.menu,
						menu_width: menuWidth,
					},
				});
			} else {
				setLayoutProperties({
					...layoutProperties,
					menu: {
						...layoutProperties.menu,
						menu_width: menuWidth},
					logo: {
						...layoutProperties.logo,
						logo_width: 100 - menuWidth},
				});
			}
		} else if (changeType === 'logoWidth') {
			if (layout_type === 'layout_4') {
				setLayoutProperties({
					...layoutProperties,
					logo: {
						...layoutProperties.logo,
						logo_width: logoWidth,
					},
				});
			} else {
				setLayoutProperties({
					...layoutProperties,
					logo: {
						...layoutProperties.logo,
						logo_width: logoWidth},
					menu: {
						...layoutProperties.menu,
						menu_width: 100 - logoWidth},
				});
			}
		} else if (changeType === 'siderWidth') {
			setLayoutProperties({
				...layoutProperties,
				sider: {
					...layoutProperties.sider,
					sider_width: siderWidth,
				},
			});
		} else if (changeType === 'showHeaderMenu') {
			setLayoutProperties({
				...layoutProperties,
				header: {
					...layoutProperties.header,
					show_header_menu: showHeaderMenu,
				},
			});
		} else if (changeType === 'appLogo') {
			setLayoutProperties({
				...layoutProperties,
				logo: {
					...layoutProperties.logo,
					app_logo: appLogo,
				},
			});
		} else if (changeType === 'headerHeight') {
			setLayoutProperties({
				...layoutProperties,
				header: {
					...layoutProperties.header,
					header_height: headerHeight },
			});
		} else if (changeType === 'padding') {
			const padding = `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
			setLogoPadding(padding);
			if (propertyType === 'logo') {
				setLayoutProperties({
					...layoutProperties,
					logo: {
						...layoutProperties.logo,
						logo_style: {
							...logo.logo_style,
							padding,
						},
					},
				});
			} else if (propertyType === 'menu') {
				setLayoutProperties({
					...layoutProperties,
					menu: {
						...layoutProperties.menu,
						menu_style: {
							...menu.menu_style,
							padding,
						},
					},
				});
			} else if (propertyType === 'sider') {
				setLayoutProperties({
					...layoutProperties,
					sider: {
						...layoutProperties.sider,
						sider_style: {
							...sider.sider_style,
							padding,
						},
					},
				});
			}
		}
	}, [
		showHeader,
		showFooter,
		isBreadcrumb,
		menuWidth,
		logoWidth,
		logoHeight,
		siderWidth,
		showHeaderMenu,
		appLogo,
		headerHeight,
		layout_type,
		paddingTop,
		paddingRight,
		paddingBottom,
		paddingLeft,
	]);
	return (
		<Row className='elements'>
			<Col span={24}>
				<Col span={24}>
					<table>
						<tbody>
							<tr>
								<td>Layout</td>
								<td>{layout_type.toUpperCase()
									.replace(/_/g, ' ')}</td>
							</tr>
							<tr>
								<td>Logo</td>
								<td>
									<Radio.Group
										onChange={handleLogoChoose}
										value={logoChoosenMethod}>
										<Radio
											value={1}
											defaultChecked
										>From URl</Radio>
										<Radio value={2}>From Local</Radio>
									</Radio.Group>
								</td>
							</tr>
							{logoChoosenMethod === 1
								? <tr>
									<td>Paste Logo URL</td>
									<td>
										<Input
											value={appLogo}
											placeholder='Logo URL'
											onChange={handleLogo} />
									</td>
								</tr>
								: <tr>
									<td>Choose Logo</td>
									<td>
										<input
											type='file' style={{width: '165px'}}
											onChange={ handleFileInput }/>
									</td>
								</tr>}
							<tr>
								<td>Logo Height</td>
								<td>
									<td><InputNumber
										min={0}
										max={100}
										value={logo.logo_height}
										style={{width: '100%'}}
										onChange={
											handleLogoHeight
										}/>
									</td>
								</td>
							</tr>
							<tr>
								<td>Header</td>
								<td>
									<Switch
										checked={showHeader}
										onChange={toggleHeaderProperties}
									/>
								</td>
							</tr>
							{showHeader && (
								<tr>
									<td colSpan={2} style={{ padding: '0' }}>
										<table>
											<thead>
												<tr>
													<th colSpan={2}>
														Header Properties
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Menu</td>
													<td>
														<Switch
															checked={
																showHeaderMenu
															}
															onChange={
																toggleHeaderMenu
															}
														/>
													</td>
												</tr>
												<tr>
													<td>Header Height</td>
													<td><InputNumber
														min={0}
														value={
															header.header_height}
														style={{width: '100%'}}
														onChange={
															handleHeaderHeight
														}/>
													</td>
												</tr>
												<tr>
													<td>Logo Width</td>
													<td><InputNumber
														min={0}
														max={100}
														value={logo.logo_width}
														style={{width: '100%'}}
														onChange={
															handleLogoWidth
														}/>
													</td>
												</tr>
												<tr>
													<td>Menu Width</td>
													<td><InputNumber
														min={0}
														max={100}
														value={menu.menu_width}
														style={{width: '100%'}}
														onChange={
															handleMenuWidth
														}/>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							)}{layout_type !== 'layout_1'
							&& <tr>
								<td colSpan={2} style={{ padding: '0' }}>
									<table>
										<thead>
											<tr>
												<th colSpan={2}>
														Sider Properties
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Width</td>
												<td>
													<InputNumber
														min={200}
														max={500}
														value={sider.sider_width}
														style={{width: '100%'}}
														onChange={
															handleSiderWidth
														}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>}
							<tr>
								<td>Breadcrumb</td>
								<td>
									<Switch
										checked={isBreadcrumb}
										onChange={toggleBreadcrumbProperties}
									/>
								</td>
							</tr>
							<tr>
								<td>Footer</td>
								<td>
									<Switch
										checked={showFooter}
										onChange={toggleFooterProperties}
									/>
								</td>
							</tr>
							{showFooter && (
								<tr>
									<td colSpan={2} style={{ padding: '0' }}>
										<table>
											<thead>
												<tr>
													<th colSpan={2}>
														Footer Properties
													</th>
												</tr>
											</thead>
										</table>
									</td>
								</tr>
							)}
							<tr>
								<td colSpan={2} style={{ padding: '0' }}>
									<table>
										<thead>
											<tr>
												<th colSpan={2}>
														Padding
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Top</td>
												<td><InputNumber min={0}
													value={paddingTop}
													style={{width: '100%'}}
													onChange={
														(e: number | null) => handlePadding(e, 'top')
													}
												/>
												</td>
											</tr>
											<tr>
												<td>Right</td>
												<td><InputNumber min={0}
													value={paddingRight}
													style={{width: '100%'}}
													onChange={
														(e: number | null) => handlePadding(e, 'right')
													}
												/>
												</td>
											</tr>
											<tr>
												<td>Bottom</td>
												<td><InputNumber min={0}
													value={paddingBottom}
													style={{width: '100%'}}
													onChange={
														(e: number | null) => handlePadding(e, 'bottom')
													}
												/>
												</td>
											</tr>
											<tr>
												<td>Left</td>
												<td><InputNumber min={0}
													value={paddingLeft}
													style={{width: '100%'}}
													onChange={
														(e: number | null) => handlePadding(e, 'left')
													}
												/>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							{/* <tr>
								<td colSpan={2} style={{ padding: '0' }}>
									<table>
										<thead>
											<tr>
												<th colSpan={2}>
														Margin
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Top</td>
												<td><InputNumber
													min={0}
													style={{width: '100%'}}
												/>
												</td>
											</tr>
											<tr>
												<td>Bottom</td>
												<td><InputNumber
													min={15}
													style={{width: '100%'}}
												/>
												</td>
											</tr>
											<tr>
												<td>Right</td>
												<td><InputNumber
													min={0}
													style={{width: '100%'}}
												/>
												</td>
											</tr>
											<tr>
												<td>Left</td>
												<td><InputNumber
													min={0}
													style={{width: '100%'}}
												/>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr> */}
						</tbody>
					</table>
				</Col>
			</Col>
		</Row>
	);
};

export default Settings;

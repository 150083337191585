import { Input, InputNumber, Modal, Select } from 'antd';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { SketchPicker } from 'react-color';
import {get} from 'lodash';

type BorderProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const Border: FC<BorderProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {
		borderWidth = '0px 0px 0px 0px',
		borderColor = '',
		borderStyle = '',
	} = activeElement.element[`${type}_style`];
	const colBorder = borderWidth.split(' ');
	const [
		borderTop,
		setBorderTop,
	] = useState(parseInt(colBorder[0].replace('px', ''), 10));
	const [
		borderRight,
		setBorderRight,
	] = useState(parseInt(colBorder[1].replace('px', ''), 10));
	const [
		borderBottom,
		setBorderBottom,
	] = useState(parseInt(colBorder[2].replace('px', ''), 10));
	const [
		borderLeft,
		setBorderLeft,
	] = useState(parseInt(colBorder[3].replace('px', ''), 10));
	const [bStyle, setBStyle] = useState(borderStyle);
	const [bColor, setBColor] = useState(borderColor);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [propertyType, setPropertyType] = useState('');
	const handleborder = (value: number | null, position: string): any => {
		if (value !== null && position) {
			setPropertyType('borderWidth');
			// eslint-disable-next-line default-case
			switch (position) {
				case 'top': setBorderTop(value);
					break;
				case 'right': setBorderRight(value);
					break;
				case 'bottom': setBorderBottom(value);
					break;
				case 'left': setBorderLeft(value);
					break;
			}
		} else {
			return null;
		}
	};

	useEffect(() => {
		// eslint-disable-next-line max-len
		const border = `${borderTop}px ${borderRight}px ${borderBottom}px ${borderLeft}px`;
		const obj: any = {
			borderColor: bColor,
			borderWidth: border,
			borderStyle: bStyle,
		};
		setChangeType(propertyType);
		setValue(obj[propertyType]);
	}, [
		borderTop,
		borderRight,
		borderBottom,
		borderLeft,
		bColor,
		bStyle,
	]);

	return (
		<table>
			<thead>
				<tr>
					<th colSpan={2}>Border</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Top</td>
					<td>
						<InputNumber min={0}
							value={borderTop}
							onChange={(e: number | null) =>
								handleborder(e, 'top')}
						/>
					</td>
				</tr>
				<tr>
					<td>Right</td>
					<td>
						<InputNumber min={0}
							value={borderRight}
							onChange={(e: number | null) =>
								handleborder(e, 'right')}
						/>
					</td>
				</tr>
				<tr>
					<td>Bottom</td>
					<td>
						<InputNumber min={0}
							value={borderBottom}
							onChange={(e: number | null) =>
								handleborder(e, 'bottom')}
						/>
					</td>
				</tr>
				<tr>
					<td>Left</td>
					<td>
						<InputNumber min={0}
							value={borderLeft}
							onChange={(e: number | null) =>
								handleborder(e, 'left')}
						/>
					</td>
				</tr>
				<tr>
					<td>Broder Color</td>
					<td>
						<Input
							className='color-picker'
							placeholder='#000000'
							value={bColor}
							onClick={() => {
								setIsModalVisible(true);
							}}
						/>
					</td>
				</tr>
				<tr>
					<td>Border Style</td>
					<td>
						<Select
							style={{width: '100%'}}
							value={bStyle}
							options={[
								{
									label: 'Dotted',
									value: 'dotted',
								},
								{
									label: 'Dashed',
									value: 'dashed',
								},
								{
									label: 'Solid',
									value: 'solid',
								},
								{
									label: 'Double',
									value: 'double',
								},
								{
									label: 'Groove',
									value: 'groove',
								},
								{
									label: 'Ridge',
									value: 'ridge',
								},
								{
									label: 'Inset',
									value: 'inset',
								},
								{
									label: 'Outset',
									value: 'outset',
								},
							]}
							onChange={(value: string) => {
								setBStyle(value);
								setPropertyType('borderStyle');
							}}
						/>
					</td>
				</tr>
			</tbody>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={bColor}
					onChange={
						event => {
							setPropertyType('borderColor');
							setBColor(get(event, 'hex', ''));
						}
					}
				/>
			</Modal>
		</table>
	);
};

export default Border;

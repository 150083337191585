import {Col, Input} from 'antd';
import React, {FC} from 'react';

const {TextArea} = Input;
type TextAreaCompProps = {
	properties: any;
	dataSource: any;
}

const TextAreaComp: FC<TextAreaCompProps> = ({properties}) => {
	const {label, textArea} = properties.column_properties;
	const {max, min, placeholder, rows} = textArea;

	const style = label.label_style;
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<TextArea
				placeholder={placeholder}
				maxLength={parseInt(max, 10)}
				minLength={parseInt(min, 10)}
				rows={rows}
			/>
		</Col>
	);
};

export default TextAreaComp;

import React, { FC } from 'react';

type IconProps = {
	type: string;
}

const Icon: FC<IconProps> = ({type, ...rest}: any) => {
	const icons = require('@ant-design/icons');
	const Component = icons[type];

	return <Component {...rest} />;
};

export default Icon;

import { Button, Col, Modal } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import EditorRow from '../editor/editor-row';

type ModalCompProps = {
    col: any;
    getActiveRowColumn: any;
    addRowColumn: any;
	deleteColumn: any;
	deleteRow: any;
	dataSource: any;
	setPropertiesType: Dispatch<SetStateAction<string>>;
};

const ModalComp: FC<ModalCompProps> = ({
	col,
	getActiveRowColumn,
	deleteColumn,
	deleteRow,
	addRowColumn,
	dataSource,
	setPropertiesType,
}) => {
	const {modal, label} = col.column_properties;
	const {
		modal_width = '',
		cancel_text = '',
		footer = true,
		row,
	} = modal;
	const [open, setIsOpen] = useState(false);
	return (
		<div>
			<Col span={24}>
				<Col span={24}>
					<Button onClick={() => setIsOpen(true)}>Preview</Button>
				</Col>
				<Col>
					{
						label.show_label && (
							<p
								style={{...label.label_style}}
							>
								{label.label_name || 'Title'}
							</p>
						)
					}
				</Col>
			</Col>
			{row.map((rows: any, index: number) => (
				<>
					{
						open && (
							<Modal
								open={open}
								onCancel={() => setIsOpen(false)}
								className='dynamic-app'
								key={index.toString()}
								title={
									label.show_label && (
										<p
											style={{...label.label_style}}
										>
											{label.label_name || 'Title'}
										</p>
									)
								}
								footer={footer}
								cancelText={cancel_text || undefined}
								width={modal_width || undefined}
							>
								<EditorRow
									key={index}
									row={rows}
									getActiveRowColumn={
										getActiveRowColumn
									}
									indexRow={index}
									dataSource={dataSource}
									deleteColumn={deleteColumn}
									deleteRow={deleteRow}
									addRowColumn={addRowColumn}
									setPropertiesType={setPropertiesType}
								/>
							</Modal>
						)
					}
					{
						!open && (
							<EditorRow
								key={index}
								row={rows}
								getActiveRowColumn={
									getActiveRowColumn
								}
								indexRow={index}
								dataSource={dataSource}
								deleteColumn={deleteColumn}
								deleteRow={deleteRow}
								addRowColumn={addRowColumn}
								setPropertiesType={setPropertiesType}
							/>
						)
					}
				</>
			))}
		</div>
	);
};

export default ModalComp;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, message, Spin } from 'antd';
import Settings from './settings/settings';
import Layout1 from './layout_types/layout1';
import Layout2 from './layout_types/layout2';
import Layout3 from './layout_types/layout3';
import Layout4 from './layout_types/layout4';
import { useParams } from 'react-router-dom';
import { updateAPI, getAPI } from '../../api/api';
import { isEmpty } from 'lodash';

const LayoutPreview = (): any => {
	const navigate = useNavigate();
	const { layout_type, application_uid } = useParams();

	const [loading, setLoading] = useState(true);
	const [layoutProperties, setLayoutProperties] = useState<any>(null);
	const [propertyType, setPropertyType] = useState('');
	const fetchData = (): any => {
		getAPI(`/apps/${application_uid}`).then((res: any) => {
			setLoading(true);
			const {applicationLayout} = res.data.data[0];
			const obj = {
				layout_type: layout_type || '',
				show_breadcrumb: false,
				logo: {
					app_logo: '',
					logo_width: null,
					logo_height: null,
					logo_style: {
						padding: '0px 0px 0px 0px',
					},
				},
				menu: {
					is_header: false,
					menu_width: null,
					menu_style: {
						padding: '0px 0px 0px 0px',
					},
				},
				header: {
					show_header: true,
					show_header_menu: true,
					header_height: null,
					header_style: {
						padding: '0px 0px 0px 0px',
					},
					profile: {
						is_profile: false,
						profile_data_source: '',
					},
					notification: {
						notification_data_source: '',
					},
				},
				sider: {
					sider_width: 200,
					sider_style: {
						padding: '0px 0px 0px 0px',
					},
				},
				footer: {
					show_footer: true,
					footer_text: '',
					footer_style: {
						padding: '0px 0px 0px 0px',
					},
				},
			};
			const data = JSON.parse(applicationLayout);
			if (isEmpty(data)) {
				setLayoutProperties(obj);
			} else {
				setLayoutProperties({...data, layout_type: layout_type});
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleSaveClick = async () => {
		const data = {
			application_layout: JSON.stringify(layoutProperties),
			application_uid,
		};
		await updateAPI('/apps/layout', data).then(res => {
			if (res.data.success) {
				message.success('Layout saved successfully');
			} else {
				message.error('Something went Wrong while saving Layout');
			}
		});
	};

	useEffect(() => {
		fetchData();
		if (layout_type && layoutProperties) {
			setLayoutProperties({
				...layoutProperties, layout_type },
			);
		}
	}, []);
	return (
		<Spin spinning={loading}>
			{layoutProperties && (
				<Row>
					<Col span={18} >
						<Row className='layout-preview-btn'>
							<Button
								onClick={
									() => navigate(
										`/layout/${application_uid}`)
								}>
								Change Layout
							</Button>
							<Button>Cancel</Button>
							<Button
								type='primary'
								onClick={handleSaveClick}>
									Save
							</Button>
						</Row>
						<Col span={24} className='layout-preview'>
							{layout_type === 'layout_1'
				&& <Layout1
					layoutProperties={layoutProperties}
					setPropertyType={setPropertyType}/>}
							{layout_type === 'layout_2'
				&& <Layout2
					layoutProperties={layoutProperties}
					setPropertyType={setPropertyType}/>}
							{layout_type === 'layout_3'
				&& <Layout3
					layoutProperties={layoutProperties}
					setPropertyType={setPropertyType} />}
							{layout_type === 'layout_4'
				&& <Layout4
					layoutProperties={layoutProperties}
					setPropertyType={setPropertyType} />}
						</Col>
					</Col>
					<Col span={6}>
						<Col span={24}>
							<div className='settings'>
								<div className='card'>
									<Col
										className='card-title'
										span={24}
										style={{ padding: '7px 10px' }}
									>
										<p>Layout Properties</p>
									</Col>
									<Col span={24}>
										<Settings
											key={propertyType}
											layoutProperties={layoutProperties}
											setLayoutProperties={
												setLayoutProperties
											}
											propertyType={propertyType}
										/>
									</Col>
								</div>
							</div>
						</Col>
					</Col>
				</Row>
			)}
		</Spin>
	);
};

export default LayoutPreview;

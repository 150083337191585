import {Select} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';

type LabelFonstStyleProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelFonstStyle: FC<LabelFonstStyleProps> = ({
	activeElement,
	setChangeType,
	setValue,
}: LabelFonstStyleProps) => {
	const {
		fontStyle = '',
	} = activeElement.element.column_properties.label.label_style;
	const [style, setStyle] = useState(fontStyle);
	const handleLabelFontStyle = (value: string): void => {
		setStyle(value);
	};

	useEffect(() => {
		setChangeType('fontStyle');
		setValue(style);
	}, [style]);

	return (
		<div>
			<Select
				style={{width: '100%'}}
				value={style}
				onChange={handleLabelFontStyle}
				options={[
					{
						value: 'normal',
						label: 'Normal',
					},
					{
						value: 'italic',
						label: 'Italic',
					},
				]}
			/>
		</div>
	);
};

export default LabelFonstStyle;

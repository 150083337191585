import { Input, Modal, Switch } from 'antd';
import React, { Dispatch, SetStateAction, FC, useState } from 'react';
import { SketchPicker } from 'react-color';
import {get} from 'lodash';

type ColumnIterablePropsProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const ColumnIterableProps: FC<ColumnIterablePropsProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {
		iterable = {
			column_iterable: false,
			odd_bg: '',
			even_bg: '',
		},
	} = activeElement.element[`${type}_properties`];
	const {
		column_iterable = false,
		odd_bg = '',
		even_bg = '',
	} = iterable;
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [propertyType, setPropertyType] = useState('');
	const handleChange = (value: boolean): void => {
		setValue(value);
		setChangeType('column_iterable');
	};

	return (
		<table>
			<tbody>
				<tr>
					<td>Iterable</td>
					<td>
						<Switch
							checked={column_iterable}
							onChange={handleChange}
						/>
					</td>
				</tr>
				{
					column_iterable && (
						<tr>
							<td>Odd Bg</td>
							<td>
								<Input
									onClick={() => {
										setIsModalVisible(true);
										setPropertyType('odd_bg');
									}}
									value={odd_bg}
								/>
							</td>
						</tr>
					)
				}
				{
					column_iterable && (
						<tr>
							<td>Even Bg</td>
							<td>
								<Input
									onClick={() => {
										setIsModalVisible(true);
										setPropertyType('even_bg');
									}}
									value={even_bg}
								/>
							</td>
						</tr>
					)
				}
			</tbody>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => {
					setIsModalVisible(false);
					setValue('');
					setChangeType(propertyType);
				}}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					// eslint-disable-next-line max-len
					color={propertyType && propertyType === 'odd_bg' ? odd_bg : even_bg}
					onChange={
						event => {
							setValue(get(event, 'hex', ''));
							setChangeType(propertyType);
						}
					}
				/>
			</Modal>
		</table>
	);
};

export default ColumnIterableProps;

/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useParams} from 'react-router';
import {currentUser, fetchData, updateAPI} from '../api/api';
import {EditForm} from '../forms/edit';
import '../forms/style.css';
import {Backdrop} from '@mui/material';
import {Alerts} from '../shared/components/alerts';
import {useNavigate} from 'react-router-dom';
import {camelCase} from 'text-case';

export const UserForm:React.FC = () => {
	const params: any = useParams();
	const [form, setForm] = useState([]);
	const [formName, setFormName] = useState('');
	const [formData, setFormData] = useState<any>();
	const [requiredFields, setRequiredFields] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [formError, setFormError] = useState(false);
	const [success, setSuccess] = useState(false);
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const setFields = (
		formData: any, requireCheck = false, userTypedValue: any = {}) => {
		const required: any = [];
		const parsedFields = formData.map((field: any) => {
			const item = {...field};
			if (item.fieldProps.required) {
				required.push(camelCase(item.fieldName));
				if (requireCheck
					&& !userTypedValue[camelCase(item.fieldName)]) {
					item.fieldProps.className = 'required error';
					item.fieldProps.helperText
				= 'Please Enter ' + item.fieldName;
				} else {
					item.fieldProps.className = 'required';
				}
			}

			return {
				...item,
			};
		});
		setRequiredFields(required);
		setForm(parsedFields);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getData = async () => {
		try {
			setLoading(true);
			const element = await fetchData(`/user-form?uid=${params.uid}`);
			setFormName(element.results.name);
			setFormData(element.results);
			setFields(JSON.parse(element.results.fieldsJson));
			setFormError(false);
			setLoading(false);
		// eslint-disable-next-line no-unused-vars
		} catch (error: any) {
			setLoading(false);
			setError(true);
		}
	};

	const handleClick = (formValues: any): void => {
		setLoading(true);
		const requireCheck
		= requiredFields.filter((item: any) => formValues[item] && item);

		// eslint-disable-next-line no-negated-condition
		if (requireCheck.length !== requiredFields.length) {
			setLoading(false);
			setFormError(true);
			setFields(form, true, formValues);
		} else {
			setFormError(false);
			setFields(form, true, formValues);

			const params = {
				fields_response: JSON.stringify(formValues),
				modified_by: currentUser,
				created_by: currentUser,
				form_uid: formData.uid,
			};
			updateAPI('/user-form/response', params).then(() => {
				setLoading(false);
				setSuccess(true);
				navigate('/success');
			}).catch(() => {
				setLoading(false);
				setError(true);
			});
		}
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<div>
			{loading
				? (<Backdrop
					sx={
						{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}
					}
					open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop>)
				: <>			<EditForm
					form={form}
					name={formName}
					type={formData && formData.type ? formData.type : ''}
					loading={loading}
					error={formError}
					onClick={handleClick}
				/>
				<Alerts
					severity={error ? 'error' : 'success'}
					message={
						error
							? 'Something went wrong while saving form'
							: 'Successfully saved'
					}
					open={error || success}/>
				</>
			}
		</div>
	);
};

import { AppstoreOutlined, ClusterOutlined } from '@ant-design/icons';
import { Col, Row, Tabs } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateApp from './create-app';
import WorkFLow from './workflow/create-workflow';

type AppsAndWorkFLowCreatorProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>;
	setThemeColor: Dispatch<SetStateAction<string>>;
}

const AppsAndWorkFLowCreator: FC<AppsAndWorkFLowCreatorProps> = ({
	setFullScreen,
	setThemeColor,
}) => {
	const navigate = useNavigate();
	return (
		<Row className='app dynamic-app-container'>
			<Col span={24}>
				<Tabs
					tabPosition='left'
					defaultActiveKey={window.location.pathname.slice(1)}
					onTabClick={(key: string) => navigate(`/${key}`)}
					items={[
						{
							label: (
								<span> <AppstoreOutlined />Applications</span>
							),
							key: 'apps',
							children: (
								<CreateApp
									setFullScreen={setFullScreen}
									setThemeColor={setThemeColor}
								/>
							),
						},
						{
							label: (
								<span> <ClusterOutlined />Work FLow</span>
							),
							key: 'workflow',
							children: (
								<WorkFLow
									setFullScreen={setFullScreen}
									setThemeColor={setThemeColor}
								/>
							),
						},
					]} />
			</Col>
		</Row>
	);
};

export default AppsAndWorkFLowCreator;

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-params */
import {Card, CardContent, TextField, Button, Grid, FormControl, Dialog, DialogTitle, Typography, IconButton, DialogContent, CircularProgress, Backdrop} from '@mui/material';
import React, {useEffect, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {currentUser, deleteAPI, fetchData, updateAPI} from '../api/api';
import Skeleton from 'react-loading-skeleton';
import ButtonComp from '../shared/components/button/button';
import {Add, CloseOutlined, DeleteOutline, Edit} from '@mui/icons-material';
import TextFieldComp from '../shared/components/textfield/textfield';
import {Alerts} from '../shared/components/alerts';
import {LoadingButton} from '@mui/lab';

export const Lookup = () => {
	const [showEle, setShowEle] = useState(false);
	const [lookupValueLoading, setLookupValueLoading] = useState(false);
	const [newOption, setNewOption] = useState('');
	const [label, setLabel] = useState('');
	const [showAddNew, setShowAddNew] = useState<any>(-1);
	const [lookup, setLookup] = useState([]);
	const [lookUpValues, setLookupValues] = useState([]);
	const [lookupName, setLookupName] = useState<string[]>([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [edit, setEdit] = useState(-1);
	const [name, setName] = useState('');
	const getData = async () => {
		try {
			setLoading(true);
			const lookUp = await fetchData('/lookups');
			const lookUpValues = await fetchData('/lookups/values');
			setLookup(lookUp.data);
			localStorage.setItem('lookupLength', lookUp.data.length);
			setLookupValues(lookUpValues.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const updateLookupValues = (data: any, option: any, type = 'update', idx: number = 0, formData: any = {}, lookUpIndex: number = 0) => {
		setLookupValueLoading(true);
		if (type !== 'new') {
			setShowAddNew(lookUpIndex);
		}

		let params: any = {
			lookup_uid: data.lookupUid,
			value: JSON.stringify(option.concat(newOption)),
		};
		if (type === 'new') {
			setShowAddNew(idx);
			params = {
				lookup_uid: formData.uid,
				modified_by: currentUser,
				created_by: currentUser,
				value: JSON.stringify(option.concat(newOption)),
			};
		}

		if (type === 'update') {
			params.value = JSON.stringify(option.concat(newOption));
		}

		if (type === 'delete') {
			const modififedOption = option;
			modififedOption.splice(idx, 1);
			params.value = JSON.stringify(modififedOption);
		}

		updateAPI('/lookups/values/create', params).then(() => {
			setNewOption('');
			setShowAddNew(-1);
			getData();
			setLookupValueLoading(false);
			setLoading(false);
			setSuccess(true);
		})
			.catch(() => {
				setLoading(false);
				setError(true);
				setLookupValueLoading(false);
			});
	};

	const handleEnableAddNew = (index: number) => setShowAddNew(index);
	const handleSubmit = () => {
		setLookupValueLoading(true);
		setLoading(true);
		const params = {
			created_by: currentUser,
			modified_by: currentUser,
			name: label,
			type: '',
		};
		updateAPI('/lookups/create', params).then(res => {
			setOpen(false);
			getData();
			setLookupValueLoading(false);
		}).then(() => {
			setLoading(false);
			setSuccess(true);
		}).catch(() => {
			setLoading(false);
			setError(true);
		});
	};

	const handleUpdate = (uid: string) => {
		const params = {name, uid};
		updateAPI('/lookups/update', params).then(() => {
			setEdit(-1);
			getData();
		}).catch(() => {});
	};

	const handleBulkDelete = (uid: string) => {
		deleteAPI('/lookups/delete', {uid})
			.then(() => {
				getData();
			})
			.catch((err: any) => {
			});
	};

	return (
		<div>
			<Dialog open={open}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: '370px',
						},
					},
				}}
			>
				<DialogTitle>
					<Typography variant='h6'>Add Lookup Name</Typography>
					<IconButton
						aria-label='close'
						onClick={() => setOpen(false)}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500],
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container>
						<Grid item xs={12} style={{display: 'flex', gap: '1rem', justifyContent: 'space-evenly'}}>
        					<TextFieldComp
								type='text'
        						onChange={e => setLabel(e.target.value)} />
							<div style={{alignSelf: 'center'}}>
								<LoadingButton
									onClick={handleSubmit}
									endIcon={<Add />}
									loading={loading}
									loadingPosition='end'
									variant='contained'
								>
			Save
								</LoadingButton>
							</div>
						</Grid>
        	</Grid>
				</DialogContent>
			</Dialog>
			<Alerts
				severity={error ? 'error' : 'success'}
				message={
					error
						? 'Something went wrong'
						: 'Successfully saved'
				}
				open={error || success}/>
			<Grid item xs={12} style={{display: 'flex'}}>
				<FormControl sx={{p: 2}} style={{float: 'right'}}>
					<ButtonComp variant='contained' startIcon={<Add />} onClick={() => setOpen(true)} text='Add' />
				</FormControl>
			</Grid>
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{loading && ([...Array(Number(localStorage.getItem('lookupLength') || 2))].map((item: any) => <Card
					key={item}
					style={{
						flexGrow: '1',
						minWidth: '400px',
						maxWidth: '400px',
						minHeight: '250px',
						maxHeight: '250px',
						margin: '10px',
					}}
				>
					<CardContent><Skeleton count={4}/></CardContent>
				</Card>))}
				{!loading && lookup.map((item: any, index: number) => {
					const filteredData = lookUpValues.filter((value: any) => value.lookupUid === item.uid);
					const option: any = filteredData.length && filteredData[0];
					const parseOptions = filteredData.length ? JSON.parse(option.value) : [];
					return (
						<div key={item.uid}>
							<Card
								style={{
									flexGrow: '1',
									minWidth: '400px',
									maxWidth: '400px',
									minHeight: '250px',
									maxHeight: '250px',
									overflowY: 'scroll',
									borderLeft: '10px solid #006bb6',
									borderRadius: '15px 0px 0px 15px',
									margin: '10px',
								}}
							>
								{showAddNew === index && lookupValueLoading ? <CardContent><Skeleton count={4}/></CardContent> : <CardContent>
									<div style={{width: '100%', height: '70px', marginBottom: '7px', borderBottom: '2px solid #1976d2'}}>
										{edit !== index && <h3 style={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>{item.name}
											<div> <Edit className='cursor-pointer' onClick={() => setEdit(index)} /> <DeleteIcon className='cursor-pointer' onClick={() => handleBulkDelete(item.uid)} /> </div>
										</h3>}
										{edit === index
										&& <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
											<TextFieldComp defaultValue={item.name} onChange={e => setName(e.target.value)} />
											<div style={{display: 'flex', gap: '5px'}}>
												<ButtonComp variant='outlined' text='Cancel' onClick={() => setEdit(-1)} />
												<ButtonComp variant='contained' text='Update' onClick={() => handleUpdate(item.uid)} />
											</div>
										</div>
										 }
									</div>
									{parseOptions.map((item: any, idx: number) => (
										<div style={{display: 'flex', justifyContent: 'space-between'}} key={item}>
											<p>{item}</p>
											<p className='cursor-pointer'><DeleteIcon onClick={() => updateLookupValues(option, parseOptions, 'delete', idx, {}, index)}/></p>
										</div>
									))}
									{parseOptions.length === 0 && !(showAddNew === index) && <p style={{textAlign: 'center', marginTop: '15px'}}>No Options</p>}
									{showAddNew === index && (<div style={{position: 'sticky', bottom: '30px', background: '#fff', zIndex: '9	'}}>
										<div><TextField style={{width: '100%'}} onChange={event => setNewOption(event.target.value)} type='text' /></div>
										<div style={{padding: '10px 0', display: 'flex', justifyContent: 'end', gap: '10px'}}>
											<Button
												variant='outlined'
												size='small'
												onClick={() => setShowAddNew(-1)}
											>
												Cancel
											</Button>
											<Button
												variant='contained'
												size='small'
												onClick={() => updateLookupValues(option, parseOptions, 'new', index, item)}
											>
                                Save
											</Button>
										</div>
									</div>)}
									<div className='cursor-pointer' style={{position: 'sticky', bottom: '0', background: '#fff', zIndex: '9	'}}>
										<AddIcon style={{marginLeft: '93%'}} onClick={() => handleEnableAddNew(index)}/>
									</div>
								</CardContent>}
							</Card>
						</div>
					);
				})}
			</div>
			{
				showEle
        && (
        	<div>
        		<TextFieldComp
        			type='text'
        			onChange={e => setLabel(e.target.value)} />
        		<Button variant='contained' onClick={(e: any) => setLookupName(e.target.value)}>Submit</Button>
        	</div>
        )
			}
		</div>
	);
};

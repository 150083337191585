import {Select} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';

type LabelFontWeightProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelFontWeight: FC<LabelFontWeightProps> = ({
	activeElement,
	setChangeType,
	setValue,
}: LabelFontWeightProps) => {
	const {
		fontWeight = '',
	} = activeElement.element.column_properties.label.label_style;
	const [weight, setWeight] = useState(fontWeight);
	const handleLabelFontWeight = (value: string): void => {
		setWeight(value);
	};

	useEffect(() => {
		setChangeType('fontWeight');
		setValue(weight);
	}, [weight]);
	return (
		<div>
			<Select
				style={{width: '100%'}}
				value={fontWeight}
				onChange={handleLabelFontWeight}
				options={[
					{
						value: '300',
						label: '300',
					},
					{
						value: '400',
						label: '400',
					},
					{
						value: '500',
						label: '500',
					},
					{
						value: '600',
						label: '600',
					},
					{
						value: '700',
						label: '700',
					},
					{
						value: '800',
						label: '800',
					},
					{
						value: '900',
						label: '900',
					},
				]}
			/>
		</div>
	);
};

export default LabelFontWeight;

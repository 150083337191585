/* eslint-disable complexity */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import ButtonComp from '../shared/components/button/button';
import copyToClip from 'copy-to-clipboard';
import {currentUser, deleteAPI, fetchData, updateAPI} from '../api/api';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from '@mui/material';
import {Add, CloseOutlined, ContentCopy, Delete, RemoveCircleOutline, Clear, Send} from '@mui/icons-material';
import {MUIDataGrid} from './data-grid';
import Skeleton from 'react-loading-skeleton';
import SendIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/RemoveRedEye';
import TextFieldComp from '../shared/components/textfield/textfield';
import {Alerts} from '../shared/components/alerts';
import gmail from '../icons/gmail.png';
import {MailContent} from './mail-content';
import ReactChipInput from 'react-chip-input';
import {LoadingButton} from '@mui/lab';
import axios from 'axios';
import { EditForm } from './edit';

export const FormsList:React.FC = () => {
	const lookUpFields = ['select', 'radio', 'checkbox'];
	const [formList, setFormList] = useState([]);
	const [viewResponseData, setViewResponseData] = useState();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [currentFormData, setCurrentFormData] = useState<any>();
	const [del, setDel] = useState(false);
	const [add, setAdd] = useState(false);
	const [deleteVal, setDeleteVal] = useState();
	const [viewResponse, setViewResponse] = useState(false);
	const [send, setSend] = useState(false);
	const [copy, setCopy] = useState(false);
	const [viewResponseLoading, setViewResponseLoading] = useState(false);
	const [type, setType] = useState('');
	const [name, setName] = useState('');
	const [selectAllChecked, setSelectAllChecked] = useState(true);
	const [formType, setFormType] = useState('');
	const [message, setMessage] = useState('Success');
	const [lookup, setLookup] = useState([]);
	const [lookupValue, setLookupValue] = useState([]);
	const [userInfo, setUserInfo] = useState<any>([]);
	const [chips, setChips] = useState<any>([]);
	const [selectedFile, setSelectedFile] = useState<any>({});
	const [mailLoader, setMailSendLoader] = useState(false);
	const [mailSent, setSentMail] = useState(false);
	const [deleteLoader, setDeleteLoader] = useState(false);
	const [preview, setPreview] = useState(false);
	const defaultField = [{
		fieldName: '',
		fieldType: '',
		lookupUid: '',
		required: false,
	}];
	const [formValues, setFormValues] = useState<any | []>(defaultField);
	const formTypes = ['Text', 'Select', 'Email', 'Date', 'Radio', 'Checkbox', 'Number', 'Tel', 'Textarea', 'FileUpload'];

	useEffect(() => {
		fetchData('/lookups')
			.then(res => setLookup(res.data))
			.catch(() => setError(true));
		fetchData('/lookups/values')
			.then(res => setLookupValue(res.data))
			.catch(() => setError(true));
		fetchData('/user/all-users')
			.then(res => setUserInfo(res.data.map((user: any) => ({email: user.email, checked: true}))))
			.catch(() => setError(true));
	}, []);
	const handleChange = (e: any, index: number):void => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.target.type === 'checkbox' ? e.target.checked : e.currentTarget.value;
		const newFormData = [...formValues];
		newFormData[index][fieldName] = fieldValue;
		setFormValues(newFormData);
	};

	const handleSelectChange = (e: any, index: number): void => {
		setType(e.target.value);
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		const newFormData = [...formValues];
		if (lookUpFields.includes(fieldValue)) {
			newFormData[index].lookupUid = '';
		}

		newFormData[index][fieldName] = fieldValue;
		setFormValues(newFormData);
	};

	const handleSubmit = ():void => {
		setLoading(true);
		setSuccess(false);
		const modifiedFormValues = formValues.map((item: any) => {
			const field = {...item};
			let selectOptions: any = [];
			if (lookUpFields.includes(field.fieldType)) {
				selectOptions = lookupValue.filter((lookup: any) => lookup.lookupUid === item.lookupUid);
			}

			return {
				...field,
				fieldProps: {
					type: item.fieldType,
					required: item.required,
				},
				value: selectOptions.length ? JSON.parse(selectOptions[0].value) : '',
			};
		});
		const params = {
			name,
			type: formType,
			fields_json: JSON.stringify(modifiedFormValues),
			modified_by: currentUser,
			created_by: currentUser,
			user_json: JSON.stringify([]),
		};
		updateAPI('/forms/create', params)
			.then(() => {
				setLoading(false);
				setSuccess(true);
				setMessage('Form created successfully');
				handleClose();
				getData();
			})
			.catch(() => setError(true));
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleOpen = async (data:any) => {
		setViewResponseLoading(true);
		setViewResponse(true);
		setOpen(true);
		setCurrentFormData(data);
		fetchData(`/user?uid=${data.uid}`)
			.then((res: any) => {
				setViewResponseLoading(false);
				setViewResponseData(res.data);
			})
			.catch(() => {
				setViewResponseLoading(false);
				handleClose();
				setError(true);
			});
	};

	const handleClose = ():void => {
		setDel(false);
		setViewResponse(false);
		setSend(false);
		setOpen(false);
		setAdd(false);
		setDeleteLoader(false);
		setSelectAllChecked(false);
		setChips([]);
		setPreview(false);
	};

	const showDeleteModal = (data:any):void => {
		setSend(false);
		setDeleteVal(data.uid);
		setDel(true);
		setOpen(true);
	};

	const handleDelete = (): void => {
		setDeleteLoader(true);
		setSuccess(false);
		deleteAPI('/forms/delete', {uid: deleteVal})
			.then(() => {
				handleClose();
				setDeleteLoader(false);
				setSuccess(true);
				setMessage('Deleted successfully');
				getData();
			})
			.catch(() => {
				setDeleteLoader(false);
				setError(true);
				handleClose();
			});
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getData = async () => {
		try {
			setLoading(true);
			const element = await fetchData('/forms');
			setFormList(element.data);
			localStorage.setItem('formLegth', element.data.length);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const onAddButtonClick = ():void => {
		setFormValues(formValues.concat(defaultField));
	};

	const handleSend = (form: any):void => {
		setSentMail(false);
		setSend(true);
		setOpen(true);
		setCurrentFormData(form);
		setSelectAllChecked(true);
		setUserInfo(userInfo.map((user: any) => ({...user, checked: true})));
	};

	const handlePreview = (form: any): void => {
		setPreview(true);
		setOpen(true);
		setCurrentFormData(form);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const userInfoModified = userInfo.map((user: any) => ({...user, checked: event.target.checked}));
		if (event.target.value === 'Select All') {
			setSelectAllChecked(event.target.checked);
			setUserInfo(userInfoModified);
		} else {
			const checkUpdatedData = userInfo.map((item: any) => (item.email === event.target.value ? {...item, checked: event.target.checked} : {...item}));
			setUserInfo(checkUpdatedData);
		}
	};

	const handleShare = (): void => {
		setMailSendLoader(true);
		const filteredUsers = userInfo.filter((user: any) => user.checked && user);
		const emailList = filteredUsers.map((user: any) => user.email);
		const params = {
			toMail: [...emailList, ...chips].toString(),
			content: {
				uid: `${window.origin}/forms/${currentFormData.uid}`,
				name: currentFormData.name,
			},
		};
		updateAPI('/forms/send-mail', params).then(res => {
			if (res.data.success) {
				setSentMail(true);
				setMailSendLoader(false);
				setChips([]);
			}
		});
	};

	const addChip = (value: any): void => {
		const chip = chips.slice();
		chip.push(value);
		setChips(chip);
	};

	const removeChip = (index: any): void => {
		const chip = chips.slice();
		chip.splice(index, 1);
		setChips(chip);
	};

	const handleRemoveField = (index: any): void => {
		const updateForm = [...formValues];
		updateForm.splice(index, 1);
		setFormValues(updateForm);
	};

	const handleFileInput = (e: any): void => {
		setSelectedFile(e.target.files[0]);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const uploadFile = async (file: any) => {
		const {name = '', type = ''} = selectedFile;
		updateAPI('/s3/getSignedUrl', {name: name.replace(/ /g, '_'), type}).then(async res => {
			const {data} = res.data;
			const options = {
				headers: {'Content-Type': file.type},
				reportProgress: true,
			};
			await axios.put(data.info.signedRequest, selectedFile, {...options}).catch(err => console.log(err));
		});
	};

	return (
		<div>
			{(mailSent) && <Alerts
				severity={error ? 'error' : 'success'}
				message={
					error
						? 'Something went wrong'
						: mailSent ? 'Sent Successfully' : message
				}
				open={error || success || mailSent}/>}
			<Dialog open={add}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: '60%',
						},
					},
				}}
			>
				<DialogTitle>
					<Typography variant='h5'>{'Add Form'}</Typography>
					<IconButton
						aria-label='close'
						onClick={() => setAdd(false)}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500],
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				<>
					<DialogContent dividers>
						<Grid container>
							<Grid item xs={6}>
								<FormGroup sx={{p: 2}}>
									<FormLabel htmlFor='name'>Form Name</FormLabel>
									<TextFieldComp name='name' onChange={e => setName(e.target.value)} />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup sx={{p: 2}}>
									<FormLabel htmlFor='type'>Form Type</FormLabel>
									<TextFieldComp name='type' onChange={e => setFormType(e.target.value)} />
								</FormGroup>
							</Grid>
							{formValues.map((item: any, index: number) =>
								<Grid item xs={12} key={index} style={{display: 'flex', marginTop: '15px'}} className='form-grid'>
									<Grid xs={4}>
										<FormGroup sx={{p: 2}}>
											<FormLabel htmlFor='fieldName'>Field Name</FormLabel>
											<TextFieldComp name='fieldName' value={item.fieldName} onChange={e => handleChange(e, index)} />
										</FormGroup>
										<FormGroup style={{marginLeft: '20px'}}>
											<FormControlLabel control={<Checkbox name='required' onChange={e => handleChange(e, index)} />} label='Required' />
										</FormGroup>
									</Grid>
									<Grid xs={4}>
										<FormGroup sx={{p: 2}}>
											<FormLabel htmlFor='fieldType'>Type</FormLabel>
											<Select value={item.fieldType} name='fieldType' onChange={e => handleSelectChange(e, index)}>
												{formTypes.map((val: any) => (
												// eslint-disable-next-line react/jsx-key
													<MenuItem value={val.toLowerCase()}>{val}</MenuItem>
												))}
											</Select>
										</FormGroup>
									</Grid>
									{lookUpFields.includes(item.fieldType)
									&& <Grid xs={4}>
										<FormGroup sx={{p: 2}}>
											<FormLabel htmlFor='lookupUid'>Look Up Name</FormLabel>
											<Select name='lookupUid' value={item.lookupUid} onChange={e => handleSelectChange(e, index)}>
												{lookup.map((val: any) => (
												// eslint-disable-next-line react/jsx-key
													<MenuItem value={val.uid}>{val.name}</MenuItem>
												))}
											</Select>
											{/* <TextFieldComp name='fieldValue' onChange={e => handleChange(e, index)} /> */}
										</FormGroup>
									</Grid>
									}
									<div><Clear className='cursor-pointer' onClick={() => handleRemoveField(index)}></Clear></div>
								</Grid>,
							)}
							<Grid item style={{width: '100%'}}>
								<FormControl sx={{p: 2}} style={{float: 'right'}}>
									<ButtonComp variant='contained' startIcon={<Add />} onClick={onAddButtonClick} text='Add Field' />
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
				</>
				<DialogActions>
					<Button variant='outlined' onClick={handleClose}>Cancel</Button>
					<LoadingButton
						onClick={handleSubmit}
						endIcon={<Add />}
						loading={loading}
						loadingPosition='end'
						variant='contained'
					>
			Save
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Dialog open={open}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: (del || send) ? '500px' : '80%',
						},
					},
				}}
			>
				<DialogTitle style={{position: 'sticky', background: 'white', top: 0}}>
					<Typography variant='h5'>{del ? 'Delete Form' : send
						? <h5>Share Form <Tooltip onClose={() => setTimeout(() => setCopy(false), 200)} title={copy ? 'copied' : 'click to copy form url'}>
							<ContentCopy onClick={() => {
								copyToClip(`http://localhost:3000/forms/${currentFormData.uid}`, {
									debug: true,
									message: 'Press to copy',
								});
								setCopy(true);
							}} style={{fontSize: '15px'}} className='cursor-pointer' /></Tooltip></h5> : preview ? 'Preview' : 'Response'}</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500],
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				{del
					&& <>
						<DialogContent dividers>
							<Typography variant='h6'>
Are you sure want to delete ?
							</Typography>
						</DialogContent><DialogActions>
							<Button onClick={handleClose} variant='outlined'>
								Cancel
							</Button>
							<LoadingButton
								onClick={handleDelete}
								endIcon={<RemoveCircleOutline />}
								loading={deleteLoader}
								loadingPosition='end'
								variant='contained'
							>
			Delete
							</LoadingButton>
						</DialogActions>
					</>
				}
				{preview
					&& <>
						<DialogContent dividers>
							<EditForm disabled={true} form={JSON.parse(currentFormData.fieldsJson)} name={currentFormData.name} />
						</DialogContent>
					</>
				}
				{send
					&& <>
						<DialogContent dividers>
							<Typography style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}} variant='caption' title='click to copy'>
							share via <img src={gmail} style={{width: '20px', margin: '0px 10px'}}></img>
							</Typography>
							<FormGroup>
								<div style={{
									position: 'sticky',
									background: 'white',
									top: '16px',
									zIndex: 99,
								}}>
									<h3>Enter Email Address</h3>
									<ReactChipInput
										chip-color='blue'
										classes='class1 class2 chipinput'
										chips={chips}
										onSubmit={(value: any) => addChip(value)}
										onRemove={(index: any) => removeChip(index)}
									/>
								</div>
								<FormControlLabel
									key={'Select All'}
									control={
										<Checkbox
											value='Select All'
											onChange={handleToChange}
											defaultChecked={true}
										/>}
									label={selectAllChecked ? 'Un Select All' : 'Select All'} />
								<MailContent handleChange={handleToChange} data={userInfo}/>
							</FormGroup>
						</DialogContent><DialogActions>
							<Button onClick={handleClose} variant='outlined'>
							Cancel
							</Button>
							<LoadingButton
								onClick={handleShare}
								endIcon={<Send />}
								loading={mailLoader}
								loadingPosition='end'
								variant='contained'
							>
			Send
							</LoadingButton>
						</DialogActions>
					</>
				}
				{viewResponse
					&& (viewResponseLoading ? <CircularProgress style={{margin: '20px auto'}}/>
						: <MUIDataGrid data={viewResponseData} formData={currentFormData}/>)
				}
			</Dialog>
			<ButtonComp variant='contained' startIcon={<Add />} onClick={
				() => setAdd(true)} text='Add Form' />
			{loading ? <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: '13px 0px'}}>{[...Array(Number(localStorage.getItem('formLegth') || 3))].map((index: number) => <Card
				key={index}
				style={{
					flexGrow: '1',
					minWidth: '350px',
					maxWidth: '350px',
					margin: '13px 0px',
					borderLeft: '10px solid #006bb6',
					borderRadius: '15px 0px 0px 15px',
				}}
			>
				<CardContent><Skeleton count={4} /></CardContent></Card>)}</div> : <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: '13px 0px'}}>
				{
					formList.map((form:any) => (
						<Card
							key={form.uid}
							style={{
								flexGrow: '1',
								minWidth: '350px',
								maxWidth: '350px',
								display: 'flex',
								flexDirection: 'column',
								borderLeft: '10px solid #006bb6',
								borderRadius: '15px 0px 0px 15px',
							}}
						>
							<CardContent>
								<Typography mb={1.5}
									variant='h5'
									component='div'
									style={{display: 'flex', justifyContent: 'space-between'}}
								>
									{form.name}
									<div style={{display: 'flex', cursor: 'pointer', gap: '1rem', flexWrap: 'wrap'}}><PreviewIcon onClick={() => handlePreview(form)} className='cursor-pointer'></PreviewIcon><SendIcon onClick={() => handleSend(form)} className='cursor-pointer'></SendIcon></div>
								</Typography>
								<Typography mb={1.5}
									variant='body2'>
									{form.type}
								</Typography>
							</CardContent>
							<CardActions style={{alignSelf: 'end'}}>
								<Button
									onClick={() => showDeleteModal(form)}
									variant='outlined'
									size='small'>
                                Delete
								</Button>
								<Button
									onClick={() => handleOpen(form)}
									variant='contained'
									size='small'>
                                View Response
								</Button>
							</CardActions>
						</Card>
					))
				}
			</div>}
		</div>
	);
};

import {UploadOutlined} from '@ant-design/icons';
import {Button, Col, Upload} from 'antd';
import React, { FC } from 'react';

type FileUploadCompProps = {
	properties: any;
	dataSource: any;
}

const FileUploadComp: FC<FileUploadCompProps> = ({properties}) => {
	const {label, upload} = properties.column_properties;
	const {file_type, max_count, multiple} = upload;
	const {label_name, label_style, show_label} = label;
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				show_label && (
					<label
						htmlFor={label_name}
						style={{...label_style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Upload
				type={file_type}
				maxCount={
					max_count === '' || max_count > 0
						? max_count
						: undefined
				}
				multiple={multiple}
			>
				<Button icon={<UploadOutlined />}>Click to Upload</Button>
			</Upload>
		</Col>
	);
};

export default FileUploadComp;

// Un-used
import { Col, Select } from 'antd';
import React, {Dispatch, SetStateAction, FC} from 'react';

type PositionProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const Position: FC<PositionProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {
		alignItems = '',
		justifyContent = '',
	} = activeElement.element[`${type}_style`];
	const handleChange = (value: any, type: string): void => {
		setValue(value);
		setChangeType(type);
	};

	return (
		<Col span={24}>
			<table>
				<tbody>
					<tr>
						<td>H-Position</td>
						<td>
							<Select
								style={{width: '100%'}}
								options={[
									{
										label: 'Left',
										value: 'left',
									},
									{
										label: 'Center',
										value: 'center',
									},
									{
										label: 'Right',
										value: 'right',
									},
								]}
								// eslint-disable-next-line max-len
								onChange={(value: string) => handleChange(value, 'justifyContent')}
								value={justifyContent || undefined}
							/>
						</td>
					</tr>
					<tr>
						<td>V-Position</td>
						<td>
							<Select
								style={{width: '100%'}}
								options={[
									{
										label: 'Top',
										value: 'top',
									},
									{
										label: 'Center',
										value: 'center',
									},
									{
										label: 'Bottom',
										value: 'bottom',
									},
								]}
								// eslint-disable-next-line max-len
								onChange={(value: string) => handleChange(value, 'alignItems')}
								value={alignItems || undefined}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</Col>
	);
};

export default Position;

import {
	DeleteOutlined,
	PlusOutlined,
	EditOutlined,
	ZoomInOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Table,
} from 'antd';
import {snakeCase } from 'lodash';
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import '../style.css';
import {
	currentUser,
	deleteAPI,
	getAPI,
	updateAPI,
} from '../../../api/api';
import {v4 as uuid} from 'uuid';

type CreateWorkFLowProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>;
	setThemeColor: Dispatch<SetStateAction<string>>;
};
const CreateWorkFLow:FC<CreateWorkFLowProps> = ({setFullScreen}) => {
	const [workflow] = Form.useForm();
	const navigate = useNavigate();
	const [workFlowData, setWorkFlowData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const [workflowId, setWorkFlowId] = useState('');

	const fetchData = (): void => {
		setLoading(true);
		getAPI('/workflow').then((res: any) => {
			if (res.data.success) {
				setWorkFlowData(res.data.data);
			} else {
				message.error('Something went wrong');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	const deleteWorkFlow = (workflowId: string): void => {
		setLoading(true);
		deleteAPI('/workflow', {workflow_id: workflowId}).then(res => {
			if (res.data.success) {
				fetchData();
				message.success('Workflow deleted successfully');
			} else {
				message.error('Workflow deleted failed');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	const handleEdit = (data: any): void => {
		const {workflowId = '', workflowName = '', description = ''} = data;
		workflow.setFieldsValue({
			workflow_name: workflowName,
			description: description,
		});
		setWorkFlowId(workflowId);
		setIsModalOpen(true);
		setEdit(true);
	};

	const onFinish = (values: any): void => {
		setLoading(true);
		const {workflow_name = '', description = ''} = values;
		let data: any = {
			workflow_name,
			entity: snakeCase(workflow_name),
			description,
			workflow_json: '{}',
			created_by: currentUser,
			modified_by: currentUser,
		};
		if (edit) {
			data = {...data, workflow_id: workflowId};
		} else {
			data = {...data, workflow_id: uuid()};
		}

		updateAPI('/workflow', {data}).then(res => {
			if (res.data.success) {
				fetchData();
				message.success('Workflow saved successfully');
			} else {
				message.error('Something went wrnog while saving Workflow');
			}

			setLoading(false);
			setIsModalOpen(false);
			setEdit(false);
			workflow.resetFields();
		}).catch(() => {
			message.error('Something went wrnog while saving Workflow');
			setIsModalOpen(false);
			setEdit(false);
			setLoading(false);
			workflow.resetFields();
		});
	};

	useEffect(() => {
		setFullScreen(true);
		fetchData();
	}, []);

	const dataSource = workFlowData;
	const columns:any = [
		{
			title: 'Work Flow Name',
			dataIndex: 'workflowName',
			width: '30%',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			width: '40%',
		},
		{
			title: 'Edit',
			dataIndex: '',
			width: '10%',
			// eslint-disable-next-line max-len
			render: (workflow: {workflowId: string}) => (<EditOutlined key={workflow.workflowId} onClick={() => handleEdit(workflow)} />),
		},
		{
			title: 'View',
			dataIndex: '',
			width: '10%',

			render: (workflow: {workflowId: string}) => (<ZoomInOutlined
				key={workflow.workflowId}
				onClick={
					() => navigate(`/workflow_actions/${workflow.workflowId}`)
				}
			/>),
		},
		{
			title: 'Delete',
			dataIndex: '',
			width: '10%',
			render: (workflow: {workflowId: string}) => (
				<Popconfirm
					title='Are you sure to delete this app?'
					onConfirm={() => {
						deleteWorkFlow(workflow.workflowId);
					}}
					onCancel={() => null}
					okText='Yes'
					cancelText='No'
				>
					<DeleteOutlined key={workflow.workflowId} />
				</Popconfirm>),
		},
	];
	return (
		<Row>
			<Col span={24}>
				<Button
					type='primary'
					icon={<PlusOutlined />}
					size='large'
					onClick={() => setIsModalOpen(true)}
				>
                    Create Work FLow
				</Button>
			</Col>
			<Col span={24} style={{marginTop: '10px'}}>
				<Table
					loading={loading}
					dataSource={dataSource}
					columns={columns}
				/>
			</Col>
			<Modal
				title={edit ? 'Update Work Flow' : 'Create Work Flow'}
				open={isModalOpen}
				onCancel={() => {
					setIsModalOpen(false);
					workflow.resetFields();
				}}
				footer={false}
			>
				<Col span={24}>
					<Form
						name='workflow'
						autoComplete='off'
						layout='vertical'
						form={workflow}
						onFinish={onFinish}
					>
						<Form.Item
							label='Work Flow Name'
							name={'workflow_name'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please Enter Work Flow Name!' }]}
						>
							<Input placeholder='Enter Work Flow Name' />
						</Form.Item>
						<Form.Item
							label='Description'
							name={'description'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please Enter Description!' }]}
						>
							<Input placeholder='Enter Description' />
						</Form.Item>
						<Form.Item>
							<Col span={24}>
								<Row style={{
									justifyContent: 'end', gap: '10px'}}>
									<Button type='default'
										onClick={() => {
											setIsModalOpen(false);
											workflow.resetFields();
										}}
									>
										Cancel
									</Button>
									<Button type='primary' htmlType='submit'>
										Submit
									</Button>
								</Row>
							</Col>
						</Form.Item>
					</Form>
				</Col>
			</Modal>
		</Row>
	);
};

export default CreateWorkFLow;

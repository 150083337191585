import {Select} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';

type LabelAlignProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelAlign: FC<LabelAlignProps> = ({
	activeElement,
	setChangeType,
	setValue,
}) => {
	const {
		textAlign = '',
	} = activeElement.element.column_properties.label.label_style;
	const [align, setAlign] = useState(textAlign);

	const handleLabelAlign = (value: string): void => {
		setAlign(value);
	};

	useEffect(() => {
		setChangeType('textAlign');
		setValue(align);
	}, [align]);

	return (
		<div>
			<Select
				style={{width: '100%'}}
				value={textAlign}
				onChange={handleLabelAlign}
				options={[
					{
						value: 'left',
						label: 'Left',
					},
					{
						value: 'right',
						label: 'Right',
					},
					{
						value: 'center',
						label: 'Center',
					},
					{
						value: 'justify',
						label: 'Justify',
					},
				]}
			/>
		</div>
	);
};

export default LabelAlign;

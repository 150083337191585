import {Input, Modal} from 'antd';
import {get} from 'lodash';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';
import {SketchPicker} from 'react-color';

type LabelColorPickerProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelColorPicker: FC<LabelColorPickerProps> = (
	{
		activeElement,
		setChangeType,
		setValue,
	}) => {
	// eslint-disable-next-line max-len
	const {color = ''} = activeElement.element.column_properties.label.label_style;
	const [labelColor, setLabelColor] = useState(color);
	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {
		setChangeType('color');
		setValue(labelColor);
	}, [labelColor]);

	return (
		<div>
			<Input
				className='color-picker'
				placeholder='#000000'
				value={color}
				onClick={() => {
					setIsModalVisible(true);
				}}
			/>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={labelColor}
					onChange={
						event => {
							setLabelColor(get(event, 'hex', ''));
						}
					}
				/>
			</Modal>
		</div>
	);
};

export default LabelColorPicker;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import { Spin, Row, Col, Layout, Button } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

const LayoutCreator = () => {
	const navigate = useNavigate();
	const { application_uid } = useParams();
	const [loading] = useState(false);
	const [selectedLayout, setSelectedLayout] = useState('');
	const handleChange = (value: string): any => {
		setSelectedLayout(value);
	};

	return (
		<Spin spinning={loading}>
			<Row className='layout'>
				<Col span={24}>
					<Row className='layout-header'>
							Select Layout
						<Button
							type='primary'
							disabled={!selectedLayout}
							onClick={() =>
								// eslint-disable-next-line max-len
								navigate(`/layout/${selectedLayout}/${application_uid}`)
							}
						>
						Next
						</Button>
					</Row>
					<Row className='layout-container'>
						<Col span={12} className='p-20'>
							<Layout
								onClick={() => handleChange('layout_1')}
								className='layout'
							>
								<Header
									className='layout1-header d-flex color-1'>
										Header
								</Header>
								<Content
									className='layout1-content d-flex color-2'>
										Content
								</Content>
								<Footer
									className='layout1-footer d-flex color-1'>
										Footer
								</Footer>
								<div
									className={
										`${selectedLayout === 'layout_1'
											? 'overlay' : ''}`}
								></div>
							</Layout>
						</Col>
						<Col span={12} className='p-20'>
							<Layout onClick={() => handleChange('layout_2')}>
								<Header
									className='layout2-header d-flex color-1'>
										Header
								</Header>
								<Layout>
									<Sider
										className='layout2-sider d-flex
										color-3'>
											Sider
									</Sider>
									<Content
										className='layout2-content d-flex
										color-2'>
											Content
									</Content>
								</Layout>
								<Footer
									className='layout2-footer d-flex color-1'>
										Footer
								</Footer>
								<div
									className={`${
										selectedLayout === 'layout_2'
											? 'overlay' : ''}`}
								></div>
							</Layout>
						</Col>
						<Col span={12} className='p-20'>
							<Layout onClick={() => handleChange('layout_3')}>
								<Header
									className='layout3-header d-flex color-1'>
										Header
								</Header>
								<Layout>
									<Content
										className='layout3-content d-flex
										color-2'>
											Content
									</Content>
									<Sider
										className='layout3-sider d-flex
										color-3'>
											Sider
									</Sider>
								</Layout>
								<Footer
									className='layout3-footer d-flex color-1'>
											Footer
								</Footer>
								<div
									className={`${
										selectedLayout === 'layout_3'
											? 'overlay' : ''}`}
								></div>
							</Layout>
						</Col>
						<Col span={12} className='p-20'>
							<Layout onClick={() => handleChange('layout_4')}>
								<Sider
									className='layout4-sider d-flex color-3'>
									Sider
								</Sider>
								<Layout>
									<Header
										className='layout4-header d-flex
										color-1'>
											Header
									</Header>
									<Content
										className='layout4-content d-flex
										color-2'>
											Content
									</Content>
									<Footer
										className='layout4-footer color-1'>
										Footer
									</Footer>
								</Layout>
								<div
									className={`${
										selectedLayout === 'layout_4'
											? 'overlay' : ''}`}
								></div>
							</Layout>
						</Col>
					</Row>
				</Col>
			</Row>
		</Spin>
	);
};

export default LayoutCreator;

/* eslint-disable no-unused-vars */
import { Col, Progress } from 'antd';
import React, { FC } from 'react';

type ProgressCompProps = {
	properties: any;
	dataSource: any;
}
const ProgressComp:FC<ProgressCompProps> = ({
	properties,
	dataSource,
}) => {
	const progress = '';
	return (
		<Col span={24}>
			<Progress percent={50} />
		</Col>
	);
};

export default ProgressComp;

/* eslint-disable max-len */
import {Col, Input, Row, Select, Switch} from 'antd';
import React, {useState, useEffect, Dispatch, SetStateAction, FC} from 'react';
import Icon from '../../icons/get-icon';
import { Icons } from '../../icons/icons-list';
import { widget_type } from '../../properties-obj/widget-properties-obj';
import LabelStyle from './label/label';

type SwitchPropertiesProps = {
	setSectionValues: Dispatch<SetStateAction<any>>;
	sectionValues: any;
	activeElement: any;
}

const SwitchProperties: FC<SwitchPropertiesProps> = ({
	setSectionValues,
	activeElement,
	sectionValues,
}) => {
	const {
		toggle = {
			default_checked: false,
			checked_name: '',
			unchecked_name: '',
			checked_icon: '',
			unchecked_icon: '',
			variable_name: '',
			value: false,
		},
	} = activeElement.element.column_properties;
	const {
		default_checked,
		checked_name,
		unchecked_name,
		checked_icon,
		unchecked_icon,
		variable_name,
	} = toggle;
	const [defaultChecked, setDefaultChecked] = useState(default_checked);
	const [checkedName, setCheckedName] = useState(checked_name);
	const [unCheckedName, setUnCheckedName] = useState(unchecked_name);
	const [checkedIcon, setCheckedIcon] = useState(checked_icon);
	const [unCheckedIcon, setUnCheckedIcon] = useState(unchecked_icon);
	const [variableName, setVariableName] = useState(variable_name);
	const [changeType, setChangeType] = useState('');

	const handleChange = (value: string | boolean, type: string): void => {
		setChangeType(type);
		// eslint-disable-next-line default-case
		switch (type) {
			case 'variable_name':
				setVariableName(value);
				break;
			case 'default_checked':
				setDefaultChecked(value);
				break;
			case 'unchecked_name':
				setUnCheckedName(value);
				break;
			case 'checked_name':
				setCheckedName(value);
				break;
			case 'unchecked_icon':
				setUnCheckedIcon(value);
				break;
			case 'checked_icon':
				setCheckedIcon(value);
				break;
		}
	};

	useEffect(() => {
		setSectionValues(
			sectionValues.map((sec: {section_uid: string, row: []}) => {
				const obj: any = {
					default_checked: defaultChecked,
					checked_name: checkedName,
					unchecked_name: unCheckedName,
					checked_icon: checkedIcon,
					unchecked_icon: unCheckedIcon,
					variable_name: variableName,
				};
				const rowRecursion = (row: any): void => {
					row.forEach((row: any) => {
						row.column.forEach((col: any) => {
							if (col.column_uid === activeElement.element.column_uid) {
								col.column_properties.toggle[changeType] = obj[changeType];
							} else if (widget_type.includes(col.widget_type)) {
								if (col.column_properties[col.widget_type].row) {
									rowRecursion(col.column_properties[col.widget_type].row);
								}
							} else if (col.row) {
								rowRecursion(col.row);
							}
						});
					});
				};

				if (sec.section_uid === activeElement.section_uid) {
					rowRecursion(sec.row);
				}

				return sec;
			}),
		);
	}, [
		defaultChecked,
		checkedName,
		checkedIcon,
		unCheckedName,
		unCheckedIcon,
		variableName,
	]);

	return (
		<div>
			<Row>
				<Col span={24}>
					<table>
						<thead>
							<tr>
								<th colSpan={2}>Toggle</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={2} style={{padding: '0'}}>
									<LabelStyle
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								</td>
							</tr><tr>
								<td>Default Checked</td>
								<td>
									<Switch
										checked={defaultChecked}
										onChange={(value: boolean) => handleChange(value, 'default_checked')}
									/>
								</td>
							</tr>
							<tr>
								<td>Checked Name</td>
								<td>
									<Input
										value={checkedName}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'checked_name')}
									/>
								</td>
							</tr>
							<tr>
								<td>UnChecked Name</td>
								<td>
									<Input
										value={unCheckedName}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'unchecked_name')}
									/>
								</td>
							</tr>
							<tr>
								<td>Checked Icon</td>
								<td>
									<Select
										style={{width: '100%'}}
										value={checkedIcon}
										onChange={(value: string) => {
											setCheckedIcon(value);
											setChangeType('checked_icon');
										}}
										showSearch
									>
										{Icons.map((item: string, index: number) => (
											<Select.Option key={index} value={item}>
												<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
													<Icon type={item} />
												</div>
											</Select.Option>
										))}
									</Select>
								</td>
							</tr>
							<tr>
								<td>UnChecked Icon</td>
								<td>
									<Select
										style={{width: '100%'}}
										value={unCheckedIcon}
										onChange={(value: string) => {
											setUnCheckedIcon(value);
											setChangeType('unchecked_icon');
										}}
										showSearch
									>
										{Icons.map((item: string, index: number) => (
											<Select.Option key={index} value={item}>
												<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
													<Icon type={item} />
												</div>
											</Select.Option>
										))}
									</Select>
								</td>
							</tr>
							<tr>
								<td>Variable Name</td>
								<td>
									<Input
										value={variableName}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'variable_name')}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	);
};

export default SwitchProperties;

import { Collapse, Select, Table } from 'antd';
import * as React from 'react';
import { get } from 'lodash';

const { Panel } = Collapse;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const StatComponent = (props: any) => {
	const { graphChange, graphQuery, statFilters, statData, columns } = props;
	return (
		<Collapse>
			<Panel header='View Stat' key='1'>
				<div>
                    Choose : <Select
						style={{ width: 200, marginBottom: '10px' }}
						onChange={graphChange}
						value={graphQuery}
						options={
							get(statFilters, 'options', [])}
					/>
				</div>
				<Table dataSource={statData}
					columns={columns}
					bordered={true}
					size='small' scroll={{ x: true }} />
			</Panel>
		</Collapse>
	);
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {TextField, TextFieldProps} from '@mui/material';
import React from 'react';

type CustomTextField = TextFieldProps

const TextFieldComp = (props:CustomTextField) => (
	<TextField
		{...props}
	>
	</TextField>
);

TextFieldComp.defaultProps = {
	autoWidth: false,
	autoFocus: false,
	color: 'primary',
	classes: {},
	disabled: false,
	error: false,
	fullWidth: false,
	placeholder: '',
	select: false,
	defaultOpen: false,
	defaultValue: '',
	label: '',
	displayEmpty: false,
	id: '',
	multiple: false,
	sx: '',
	variant: 'outlined',
};

export default TextFieldComp;

import {Button, Col} from 'antd';
import React, { FC } from 'react';
import Icon from '../icons/get-icon';
import './style.css';
type ButtonCompProps = {
	properties: any;
	dataSource: any;
}

const ButtonComp: FC<ButtonCompProps> = ({properties}) => {
	const {
		icon,
		shape,
		size,
		title,
		type,
		block,
		icon_position,
		width = '',
		color = '',
		backgroundColor = '',
		btn_align = '',
	} = properties.column_properties.button;
	return (
		<Col span={24}>
			<Button
				type={type || 'default'}
				icon={icon === '' ? undefined : <Icon type={icon} />}
				size={size || 'default'}
				shape={shape || 'default'}
				block={block}
				className='page-creator-button'
				style={{
					flexDirection: icon_position === 'left' ? 'row'
						: icon_position === 'right' ? 'row-reverse' : 'row',
					width: width,
					color: color,
					backgroundColor: backgroundColor,
					boxShadow: 'none',
					// eslint-disable-next-line max-len
					margin: `${btn_align === 'left' ? '0 auto 0 0' : btn_align === 'right' ? '0 0 0 auto' : 'auto'}`,
				}}
			>
				{title || ''}
			</Button>
		</Col>
	);
};

export default ButtonComp;

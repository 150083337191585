import {Input, Modal} from 'antd';
import {get} from 'lodash';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';
import {SketchPicker} from 'react-color';

type LabelBackgroundColorPickerProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelBackgroundColorPicker: FC<LabelBackgroundColorPickerProps> = (
	{
		activeElement,
		setChangeType,
		setValue,
	}) => {
	const {
		backgroundColor = '',
	} = activeElement.element.column_properties.label.label_style;
	const [
		labelBackgroundColor,
		setLabelBackgroundColor,
	] = useState(backgroundColor);
	const [isModalVisible, setIsModalVisible] = useState(false);
	useEffect(() => {
		setChangeType('backgroundColor');
		setValue(labelBackgroundColor);
	}, [labelBackgroundColor]);

	return (
		<div>
			<Input
				className='color-picker'
				placeholder='#000000'
				value={labelBackgroundColor}
				onClick={() => {
					setIsModalVisible(true);
				}}
			/>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={labelBackgroundColor}
					onChange={
						event => {
							setLabelBackgroundColor(get(event, 'hex', ''));
						}
					}
				/>
			</Modal>
		</div>
	);
};

export default LabelBackgroundColorPicker;

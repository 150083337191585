import { Image, Col } from 'antd';
import React, {FC} from 'react';

type ImageCompProps = {
	properties: any;
	dataSource: any;
}

const ImageComp: FC<ImageCompProps> = ({properties}) => {
	const {
		src,
		preview,
		height,
		width,
		alt,
		img_style} = properties.column_properties.image;
	return (
		<Col span={24} style={{height: '100%'}}>
			<Image
				src={src}
				preview={preview}
				height={height || '100%'}
				width={width || '100%'}
				alt={alt}
				style={{...img_style}}
			/>
		</Col>
	);
};

export default ImageComp;

import {InputNumber} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';

type LabelFontSizeProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelFontSize: FC<LabelFontSizeProps> = ({
	activeElement,
	setChangeType,
	setValue,
}) => {
	const {
		fontSize = '',
	} = activeElement.element.column_properties.label.label_style;
	const [size, setSize] = useState(fontSize);
	const handleLabelFontSize = (e: any): void => {
		setSize(e);
	};

	useEffect(() => {
		setChangeType('fontSize');
		setValue(size);
	}, [size]);

	return (
		<div>
			<InputNumber
				value={size}
				min={1}
				onChange={handleLabelFontSize}
			/>
		</div>
	);
};

export default LabelFontSize;

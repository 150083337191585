/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { message } from 'antd';
import axios from 'axios';
import { fetchData } from './api';
import { get } from 'lodash';

function filterKey(formObj: any, colValue: string) {
	const result = Object.keys(formObj)
		.filter(item => item.includes(colValue))
		.reduce((obj: any, key) => Object.assign(obj, {
			[key]: formObj[key],
		}), {});

	return result;
}

export const lookupActionResponse = (props: any) => {
	const { form = [], setform, loader, formDetail = {}} = props;

	fetchData('/lookups/values')
		.then(res => {
			const modifiedForm = form.map((item: any) => {
				if (item.lookupUid) {
					const getValues
						= res.data.find((val: any) =>
							val.lookupUid === item.lookupUid).value;
					const getDropdownValues = JSON.parse(getValues);
					if (get(getDropdownValues, 'type', '') === 'url') {
						const {data = []} = getDropdownValues;
						const colName = get(getDropdownValues, 'col');
						const filteredData
						= data.filter((item: any) =>
							item.value === get(
								filterKey(formDetail,
									colName),
								colName));
						if (!`${get(filteredData, '[0].apiUrl', '')}`) {
							return item;
						}

						return axios(`${get(filteredData,
							'[0].apiUrl',
							'')}`)
							.then((resp: any) => {
								const value = resp.data.data;
								const result = value.map((obj: any) =>
									obj[get(filteredData, '[0].field')]);
								item.value = result;
								return item;
							})
							.catch((error: any) =>
								message.error(error.message));
					}

					item.value = getDropdownValues;
				}

				return item;
			});
			Promise.all(modifiedForm).then((promiseResp: any) => {
				setform(promiseResp);
				if (get(props, 'loader')) {
					loader(false);
				}
			});
		})
		.catch((error: any) => message.error(error.message));
};

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {PlusOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import { createSection } from '../../properties-obj/properties-obj';
import './style.css';
type PropoverProps = {
  setSectionValues: Dispatch<SetStateAction<any>>;
  sectionValues: any;
};
const AddSection = ({
	setSectionValues,
	sectionValues,
}: PropoverProps) => {
	const addSection = () => {
		setSectionValues([...sectionValues, createSection()]);
		message.success('Section Added Successfully');
	};

	return (
		<div>
			<Button
				className='add-section'
				type='primary'
				icon={<PlusOutlined />}
				block
				ghost
				onClick={addSection}
			>Add Section
			</Button>
		</div>
	);
};

export default AddSection;

import {Col, Select} from 'antd';
import React, {FC} from 'react';

type DropdownCompProps = {
	properties: any;
	dataSource: any;
}

// eslint-disable-next-line max-len
const DropdownComp: FC<DropdownCompProps> = ({properties}: DropdownCompProps) => {
	const {label, dropdown} = properties.column_properties;
	// eslint-disable-next-line max-len
	const {placeholder, selectMode, defaultValue, show_search = false} = dropdown;
	const style = label.label_style;
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Select
				placeholder={placeholder}
				value={defaultValue === '' ? undefined : defaultValue}
				mode={selectMode === 'multiple' ? selectMode : undefined}
				options={[]}
				showSearch={show_search}
			/>
		</Col>
	);
};

export default DropdownComp;

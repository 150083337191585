import {
	DeleteOutlined,
	EditOutlined,
	MoreOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	Input,
	Menu,
	message,
	Modal,
	Row,
	Select,
	Spin,
	Popover,
} from 'antd';
import { Icons } from '../icons/icons-list';
import Icon from '../icons/get-icon';
import React,
{ Dispatch, SetStateAction, useEffect, useState, FC } from 'react';
import './style.css';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { getAPI, updateAPI } from '../../api/api';
import Settings from './settings/settings';
type MenuCreatorProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>;
	setThemeColor: Dispatch<SetStateAction<string>>;
};

const MenuCreator: FC<MenuCreatorProps> = ({
	setFullScreen, setThemeColor }) => {
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const { application_uid } = useParams();
	const { SubMenu, Item } = Menu;
	const [menuValues, setMenuValues] = useState<any>([]);
	const [menuType, setMenuType] = useState('');
	const [menuName, setMenuName] = useState('');
	const [pageData, setPageData] = useState<any>([]);
	const [menuUid, setMenuUId] = useState('');
	const [menuItemId, setMenuItemId] = useState('');
	const [subMenu, setSubMenu] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [menuData, setMenuData] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [currentMenu, setCurrentMenu] = useState<any>('');
	const [isMenuItemModalOpen, setIsMenuItemModalOpen] = useState(false);
	const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState<any>([]);
	const [menuProperties, setMenuProperties] = useState<any>({
		mode: '',
		primaryColor: '',
		text: {
			color: '',
			fontSize: '',
			textAlign: '',
			fontWeight: '',
			fontStyle: '',
		},
		theme: '',
		icon: {
			color: '',
		},
	});

	const fetchData = (): void => {
		setLoading(true);
		getAPI(`/menus/menu-json/${application_uid}`)
			.then((res: any) => {
				if (res.data.success) {
					const { data = [] }: any = res.data;
					if (!isEmpty(data)) {
						const styles = JSON.parse(data[0].menuStyles);
						setMenuData(data);
						setMenuUId(data[0].menuUid);
						setMenuName(data[0].menuName);
						setMenuValues(JSON.parse(data[0].menuJson));
						setMenuProperties(styles);
						setThemeColor(styles.primaryColor);
					}

					getAPI(`/pages/${application_uid}`)
						.then((res: any) => {
							if (res.data.success) {
								const { data } = res.data;
								if (!isEmpty(data)) {
									setPageData(data);
									const options: any = [];
									for (let i = 0; i < data.length; i++) {
										options[i] = {
											value: data[i].pageRoute,
											label: data[i].pageName,
										};
									}

									setOptions(options);
								}
							} else {
								message.error('Something went wrong');
							}
						}).catch(() => {
							message.error('Something went wrong');
						});
				} else {
					message.error('Something went wrong');
				}

				setLoading(false);
			});
	};

	const onContinue = (values: any): void => {
		setMenuName(values.menu_name);
		setIsMenuModalOpen(false);
		setIsMenuItemModalOpen(true);
	};

	const onFinish = (values: any): void => {
		const {
			menu_item_name = '',
			menu_item_route = '',
			menu_item_icon = '',
			menu_item_url = '',
		} = values;
		const copyValues = menuValues;
		// eslint-disable-next-line no-negated-condition
		const page: any = !isEmpty(menu_item_route) ? pageData.find(
			(page: any) => page.pageRoute === menu_item_route) : '';
		const data = {
			menu_item_uid: uuid(),
			menu_item_name,
			menu_item_route,
			menu_item_icon,
			menu_item_url,
			page_uid: page.pageUid,
			sub_menu_item: [],
		};
		if (menuType === 'sub-menu') {
			const menuRecursion = (menus: any): void => {
				// eslint-disable-next-line array-callback-return
				menus.map((menu: any) => {
					if (menu.menu_item_uid === menuItemId) {
						menu.sub_menu_item.push(data);
						setMenuValues(copyValues);
						form2.resetFields();
						setIsMenuItemModalOpen(false);
					} else if (!isEmpty(menu.sub_menu_item)) {
						menuRecursion(menu.sub_menu_item);
					}
				});
			};

			menuRecursion(copyValues);
		} else if (menuType === 'edit-menu') {
			const menuRecursion = (menus: any): void => {
				// eslint-disable-next-line array-callback-return
				menus.map((menu: any, index: number) => {
					if (menu.menu_item_uid === menuItemId) {
						data.menu_item_uid = menuItemId;
						data.sub_menu_item = subMenu;
						menus[index] = data;
						setMenuValues(copyValues);
						form2.resetFields();
						setIsMenuItemModalOpen(false);
					} else if (!isEmpty(menu.sub_menu_item)) {
						menuRecursion(menu.sub_menu_item);
					}
				});
			};

			menuRecursion(copyValues);
		} else {
			setMenuValues((prev: any) => [...prev, data]);
			form2.resetFields();
			setIsMenuItemModalOpen(false);
		}
	};

	const handleCreateMenuClick = (): void => {
		setMenuType('menu');
		if (isEmpty(menuName)) {
			setIsMenuModalOpen(true);
		} else {
			setIsMenuItemModalOpen(true);
		}
	};

	const handleSaveClick = (): void => {
		setLoading(true);
		const data = {
			menu_uid: menuUid === '' ? uuid() : menuUid,
			menu_name: menuName,
			menu_json: JSON.stringify(menuValues),
			menu_styles: JSON.stringify(menuProperties),
			application_uid,
		};
		updateAPI('/menus/create-menu', { data }).then(res => {
			if (res.data.success) {
				fetchData();
				message.success('Menu saved successfully');
			} else {
				message.error('Something went wrong while saving menu');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
		});
	};

	const menuRecursion = (menus: any): any => (menus.map(
		(menu: any) => {
			const { icon, text } = menuProperties;
			const crudContent = (
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					gap: '15px',
					cursor: 'pointer',
				}}>
					<span>
						<PlusOutlined onClick={() => {
							setMenuType('sub-menu');
							setIsMenuItemModalOpen(true);
							setMenuItemId(menu.menu_item_uid);
						}} />
					</span>
					<span>
						<EditOutlined
							onClick={() => editMenuItem(menu.menu_item_uid)}
						/>
					</span>
					<span>
						<DeleteOutlined
							onClick={() => deleteMenuItem(menu.menu_item_uid)}
						/>
					</span>
				</div>
			);
			if (!isEmpty(menu.sub_menu_item)) {
				return (
					<SubMenu key={menu.menu_item_uid}
						title={
							<div>
								<div
									className='icon'
								>
									<Popover
										placement='top'
										trigger='hover'
										content={crudContent}>
										<MoreOutlined />
									</Popover>
								</div>
								<div>
									<div style={{
										display: 'flex',
										gap: '10px',
									}}>
										{
											!isEmpty(menu.menu_item_icon) && (
												<span style={{ ...icon }}>
													<Icon
														type={
															menu.menu_item_icon
														}
													/>
												</span>
											)
										}
										<span style={{ ...text,
											width: '100%',
										}}>
											{menu.menu_item_name}
										</span>
									</div>
								</div>
							</div>
						}>
						{menuRecursion(menu.sub_menu_item)}
					</SubMenu>
				);
			}

			return (
				<Item key={menu.menu_item_uid}>
					<div
						className='icon'
					>
						<Popover
							placement='top'
							trigger='hover'
							content={crudContent}
						>
							<MoreOutlined />
						</Popover>
					</div>
					<div>
						<Link
							to={`/${menu.menu_item_route}`}>
							<div style={{ display: 'flex', gap: '10px' }}>
								{
									!isEmpty(menu.menu_item_icon) && (
										<span style={{ ...icon }}>
											<Icon type={menu.menu_item_icon} />
										</span>
									)
								}
								<span style={{ ...text, width: '100%' }}>
									{menu.menu_item_name}
								</span>
							</div>
						</Link>
					</div>
				</Item>
			);
		},
	));

	const deleteMenuItem = (uid: string): void => {
		const menuDeleteRecursion = (menus: any): void => menus.filter(
			(menu: any) => {
				if (menu.menu_item_uid === uid) {
					return null;
				// eslint-disable-next-line no-else-return
				} else if (!isEmpty(menu.sub_menu_item)) {
					// eslint-disable-next-line max-len
					menu.sub_menu_item = menuDeleteRecursion(menu.sub_menu_item);
				}

				return menu;
			});
		setMenuValues(menuDeleteRecursion(menuValues));
	};

	const editMenuItem = (uid: string): void => {
		// eslint-disable-next-line max-len, array-callback-return
		const menuRecursion = (menus: any): void => menus.filter((menu: any) => {
			if (menu.menu_item_uid === uid) {
				setMenuType('edit-menu');
				setSubMenu(menu.sub_menu_item);
				setMenuItemId(menu.menu_item_uid);
				setIsMenuItemModalOpen(true);
				return menu;
			// eslint-disable-next-line no-else-return
			} else if (!isEmpty(menu.sub_menu_item)) {
				menuRecursion(menu.sub_menu_item);
			}
		});

		setCurrentMenu(menuRecursion(menuValues));
	};

	useEffect(() => {
		setFullScreen(true);
		fetchData();
	}, []);

	return (
		<Spin spinning={loading}>
			<Row className='menus dynamic-app-container'>
				<Col span={18} style={{ paddingRight: '15px' }}>
					<Col span={24} style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '10px',
					}}>
						<div>
							<div style={{ marginBottom: '10px' }}>
								<Button
									className='add-section'
									type='primary'
									icon={<PlusOutlined />}
									style={{ margin: 0 }}
									onClick={handleCreateMenuClick}
								>{isEmpty(menuValues) ? 'Create Menu'
										: 'Add Menu'}
								</Button>
							</div>
						</div>
						<div>
							<Button type='text' size='large'>Cancel</Button>
							<Button
								type='primary'
								size='large'
								onClick={handleSaveClick}
							>Save</Button>
						</div>
						{/* <Switch /> */}
					</Col>
					<Col span={24}>
						<Menu
							mode={
								menuProperties.mode === '' ? 'vertical'
									: menuProperties.mode
							}
							className='menu'
							theme={
								menuProperties.theme === '' ? 'light'
									: menuProperties.theme}
							// eslint-disable-next-line max-len
							style={{ width: menuProperties.mode === 'horizontal' ? '100%' : 256 }}
						>
							{
								!isEmpty(menuValues) && (
									menuRecursion(menuValues)
								)
							}
						</Menu>
					</Col>
				</Col>
				<Col span={6}>
					<Col span={24}>
						<div className='settings'>
							<div className='card'>
								<Col
									className='card-title'
									span={24}
									style={{ padding: '7px 10px' }}
								>
									<p>Properties</p>
								</Col>
								<Col span={24}>
									{
										// eslint-disable-next-line max-len
										// eslint-disable-next-line no-negated-condition
										!isEmpty(menuValues) ? (
											<Settings
												setThemeColor={setThemeColor}
												menuProperties={menuProperties}
												setMenuProperties={
													setMenuProperties
												}
											/>
										) : (
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													minHeight: '100px',
													height: '100%',
													width: '100%',
												}}>
												<h3>Create Menu</h3>
											</div>
										)
									}
								</Col>
							</div>
						</div>
					</Col>
				</Col>
				<Modal
					open={isMenuModalOpen}
					onCancel={() => {
						setIsMenuModalOpen(false);
						form1.resetFields();
					}}
					footer={false}
					title={'Add Menu'}
				>
					<Form
						form={form1}
						name='menu-creator'
						className='menu-creator'
						onFinish={onContinue}
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						autoComplete='off'
					>
						<Form.Item
							label='Menu Name'
							name='menu_name'
							style={{ marginBottom: '4px' }}
							rules={[
								{
									required: true,
									message: 'Please enter Menu name!',
								},
							]}
						>
							<Input
								placeholder='Enter Menu Name...'
								min={1}
							/>
						</Form.Item>
						<Form.Item style={{
							display: 'flex',
							justifyContent: 'end',
							marginTop: '20px',
						}}>
							<Button type='primary' htmlType='submit'>
								Continue
							</Button>
						</Form.Item>
					</Form>
				</Modal>
				<Modal
					open={isMenuItemModalOpen}
					onCancel={() => {
						setIsMenuItemModalOpen(false);
						setMenuType('');
						form1.resetFields();
					}}
					footer={false}
					title={
						menuType === 'sub-menu' ? 'Add Sub Menu'
							: menuType === 'edit-menu' ? 'Update Menu'
								: 'Add Menu'
					}
				>
					<Row>
						<Col span={24}>
							<Form
								name='menu_item_creator'
								className='menu-creator'
								onFinish={onFinish}
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
								form={form2}
							>
								<Form.Item
									label={
										menuType === 'sub-menu'
											? 'Sub Menu Item Name'
											: 'Menu Item Name'
									}
									name='menu_item_name'
									style={{ marginBottom: '4px' }}
									rules={[
										{
											required: true,
											message: 'Please enter Menu name!',
										},
									]}
								>
									<Input
										placeholder='Enter Menu Item Name...'
										value=''
									/>
								</Form.Item>
								<Form.Item
									label={
										menuType === 'sub-menu'
											? 'Sub Menu Item Route'
											: 'Menu Item Route'
									}
									name='menu_item_route'
									style={{ marginBottom: '4px' }}
								>
									<Select
										options={options}
										placeholder='Select Menu Route'
										showSearch
									/>
								</Form.Item>
								<Form.Item
									label={menuType === 'sub-menu'
										? 'Sub Menu Item URL'
										: 'Menu Item URL'
									}
									name='menu_item_url'
									style={{ marginBottom: '4px' }}
								>
									<Input
										placeholder='Enter URL...'
									/>
								</Form.Item>
								<Form.Item
									label='Icon'
									name='menu_item_icon'
									style={{ marginBottom: '4px' }}
								>
									<Select
										placeholder='Select Icon'
										showSearch
									>
										{Icons.map(
											(item: string, index: number) => (
												<Select.Option
													key={index}
													value={item}>
													<div style={{
														display: 'flex',
														justifyContent:
														'center',
														alignItems: 'center',
														height: '100%',
													}}>
														<Icon type={item} />
													</div>
												</Select.Option>
											))}
									</Select>
								</Form.Item>
								<Form.Item style={{
									display: 'flex',
									justifyContent: 'end', marginTop: '20px' }}>
									<Button type='primary' htmlType='submit'>
										{
											menuType === 'edit-menu'
												? 'Update'
												: 'Submit'
										}
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Modal>
			</Row>
		</Spin>
	);
};

export default MenuCreator;

/* eslint-disable react/react-in-jsx-scope */
import {
	AlignLeftOutlined,
	BookOutlined,
	BorderOutlined,
	CalendarOutlined,
	CaretRightOutlined,
	DownOutlined,
	FileImageOutlined,
	FontColorsOutlined,
	FontSizeOutlined,
	MinusSquareOutlined,
	NumberOutlined,
	UploadOutlined,
	PieChartOutlined,
	SwapOutlined,
	SwitcherOutlined,
	TableOutlined,
	UnorderedListOutlined,
	VideoCameraOutlined,
	ClusterOutlined,
} from '@ant-design/icons';
import {v4 as uuid} from 'uuid';
export const widgets = [
	{
		id: uuid(),
		name: 'Form',
		type: 'form',
		icon: <BookOutlined />,
	},
	{
		id: uuid(),
		name: 'Label',
		type: 'label',
		icon: <FontSizeOutlined />,
	},
	{
		id: uuid(),
		name: 'Text',
		type: 'text',
		icon: <FontColorsOutlined />,
	},
	{
		id: uuid(),
		name: 'Text Area',
		type: 'textArea',
		icon: <AlignLeftOutlined />,
	},
	{
		id: uuid(),
		name: 'Number',
		type: 'number',
		icon: <NumberOutlined />,
	},
	{
		id: uuid(),
		name: 'Date',
		type: 'date',
		icon: <CalendarOutlined />,
	},
	{
		id: uuid(),
		name: 'Dropdown',
		type: 'dropdown',
		icon: <DownOutlined />,
	},
	{
		id: uuid(),
		name: 'Radio',
		type: 'radio',
		icon: <SwitcherOutlined />,
	},
	{
		id: uuid(),
		name: 'Checkbox',
		type: 'checkbox',
		icon: <UnorderedListOutlined />,
	},
	{
		id: uuid(),
		name: 'Toggle',
		type: 'toggle',
		icon: <SwapOutlined />,
	},
	{
		id: uuid(),
		name: 'Table',
		type: 'table',
		icon: <TableOutlined />,
	},
	{
		id: uuid(),
		name: 'Upload',
		type: 'upload',
		icon: <UploadOutlined />,
	},
	{
		id: uuid(),
		name: 'Button',
		type: 'button',
		icon: <CaretRightOutlined />,
	},
	{
		id: uuid(),
		name: 'Image',
		type: 'image',
		icon: <FileImageOutlined />,
	},
	{
		id: uuid(),
		name: 'Video',
		type: 'video_upload',
		icon: <VideoCameraOutlined />,
	},
	{
		id: uuid(),
		name: 'Flow',
		type: 'flow',
		icon: <ClusterOutlined />,
	},
	{
		id: uuid(),
		name: 'Collapse',
		type: 'collapse',
		icon: <MinusSquareOutlined />,
	},
	{
		id: uuid(),
		name: 'Progress',
		type: 'progress',
		icon: <PieChartOutlined />,
	},
	{
		id: uuid(),
		name: 'Modal',
		type: 'modal',
		icon: <BorderOutlined />,
	},
];

import {Col, DatePicker} from 'antd';
import React, { FC } from 'react';

type DateCompProps = {
	properties: any;
	dataSource: any;
}

const DateComp: FC<DateCompProps> = ({properties}) => {
	const {
		label,
		placeholder,
		date,
	} = properties.column_properties;
	const style = label.label_style;
	const {
		dateFormat,
		max_date,
		min_date,
		min_dateTime,
		max_dateTime,
		showTime,
	} = date;
	const range = (start: number, end: number): number[] => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}

		return result;
	};

	const disabledTime = (): any => ({
		// eslint-disable-next-line max-len
		disabledHours: () => range(0, 24).splice(parseInt(min_dateTime.split(':')[0], 10), parseInt(max_dateTime.split(':')[0], 10)),
		// eslint-disable-next-line max-len
		disabledMinutes: () => range(0, 60).splice(parseInt(min_dateTime.split(':')[1], 10), parseInt(max_dateTime.split(':')[1], 10)),
		// eslint-disable-next-line max-len
		disabledSeconds: () => range(0, 60).splice(parseInt(min_dateTime.split(':')[2], 10), parseInt(max_dateTime.split(':')[2], 10)),
	});
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}

			<DatePicker
				format={dateFormat}
				showTime={showTime}
				placeholder={placeholder}
				// eslint-disable-next-line max-len
				disabledDate={d => !d || d.isAfter(max_date) || d.isBefore(min_date)}
				disabledTime={disabledTime}
			/>
		</Col>
	);
};

export default DateComp;

import {Col, Radio} from 'antd';
import React, {FC} from 'react';

type RadioCompProps = {
	properties: any;
	dataSource: any;
}

const RadioComp: FC<RadioCompProps> = ({properties}) => {
	const {
		label,
		radio,
	} = properties.column_properties;
	const {defaultValue, optionType, buttonStyle} = radio;
	const style = label.label_style;
	const options = [
		{label: 'Apple', value: 'apple'},
		{label: 'Pear', value: 'pear'},
		{label: 'Orange', value: 'orange'},
	];
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Radio.Group
				options={options}
				value={defaultValue === 'none' ? undefined : defaultValue}
				optionType={optionType === '' ? undefined : optionType}
				buttonStyle={buttonStyle === '' ? undefined : buttonStyle}
			/>
		</Col>
	);
};

export default RadioComp;

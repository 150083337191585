
import React from 'react';
import './style.css';
import {Favorite} from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Success = () => (
	<div className='screen un'>
		<div className='border-top'>
		</div>
		<Favorite style={{marginTop: '30%', fontSize: '60px', color: 'red'}}/>
		<h3>SUCCESS!</h3>
		<p className='success-message'>Thanks for your response</p>
		<p className='success-message-2'>You are safe to close the window</p>
		<button id='btnClick'>CONTINUE</button>
	</div>
);

export default Success;

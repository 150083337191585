import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber, Popover } from 'antd';
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
	FC,
} from 'react';
import BorderRadiusImg from '../../../assets/img/border-radius.png';

type BorderRadiusProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const BorderRadius: FC<BorderRadiusProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {
		borderRadius = '0px 0px 0px 0px',
	} = activeElement.element[`${type}_style`];
	const colBorderRadius = borderRadius.split(' ');
	const [
		borderRadiusTop,
		setBorderRadiusTop,
	] = useState(colBorderRadius[0].replace('px', ''));
	const [
		borderRadiusRight,
		setBorderRadiusRight,
	] = useState(colBorderRadius[1].replace('px', ''));
	const [
		borderRadiusBottom,
		setBorderRadiusBottom,
	] = useState(colBorderRadius[2].replace('px', ''));
	const [
		borderRadiusLeft,
		setBorderRadiusLeft,
	] = useState(colBorderRadius[3].replace('px', ''));
	// eslint-disable-next-line max-len
	const handleborderRadius = (value: number | null, position: string): any => {
		if (value !== null && position) {
			// eslint-disable-next-line default-case
			switch (position) {
				case 'top': setBorderRadiusTop(value);
					break;
				case 'right': setBorderRadiusRight(value);
					break;
				case 'bottom': setBorderRadiusBottom(value);
					break;
				case 'left': setBorderRadiusLeft(value);
					break;
			}
		} else {
			return null;
		}
	};

	useEffect(() => {
		// eslint-disable-next-line max-len
		const borderRadius = `${borderRadiusTop}px ${borderRadiusRight}px ${borderRadiusBottom}px ${borderRadiusLeft}px`;
		setChangeType('borderRadius');
		setValue(borderRadius);
	}, [
		borderRadiusTop,
		borderRadiusRight,
		borderRadiusBottom,
		borderRadiusLeft,
	]);

	return (
		<table>
			<thead>
				<tr>
					<th colSpan={2}>
						<div style={{
							display: 'flex',
							justifyContent: 'start',
							alignItems: 'center',
							gap: '10px',
						}}>
							<p>Border Radius</p>
							<Popover
								content={<img
									src={BorderRadiusImg}
									alt='padding'
									width='300px' />}
								trigger='hover'
							>
								<ExclamationCircleOutlined />
							</Popover>
						</div></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Top</td>
					<td>
						<InputNumber min={0}
							value={borderRadiusTop}
							onChange={(e: number | null) =>
								handleborderRadius(e, 'top')}
						/>
					</td>
				</tr>
				<tr>
					<td>Right</td>
					<td>
						<InputNumber min={0}
							value={borderRadiusRight}
							onChange={(e: number | null) =>
								handleborderRadius(e, 'right')}
						/>
					</td>
				</tr>
				<tr>
					<td>Bottom</td>
					<td>
						<InputNumber min={0}
							value={borderRadiusBottom}
							onChange={(e: number | null) =>
								handleborderRadius(e, 'bottom')}
						/>
					</td>
				</tr>
				<tr>
					<td>Left</td>
					<td>
						<InputNumber min={0}
							value={borderRadiusLeft}
							onChange={(e: number | null) =>
								handleborderRadius(e, 'left')}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default BorderRadius;

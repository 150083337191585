import { Col } from 'antd';
import React, {FC} from 'react';

type LabelCompProps = {
	properties: any;
	dataSource: any;
}

const LabelComp: FC<LabelCompProps> = ({ properties }) => {
	const { label: {
		label_name = '',
		label_style,
	} } = properties.column_properties;
	return (
		<Col span={24}>
			<p
				style={{ ...label_style }}
			>
				{label_name || 'Title'}
			</p>
		</Col>
	);
};

export default LabelComp;

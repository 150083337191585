/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from 'axios';

const getAPIUrl = (): any => {
	const { protocol, hostname } = window.location;

	let { port }: any = window.location;
	if (hostname.includes('localhost')) {
		port = 8080;
	} else if (hostname.includes('v2')) {
		port = 8080;
		return `${protocol}//${hostname}:${port}/api/v2`;
	}

	return `${protocol}//${hostname}:${port}/api/v1`;
};

const URL = getAPIUrl();
const token = localStorage.getItem('token');
export const currentUser = localStorage.getItem('user');
const headers = {
	'Content-Type': 'application/json',

	Authorization: `Bearer ${token}`,
};

export const fetchData = async (pathName: any) => {
	const response = await fetch(`${URL}${pathName}`, {
		method: 'GET',
		mode: 'cors',
		headers,
	});
	const res = await response.json();
	return res;
};

export const getAPI = async (pathName: any, params = {}) => {
	const response = await axios.get(`${URL}${pathName}`, {
		headers,
		params,
	});
	return response;
};

export const getAllActions = async (pathName: any) => {
	const response = await fetch(`${URL}${pathName}`, {
		method: 'GET',
		mode: 'cors',
		headers,
	});
	const res = await response.json();
	return res;
};

export const getActivityView = async (pathName: any) => {
	const response = await fetch(`${URL}${pathName}`, {
		method: 'GET',
		mode: 'cors',
		headers,
	});
	const res = await response.json();
	return res;
};

export const getActionResponse = async (pathName: any) => {
	const response = await fetch(`${URL}${pathName}`, {
		method: 'GET',
		mode: 'cors',
		headers,
	});
	const res = await response.json();
	return res;
};

export const deleteAPI = async (pathName: string, data: any) => {
	const response = await axios.delete(`${URL}${pathName}`, {
		headers,
		data,
	});
	return response;
};

export const deleteActivity = async (pathName: string, data: any) => {
	const response = await axios.delete(`${URL}${pathName}`, {
		headers,
		data,
	});
	return response;
};

export const updateAPI = async (pathName: string, data: any) => {
	const response = await axios.post(`${URL}${pathName}`, data, {
		headers,
	});
	return response;
};

export const updateCandidate = async (pathName: string, data: any, uid: any) => {
	const response = await axios.patch(`${URL}${pathName}/${uid}`, data, {
		headers,
	});
	return response;
};

export const putAPI = async (pathName: string, data: any) => {
	const response = await axios.put(`${URL}${pathName}`, data, {
		headers,
	});
	return response;
};

export const uploadFile = async (file: any) => {
	const { name = '', type = '' } = file;
	return updateAPI('/s3/getSignedUrl', {
		name: name.replace(/ /g, '_'),
		type,
	}).then(async res => {
		const { data } = res.data;
		const options = {
			headers: { 'Content-Type': file.type },
			reportProgress: true,
		};
		await axios
			.put(data.info.signedRequest, file, { ...options })
			.catch(err => err);
		return data.info.url;
	});
};

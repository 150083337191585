import {
	CheckOutlined,
	DeleteOutlined,
	MenuOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Col, message, Popover, Row, Tooltip} from 'antd';
import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import EditorRow from './editor-row';
import './style.css';
import { createColum, createRow } from '../properties-obj/properties-obj';
import {v4 as uuid} from 'uuid';
import {cloneDeep, isEmpty} from 'lodash';
import { widget_type } from '../properties-obj/widget-properties-obj';

type EditorSectionProps = {
  value: any;
  setActiveElement: Dispatch<SetStateAction<{}>>;
  setPropertiesType: Dispatch<SetStateAction<string>>;
  setSectionValues: Dispatch<SetStateAction<any>>;
  sectionValues: any;
};

const EditorSection: FC<EditorSectionProps> = (
	{
		value,
		setActiveElement,
		setPropertiesType,
		setSectionValues,
		sectionValues,
	},
) => {
	const [showIcon, setShowIcon] = useState(false);
	const getActiveRowColumn = (element_uid: string, type: string): void => {
		const {section_uid, section_properties: {data_source}} = value;
		const activeRow = (row: any): void => {
			if (type === 'row') {
				row.forEach((row: {column: any, row_uid: string}) => {
					if (row.row_uid === element_uid) {
						setActiveElement({
							section_uid,
							element: row,
							element_type: 'row',
							data_source,
						});
						setPropertiesType('row');
					} else if (row.column && !isEmpty(row.column)) {
						row.column.forEach((col: any) => {
							if (widget_type.includes(col.widget_type)) {
								// eslint-disable-next-line max-len
								if (col.column_properties[col.widget_type].row) {
									// eslint-disable-next-line max-len
									activeRow(col.column_properties[col.widget_type].row);
								}
							} else if (col.row && !isEmpty(col.row)) {
								activeRow(col.row);
							}
						});
					}
				});
			}
		};

		const activeColumn = (rows: any): void => {
			rows.forEach((row: any) => {
				row.column.forEach((col: any) => {
					if (col.column_uid === element_uid) {
						setActiveElement(
							{
								section_uid,
								element: col,
								element_type: 'column',
								data_source,
							},
						);
						setPropertiesType('column');
					} else if (widget_type.includes(col.widget_type)) {
						if (col.column_properties[col.widget_type].row) {
							// eslint-disable-next-line max-len
							activeColumn(col.column_properties[col.widget_type].row);
						}
					} else if (col.row && !isEmpty(col.row)) {
						activeColumn(col.row);
					}
				});
			});
		};

		if (type === 'row') {
			activeRow(value.row);
		} else if (type === 'column') {
			activeColumn(value.row);
		}
	};

	const addRowColumn = (values: any): void => {
		let form_uid = '';
		const {element_uid, type} = values;
		const addRow = (rows: any): void => {
			rows.forEach((row: any) => {
				row.column.forEach((col: any) => {
					if (col.column_uid === element_uid) {
						col.widget_type = '';
						if (col.row) {
							col.row.push(createRow());
						} else {
							col.row = [];
							col.row.push(createRow());
						}

						message.success('Row Added Successfully');
					} else if (widget_type.includes(col.widget_type)) {
						if (col.column_properties[col.widget_type].row) {
							addRow(col.column_properties[col.widget_type].row);
						}
					} else if (col.row && !isEmpty(col.row)) {
						addRow(col.row);
					}
				});
			});
		};

		const addColumn = (rows: any): void => {
			rows.forEach((row: any) => {
				if (row.row_uid === element_uid) {
					row.column.push(createColum(form_uid));
					message.success('Colum Added Successfully');
				} else if (row.column && !isEmpty(row.column)) {
					row.column.forEach((col: any) => {
						form_uid = col.form_uid;
						if (widget_type.includes(col.widget_type)) {
							if (col.column_properties[col.widget_type].row) {
								// eslint-disable-next-line max-len
								addColumn(col.column_properties[col.widget_type].row);
							}
						} else if (col.row && !isEmpty(col.row)) {
							addColumn(col.row);
						}
					});
				}
			});
		};

		const copyRow = (rows: any): void => {
			let form_uid = '';
			rows.forEach((row: any, index: number) => {
				if (row.row_uid === element_uid) {
					const recursion = (columns: any): void => {
						columns.forEach((col: any) => {
							col.column_uid = uuid();
							if (col.form_wrapper && col.form_uid) {
								form_uid = uuid();
								col.form_uid = form_uid;
							} else if (col.form_uid && form_uid) {
								col.form_uid = form_uid;
							}

							if (widget_type.includes(col.widget_type)) {
								// eslint-disable-next-line max-len
								if (col.column_properties[col.widget_type].row) {
									// eslint-disable-next-line max-len
									recursion(col.column_properties[col.widget_type].row);
								}
							} else if (col.row && !isEmpty(col.row)) {
								col.row.forEach((col_row: any) => {
									col_row.row_uid = uuid();
									// eslint-disable-next-line max-len
									if (col_row.column && !isEmpty(col_row.column)) {
										recursion(col_row.column);
									}
								});
							}
						});
					};

					const copy = cloneDeep(row);
					copy.row_uid = uuid();
					if (copy.column) {
						recursion(copy.column);
					}

					rows.splice(index + 1, 0, copy);
					message.success('Row Copied Successfully');
				} else if (row.column && !isEmpty(row.column)) {
					row.column.forEach((col: any) => {
						if (widget_type.includes(col.widget_type)) {
							if (col.column_properties[col.widget_type].row) {
								// eslint-disable-next-line max-len
								copyRow(col.column_properties[col.widget_type].row);
							}
						} else if (col.row && !isEmpty(col.row)) {
							copyRow(col.row);
						}
					});
				}
			});
		};

		const copyColumn = (rows: any): void => {
			let form_uid = '';
			rows.forEach((row: any) => {
				row.column.forEach((col: any, index: number) => {
					if (col.column_uid === element_uid) {
						const recursion = (col_rows: any): void => {
							col_rows.forEach((col_row: any) => {
								col_row.row_uid = uuid();
								if (col_row.column) {
									col_row.column.forEach((row_col: any) => {
										row_col.column_uid = uuid();
										// eslint-disable-next-line max-len
										if (row_col.form_wrapper && row_col.form_uid) {
											form_uid = uuid();
											row_col.form_uid = form_uid;
										// eslint-disable-next-line max-len
										} else if (row_col.form_uid && form_uid) {
											row_col.form_uid = form_uid;
										}

										// eslint-disable-next-line max-len
										if (row_col.row && !isEmpty(row_col.row)) {
											recursion(row_col.row);
										}
									});
								}
							});
						};

						const copy = cloneDeep(col);
						copy.column_uid = uuid();
						if (copy.form_wrapper && copy.form_uid) {
							form_uid = uuid();
							copy.form_uid = form_uid;
						}

						if (widget_type.includes(copy.widget_type)) {
							if (copy.column_properties[copy.widget_type].row) {
								// eslint-disable-next-line max-len
								recursion(copy.column_properties[col.widget_type].row);
							}
						} else if (copy.row && !isEmpty(copy.row)) {
							recursion(copy.row);
						}

						row.column.splice(index + 1, 0, copy);
						message.success('Column Copied Successfully');
					} else if (widget_type.includes(col.widget_type)) {
						if (col.column_properties[col.widget_type].row) {
							// eslint-disable-next-line max-len
							copyColumn(col.column_properties[col.widget_type].row);
						}
					} else if (col.row && !isEmpty(col.row)) {
						copyColumn(col.row);
					}
				});
			});
		};

		setSectionValues(
			sectionValues.map((sec: any) => {
				if (sec.section_uid === value.section_uid) {
					if (type === 'add-column') {
						addColumn(sec.row);
					} else if (type === 'add-row') {
						addRow(sec.row);
					} else if (type === 'copy-row') {
						copyRow(sec.row);
					} else if (type === 'copy-column') {
						copyColumn(sec.row);
					}
				}

				return sec;
			}),
		);
	};

	const deleteRow = (row_uid: string): void => {
		const rowDel = (rows: any): void =>
			rows.forEach((row: any, index: number) => {
				if (row.row_uid === row_uid) {
					rows.splice(index, 1);
				} else {
					row.column.forEach((col: any) => {
						if (widget_type.includes(col.widget_type)) {
							if (col.column_properties[col.widget_type].row) {
								// eslint-disable-next-line max-len
								rowDel(col.column_properties[col.widget_type].row);
							}
						} else if (col.row && !isEmpty(col.row)) {
							rowDel(col.row);
						}
					});
				}
			});

		setSectionValues(
			sectionValues.map((sec: any) => {
				if (sec.section_uid === value.section_uid) {
					rowDel(sec.row);
				}

				return sec;
			}),
		);
	};

	const deleteColumn = (column_uid: string): void => {
		const colDel = (rows: any): void => rows.forEach((row: any) => {
			row.column.forEach((col: any, index: any) => {
				if (col.column_uid === column_uid) {
					row.column.splice(index, 1);
				} else if (widget_type.includes(col.widget_type)) {
					if (col.column_properties[col.widget_type].row) {
						colDel(col.column_properties[col.widget_type].row);
					}
				} else if (col.row && !isEmpty(col.row)) {
					colDel(col.row);
				}
			});
		});

		setSectionValues(
			sectionValues.map((sec: any) => {
				if (sec.section_uid === value.section_uid) {
					colDel(sec.row);
				}

				return sec;
			}),
		);
	};

	const deleteSection = (section_uid: string): void => {
		const copySectionValues = sectionValues;
		copySectionValues.forEach((sec: any, index: number) => {
			if (sec.section_uid === section_uid) {
				copySectionValues.splice(index, 1);
			}
		});
		setSectionValues([...copySectionValues]);
	};

	const {data_source} = value.section_properties;
	const {section_style: {
		margin = '',
		padding = '',
		borderRadius = '',
		backgroundColor = '',
		width = '',
		borderWidth = '',
		borderColor = '',
		borderStyle = '',
		height = null,
	}} = value;
	const style = {
		margin,
		padding,
		borderRadius,
		backgroundColor,
		borderWidth,
		borderColor,
		borderStyle,
		height,
	};
	const {title} = value.section_properties;
	const addRow = (): void => {
		setSectionValues(sectionValues.map((sec: any) => {
			if (sec.section_uid === value.section_uid) {
				sec.row.push(createRow());
				message.success('Row Added');
			}

			return sec;
		}));
	};

	return (
		<Col
			span={width || 24}
			className={showIcon ? 'editor-section show' : 'editor-section'}
			key={value.section_uid}
			id={value.section_uid}
			onMouseOver = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(true);
				}
			}
			onMouseOut = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(false);
				}
			}
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				setActiveElement({ element: value, element_type: 'section'});
				setPropertiesType('section');
			}}
		>
			<div
				className='icon row'
				style={{
					display: showIcon ? 'block' : 'none',
				}}
			>
				<Popover placement='top' trigger='hover' content={(
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						gap: '15px', cursor: 'pointer',
					}}>
						<span>
							<Tooltip title='Add Row' placement='topLeft'>
								<PlusOutlined onClick={addRow} />
							</Tooltip>
						</span>
						<span>
							<Tooltip title='Delete Row' placement='right'>
								<DeleteOutlined onClick={() => {
									deleteSection(value.section_uid);
								}} />
							</Tooltip>
						</span>
						<span>
							<Tooltip title='Select Section' placement='topLeft'>
								<CheckOutlined onClick={() => {
									// eslint-disable-next-line max-len
									setActiveElement({ element: value, element_type: 'section'});
									setPropertiesType('section');
								}} />
							</Tooltip>
						</span>
					</div>
				)}>
					<MenuOutlined />
				</Popover>
			</div>
			<Col span={24} style={{...style, width: '100%'}}>
				{
					title.show_title && (
						<Row key={value.section_uid}>
							<Col span={24}>
								<div style={{
									width: '100%',
									display: 'flex',
									gap: '10px',
									padding: '5px 10px',
								}}>
									<p
										style={{...title.title_style,
											width: '100%',
										}}
									>
										{title.section_title || 'Section Title'}
									</p>
								</div>
							</Col>
						</Row>
					)
				}
				{(value.row && !isEmpty(value.row))
				&& value.row.map((row: any, indexRow: number) => (
					<EditorRow
						key={indexRow}
						row={row}
						getActiveRowColumn={getActiveRowColumn}
						indexRow={indexRow}
						dataSource={data_source}
						deleteColumn={deleteColumn}
						addRowColumn={addRowColumn}
						deleteRow={deleteRow}
						setPropertiesType={setPropertiesType}
					/>
				))}
			</Col>
		</Col>
	);
};

export default EditorSection;

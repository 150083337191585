import {Col, Switch} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';
import LabelAlign from './label-align';
import LabelColorPicker from './label-color-picker';
import LabelFontSize from './label-font-size';
import LabelFontWeight from './label-font-weight';
import LabelName from './label-name';
import LabelPadding from './label-padding';
import LabelFonstStyle from './label-font-style';
import { widget_type } from '../../../properties-obj/widget-properties-obj';
import LabelBackgroundColor from './label-background-color';
import LabelBorderRadius from './label-border-radius';
// eslint-disable-next-line max-len
import { title_style, common_styles } from '../../../properties-obj/properties-obj';
import LabelFonstTransform from './label-transform';

type LabelStyleProps = {
	sectionValues: any;
	setSectionValues: Dispatch<SetStateAction<any>>;
    activeElement: any;
}

const LabelStyle: FC<LabelStyleProps> = ({
	activeElement,
	sectionValues,
	setSectionValues,
}) => {
	const {label: {
		show_label = false,
	}} = activeElement.element.column_properties;
	const toggeleLabelProperties = (checked: boolean): void => {
		setChangeType('show_label');
		setValue(checked);
	};

	const [changeType, setChangeType] = useState('');
	const [value, setValue] = useState<any>(null);

	useEffect(() => {
		if (changeType) {
			setSectionValues(
				sectionValues.map((sec: {section_uid: string, row: []}) => {
					const rowRecursion = (row: any): void => {
						row.forEach((row: any) => {
							row.column.forEach((col: any) => {
								const {label} = col.column_properties;
								// eslint-disable-next-line max-len
								if (col.column_uid === activeElement.element.column_uid) {
									// eslint-disable-next-line max-len
									if (['show_label', 'label_name'].includes(changeType)) {
										if (changeType === 'show_label') {
											label[changeType] = value;
											// eslint-disable-next-line max-len
											label.label_style = {...label.label_style, ...title_style, ...common_styles};
										} else {
											label[changeType] = value;
										}
									} else {
										label.label_style[changeType] = value;
									}
								// eslint-disable-next-line max-len
								} else if (widget_type.includes(col.widget_type)) {
								// eslint-disable-next-line max-len
									if (col.column_properties[col.widget_type].row) {
									// eslint-disable-next-line max-len
										rowRecursion(col.column_properties[col.widget_type].row);
									}
								} else if (col.row) {
									rowRecursion(col.row);
								}
							});
						});
					};

					if (sec.section_uid === activeElement.section_uid) {
						rowRecursion(sec.row);
					}

					return sec;
				}),
			);
		}
	}, [changeType, value]);

	return (
		<Col span={24}>
			{
				activeElement.element.widget_type !== 'label' && (
					<table>
						<tbody>
							<tr>
								<td>Show Title</td>
								<td>
									<Switch
										checked={show_label}
										onChange={toggeleLabelProperties}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				)
			}
			{
				// eslint-disable-next-line max-len
				(((activeElement.element.widget_type === 'label') && !show_label) || ((activeElement.element.widget_type !== 'label') && show_label)) && (
					<Col span={24}>
						<table>
							<thead>
								<tr>
									<th colSpan={2}>Title</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Name</td>
									<td>
										<LabelName
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Color</td>
									<td>
										<LabelColorPicker
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Background Color</td>
									<td>
										<LabelBackgroundColor
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td colSpan={2} style={{padding: '0'}}>
										<LabelBorderRadius
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Align</td>
									<td>
										<LabelAlign
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Font Size</td>
									<td>
										<LabelFontSize
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Font Style</td>
									<td>
										<LabelFonstStyle
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Font Weight</td>
									<td>
										<LabelFontWeight
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
								<tr>
									<td>Text Transform</td>
									<td>
										<LabelFonstTransform
											// eslint-disable-next-line max-len
											key={activeElement.element.column_uid}
											activeElement={activeElement}
											setChangeType={setChangeType}
											setValue={setValue}
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<LabelPadding
							key={activeElement.element.column_uid}
							activeElement={activeElement}
							setChangeType={setChangeType}
							setValue={setValue}
						/>
					</Col>
				)
			}
		</Col>
	);
};

export default LabelStyle;

/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Dispatch, SetStateAction } from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { fetchData, updateCandidate, uploadFile, currentUser, getActivityView, getActionResponse, putAPI, getAllActions, updateAPI, getAPI } from '../api/api';
import { camelCase, capitalCase, snakeCase } from 'text-case';
import { Comment } from '@mui/icons-material';
import {
	FormGroup, FormLabel, Grid, TextareaAutosize, IconButton, Button, FormControl, RadioGroup, Radio,
	FormControlLabel, Card, CardContent, Box, Step, Stepper, StepLabel, Backdrop, CircularProgress, StepContent, Avatar, CardActions, Select, MenuItem,
	SelectChangeEvent,
	Popover,
	Autocomplete,
	TextField,
} from '@mui/material';
import './profile-tracker.css';
import TextFieldComp from '../shared/components/textfield/textfield';
import { Alerts } from '../shared/components/alerts';
import { getPath, safeJSONParse } from '../shared/formatting/index';
import { filter, get } from 'lodash';
import { Col, Row, message } from 'antd';
import { getPermission } from '../shared/permissions';
import { lookupActionResponse } from '../api/lookup-response';
import { DetailsForm } from '../forms/details-form';
import BackgroundColor from '../components/settings/properties/common/background-color';
import { columnDef } from '../shared/defined-columns/profile-tracker';
const permissions = getPermission('UsersList');
const { is_admin, is_update } = permissions;

type FormCreatorProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>
}

export const ProfileDisplay = ({ setFullScreen }: FormCreatorProps) => {
	const params = useParams();
	const location = useLocation();
	const { pathname } = location;
	const navigate = useNavigate();
	const [data, setData]: any = useState({});
	const [value, setValue] = useState('');
	const [form, setForm] = useState([]);
	const [formName, setFormName] = useState('');
	const [users, setUsers] = useState([]);
	const [formData, setFormData] = useState<any>();
	const [requiredFields, setRequiredFields] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [formError, setFormError] = useState(false);
	const [roundsLoading, setRoundsLoading] = useState(false);
	const [activity, setActivity] = useState<any>([]);
	const [currentActivity, setCurrentActivity] = useState<any>({});
	const [resource, setResource] = useState('');
	const [actionResp, setActionResp] = useState([]);
	const [currentActionId, setCurrentActionId] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [actionRounds, setActionRounds] = useState([]);
	const [extraNotes, setExtraNotes] = useState('');
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [showProgress, setShowProgress] = useState(0);
	const [manualAction, setManualAction] = useState(0);
	const [isButton, setIsButton] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const path = getPath(pathname);
	const userLoginId = get(localStorage, 'userLoginId', '');
	const [responseFormRequiredFields, setResponseFormRequiredFields] = useState<any>([]);
	const [responseForm, setResponseForm] = useState([]);
	const [responseFormData, setResponseFormData] = useState<any>();
	const [loaded, setLoaded] = useState(false);
	const [viewResponse, setViewResponse] = useState(false);
	const [respData, setRespData]: any = useState({});
	const [finalObject, setFinalObject] = useState({});
	const [status, setStatus] = useState('');

	const fetchActionResp = async (id: string) => {
		try {
			const response = await getActionResponse(`/${path}/getAction/${id}`);
			const result = response.data;
			setActionResp(result);
			result.forEach((item: any) => {
				if (item.responseFormId) {
					getFormDetails(item.responseFormId);
				}
			});
		} catch (e: any) {
			console.log('Error on action response', e.message);
		}
	};

	const getActions = () => {
		getAllActions(`/${path}/get-actions`).then((response: any) => {
			setActionRounds(response.data);
			setLoading(false);
		}).catch(() => {
			setError(true);
			setLoading(false);
		});
	};

	const fetch = () => (
		fetchData(`/${path}/${params.uid}`)
			.then((res: any) => {
				const finalObj = { duration: 0, updatedAt: '' };
				for (let i = 0; i < res.data.length; i++) {
					Object.assign(finalObj, res.data[i]);
				}

				setFinalObject(finalObj);

				setErrorMessage('');
				setError(false);
				setSuccess(false);
				setData(finalObj);
			})
			.catch(err => {
				setError(true);
			}));

	const currentActionRoleId = () => {
		const allActions = actionRounds;
		let roleId = '';
		if (allActions && currentActivity) {
			const filterData = allActions.find((item: any) => currentActivity.actionId === item.actionId);
			roleId = get(filterData, 'roleId') ?? '';
		}

		return roleId;
	};

	const fetchUsers = () => {
		const id = currentActionRoleId();
		fetchData(`/${path}/get-user-roles?roleid=${id}`).then((res: any) => {
			setUsers(res.data);
		}).catch(() => {
			message.error('Could not load users');
		});
	};

	const fetchActivity = () => (
		getActivityView(`/${path}/getActivity/${params.uid}`)
			.then((res: any) => {
				setActivity(res.data);
				res.data.forEach((item: any) => {
					if (item.responseId === null && item.sourceType === null) {
						fetchActionResp(item.actionId);
						setCurrentActivity(item);
						setCurrentActionId(item.actionId);
					} else {
						setIsButton(false);
					}
				});
			})
			.catch(err => {
				setError(true);
			}));

	const setFields = (
		formData: any, requireCheck = false, userTypedValue: any = {}) => {
		const required: any = [];
		const parsedFields = formData.map((field: any) => {
			const item = { ...field };
			if (item.fieldProps.required) {
				required.push(camelCase(item.fieldName));
				if (requireCheck
					&& !userTypedValue[camelCase(item.fieldName)]) {
					item.fieldProps.className = 'required error';
					item.fieldProps.helperText
						= 'Please Enter ' + item.fieldName;
				} else {
					delete item.fieldProps.helperText;
					item.fieldProps.className = 'required';
				}
			}

			return {
				...item,
			};
		});
		setRequiredFields(required);
		setForm(parsedFields);
	};

	const setResponseFields = (
		responseFormData: any, requireCheck = false, userTypedValue: any = {}) => {
		const required: any = [];
		const parsedFields = responseFormData.map((field: any) => {
			const item = { ...field };
			if (item.fieldProps.required) {
				required.push(camelCase(item.fieldName));
				if (requireCheck
					&& !userTypedValue[camelCase(item.fieldName)]) {
					item.fieldProps.className = 'required error';
					item.fieldProps.helperText
						= 'Please Enter ' + item.fieldName;
				} else {
					delete item.fieldProps.helperText;
					item.fieldProps.className = 'required';
				}
			}

			return {
				...item,
			};
		});
		setResponseFormRequiredFields(required);
		setResponseForm(parsedFields);
	};

	const getData = async () => {
		try {
			setLoading(true);
			const element = await fetchData(`/forms/${path}`);
			setFormName(element.results.name);
			setFormData(element.results);
			setFields(safeJSONParse(element.results.fieldsJson));
			setShowProgress(get(element, 'results.showProgress', 0));
			setManualAction(get(element, 'results.manualAction', 0));
			setFormError(false);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setError(true);
		}
	};

	const getFormDetails = async (id: string) => {
		try {
			setLoading(true);
			const response = await fetchData(`/forms/form-details/${id}`);

			setResponseFormData(response.results);
			setResponseFields(safeJSONParse(response.results.fieldsJson));
			setFormError(false);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		fetch();
		getData();
		fetchActivity();
		getActions();
		setFullScreen(false);
	}, []);

	useEffect(() => {
		getActivityView(`/${path}/${params.uid}`).then(res => {
			setStatus(res.data[0].status);
		});
	}, []);

	useEffect(() => {
		fetchUsers();
		if (get(currentActivity, 'responseId') === null) {
			setIsButton(true);
		}
	}, [currentActivity]);

	useEffect(() => {
		const doc: any = document;
		document.addEventListener('wheel', () => {
			if (get(document, 'activeElement.type') === 'number') {
				doc.activeElement?.blur();
			}
		});
		return () => {
			document.removeEventListener('wheel', () => {
				if (get(document, 'activeElement.type') === 'number') {
					doc.activeElement?.blur();
				}
			});
		};
	}, []);

	const handleOnChange = (e: any): void => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.currentTarget.value;
		const newFormData = { ...data };
		newFormData[fieldName] = fieldValue;
		setData(newFormData);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetVal = event.target.value;
		const actionFilter = actionResp.find((item: any) => item.responseId === targetVal);
		setLoaded(true);
		if (get(actionFilter, 'responseFormId')) {
			responseForm.forEach((item: any) => {
				item.required = true;
				item.fieldProps.required = true;
			});
			setResponseFields(responseForm);

			const args = {
				form: responseForm,
				setform: setResponseForm,
				loader: setLoaded,
				formDetail: data,
			};
			lookupActionResponse(args);
			setViewResponse(true);
		} else {
			responseForm.forEach((item: any) => {
				item.required = true;
				item.fieldProps.required = true;
			});
			setLoaded(false);
			setViewResponse(false);
		}

		setValue((event.target as HTMLInputElement).value);
	};

	const handleSubmit = async (): Promise<void> => {
		setLoading(true);
		const requireCheck
			= requiredFields.filter((item: any) => data[item] && item);
		// eslint-disable-next-line no-negated-condition
		if (requireCheck.length !== requiredFields.length) {
			setLoading(false);
			setFormError(true);
			setFields(form, true, data);
		} else {
			setFormError(false);
			setFields(form, true, data);
			const files = Object.keys(data).reduce((acc: any, key) => {
				if (data[key] instanceof File) {
					acc[key] = data[key];
				}

				return acc;
			}, {});

			if (Object.keys(files).length) {
				const s3Url = await uploadFile(Object.values(files)[0]);
				data[Object.keys(files)[0]] = s3Url;
			}

			delete data.comments;
			const renamedPayload = Object.keys(data).reduce(
				(acc, key) => ({
					...acc,
					...{ [snakeCase(key)]: data[key] },
				}),
				{},
			);
			const params = {
				...renamedPayload,
				modified_by: currentUser,
				created_by: currentUser,
			};
			const uid = get(params, 'uid');

			updateCandidate(`/${path}/updateAny`, params, uid).then(res => {
				if (res.data.success) {
					fetch();
					setSuccess(true);
					setLoading(false);
					setValue('');
					setViewResponse(false);
				} else {
					setErrorMessage(res.data.message);
					setError(true);
					setLoading(false);
					setValue('');
					setViewResponse(false);
				}
			}).catch((e: any) => {
				setError(true);
				setLoading(false);
				setValue('');
			});
		}
	};

	const actionUpdate = async (id: String) => {
		// Here it could be
		const payload = {
			uid: params.uid,
			created_by: currentUser,
			modified_by: currentUser,
			action_id: id,
			isActionUpdate: 'true',
			last_start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
			last_end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
		};
		await putAPI(`/${path}/processWorkflow`, payload).then((res: any) => {
			if (res.data.success) {
				callBackApis();
				setSuccess(true);
				setLoading(false);
			} else {
				setErrorMessage(res.data.message);
				setError(true);
				setLoading(false);
			}
		}).catch((e: any) => {
			setError(true);
			setLoading(false);
		});
	};

	const callBackApis = () => {
		setRoundsLoading(false);
		fetch();
		fetchActivity();
		setValue('');
		setData({ ...data, comments: '' });
		setSuccess(true);
		setExtraNotes('');
		handleClose();
		setViewResponse(false);
	};

	const handleAddComment = (e: any) => {
		setExtraNotes(e.target.value);
	};

	const submitResponse = async (responseId?: string) => {
		// Here we need
		try {
			setRoundsLoading(true);
			const requireCheck
				= responseFormRequiredFields.filter((item: any) => respData[item] && item);
			// eslint-disable-next-line no-negated-condition
			if (requireCheck.length !== responseFormRequiredFields.length) {
				setLoading(false);
				setResponseFields(responseForm, true, respData);
				setRoundsLoading(false);
			} else {
				setResponseFields(responseForm, false, respData);

				const currentDate = new Date();
				if (currentActivity.assignedUserUid && (value || responseId)) {
					const payload = {
						activityId: currentActivity.activityId,
						actionId: currentActionId,
						uid: params.uid,
						response: value || responseId,
						comment: data.comments,
						userId: currentActivity.userId,
						notificationTemplateId: `${path}`,
						userName: currentUser,
						locationDetails: get(respData, 'location', ''),
						durations: get(respData, 'duration', ''),
						last_start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
						last_end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
						totalHours: get(respData, 'totalHours', ''),
					};
					// Function to check if a button should be disabled

					await putAPI(`/${path}/processWorkflow`, payload);
					callBackApis();
				} else if (!currentActivity.assignedUserUid && resource) {
					const payload = {
						activityId: currentActivity.activityId,
						uid: params.uid,
						assignedUserUid: resource,
						userId: resource,
						notificationTemplateId: `${path}`,
						userName: currentUser,
					};

					await putAPI(`/${path}/processWorkflow`, payload);
					callBackApis();
				} else {
					setError(true);
					callBackApis();
				}
			}
		} catch (err: any) {
			setError(true);
			callBackApis();
		}
	};

	const handleResourceChange = (event: SelectChangeEvent) => {
		setResource(event.target.value as string);
	};

	const inputResourceSelect = (activity: any) => {
		const roundName = typeof activity.name === 'string' ? safeJSONParse(activity.name) : activity.name;
		return (
			<Grid container >
				<Grid item xs={2} md={1}>
					<Avatar sx={{ bgcolor: '#1976d2' }}>{activity.fullName?.substring(0, 1)}</Avatar>
				</Grid>
				<Grid item xs={10} md={11} style={{ marginTop: '10px' }}>
					{`Select Resource for ${roundName?.value}`}
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={6} padding='10px'>
					<FormControl fullWidth>
						<Select
							labelId='demo-simple-select-label'
							className='dropDown'
							value={resource}
							onChange={handleResourceChange}
						>
							{
								users.map((item: any) => (
									<MenuItem key={item.uid} value={item.uid}>{item.fullName}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2} direction='row' display='flex' alignItems='center'>
					<Button variant='contained' disabled={roundsLoading} onClick={() => submitResponse()} style={{ height: '25px' }}>Submit</Button>
				</Grid>
			</Grid>
		);
	};

	const inputContent = (activity: any) => {
		const roundName = typeof activity.name === 'string' ? safeJSONParse(activity.name) : activity.name;
		const hasRadioButton: any = [];
		const hasButton = actionResp.filter((item: any) => {
			if (JSON.parse(item.responseResult)?.button) {
				return item;
			}

			hasRadioButton.push(item);
			return null;
		});
		return (
			<Grid container>
				{actionResp.length > 0
					&& <Grid container>
						<Grid item xs={2} md={1}>
							<Avatar sx={{ bgcolor: '#1976d2' }}>{activity.fullName?.substring(0, 1)}</Avatar>
						</Grid>
						<Grid item xs={10} md={11} style={{ marginTop: '10px' }}>
							{roundName?.value}&nbsp;{roundName?.pp}
							&nbsp;<b>{activity.fullName}</b>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
							<FormControl>
								{(is_admin || is_update || path === 'asset-tracker') ? <RadioGroup
									aria-labelledby='demo-controlled-radio-buttons-group'
									name='controlled-radio-buttons-group'
									value={value}
									onChange={handleChange}
									row
								>
									{hasRadioButton.map((item: any, index: number) => (
										<FormControlLabel key={index} value={item.responseId} control={<Radio size='small' />} label={item.responseName} />
									))}
								</RadioGroup> : ''}
							</FormControl>
							<div style={{ display: 'flex', gap: '17px', marginBottom: '10px' }}>{hasButton.map((item: any, index) =>
								<Button variant='contained' key={index} style={{ height: '25px' }}
									onClick={() => submitResponse(item.responseId)}>{item.responseName}</Button>)}</div>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={11}>
							<FormControl style={{ display: 'block', marginBottom: '10px' }}>
								<div>{
									(responseForm || get(responseForm, 'value', []).length) && viewResponse
										? responseForm.map((item: any, i: number) => (
											<>
												{(item.fieldProps.type === 'autoComplete')
													? (<>
														<FormGroup>
															<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
															<Autocomplete
																freeSolo
																options={item.value || []}
																renderInput={params => <TextField
																	{...params}
																	name={camelCase(item.fieldName)}
																	InputProps={{
																		...params.InputProps,
																		className: 'dropDown',
																		endAdornment: (
																			<React.Fragment>
																				{loaded ? <CircularProgress color='inherit' size={20} /> : null}
																				{params.InputProps.endAdornment}
																			</React.Fragment>
																		),
																	}}
																/>}
																onChange={(event: any, newValue: string | null) => {
																	setRespData({ ...respData, location: newValue });
																}}
																value={get(respData, 'location', '')}
															/>
															{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
														</FormGroup>
													</>
													)
													: null}
												{(item.fieldProps.type === 'number')
													? (
														<Grid item xs={12}>
															<FormGroup>
																<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
																<TextFieldComp {...item.fieldProps} className='form-group-input' name={camelCase(item.fieldName)} placeholder={`Enter ${item.fieldName}`}
																	onChange={e => {
																		const { name, value } = e.target;
																		setRespData({ ...respData, [name]: value });
																	}}
																/>
															</FormGroup>
														</Grid>
													) : null}
											</>
										)) : ''}</div>
							</FormControl>
							<TextareaAutosize
								minRows={2}
								placeholder='  Enter your comments'
								name='comments'
								style={{ width: '100%', border: '1px solid #e0e0e0', height: '88px', padding: '10px' }}
								onChange={handleOnChange}
								required
								className='comments'
								value={data.comments}
							/>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={11} sm={11}>
						</Grid>
						<Grid item xs={1}></Grid>
						<Grid item xs={3} direction='row' justifyContent='flex-end' style={{ padding: '5px 0 5px 0' }}>
							{hasRadioButton.length ? <Button variant='contained' disabled={roundsLoading} onClick={() => submitResponse()} style={{ height: '25px' }}> Submit </Button>
								: ''}
						</Grid>
					</Grid>
				}
			</Grid>
		);
	};

	const assetActions = () => (
		<Row style={{ paddingLeft: '10px' }}>
			<Col md={24} style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>

				{actionRounds && actionRounds.length
					? actionRounds.map((item: any, index: number) => {
						const buttonName = typeof item.name === 'string' ? safeJSONParse(item.name) : item.name;

						// Determine the disabled state for the current button
						const isButtonDisabled = item.disableButton?.includes(status) || isButton || isDisabled;

						return (
							item.isvisible === '1' ? (
								<div key={index}>
									<Button
										onClick={() => {
											// eslint-disable-next-line no-unused-expressions
											isButtonDisabled ? '' : actionUpdate(item.actionId);
										}}
										variant='contained'
										size='small'
										disabled={isButtonDisabled}
									>
										{buttonName?.value}
									</Button>
								</div>
							) : null
						);
					})
					: null}
			</Col>

		</Row>
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const addComment = async () => {
		if (extraNotes) {
			const payload = {
				uid: params.uid,
				extraComment: extraNotes,
				workflowId: get(formData, 'workflowId', ''),
				userName: currentUser,
				userId: userLoginId,
			};
			await putAPI(`/${path}/processWorkflow`, payload);
			callBackApis();
		} else {
			setError(true);
		}
	};

	if (loading) {
		return <Backdrop
			sx={
				{ color: '#fff', height: '100vh', zIndex: theme => theme.zIndex.drawer + 1 }
			}
			open={loading}>
			<CircularProgress color='inherit' />
		</Backdrop>;
	}

	const resumeName = data && typeof data.resume === 'string' ? data.resume.split('/')[data.resume.split('/').length - 1] : get(data.resume, 'name', '');

	return (
		<>
			{(error || success)
				&& <Alerts
					severity={error ? 'error' : 'success'}
					message={error ? (errorMessage || 'Error') : 'Successful'}
					open={error || success} />}
			<Grid container sx={{ marginTop: '10px' }}>
				{showProgress && actionRounds.length > 0 && activity && activity[0]
					? <Grid item xs={12} style={{ backgroundColor: '#fff', marginBottom: '10px', overflow: 'auto' }}><Box style={{ margin: 'auto', padding: '10px' }}>
						<Stepper activeStep={activity[0].currentStep - 1} className='profile-tracker-stepper' alternativeLabel>
							{actionRounds.map((item: any, index: number) => {
								const labelProps: {
									optional?: React.ReactNode;
									error?: boolean;
								} = {};
								if (activity && activity[0] && activity[0].isActive === 0 && ((index + 1) === activity[0].currentStep)) {
									labelProps.error = true;
								}

								const stepperName = typeof item.name === 'string' ? safeJSONParse(item.name) : item.name;
								return (
									<Step key={item.sequence}>
										<StepLabel {...labelProps} style={{ whiteSpace: 'nowrap' }}>{stepperName?.value}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</Box>
					</Grid>
					: ''
				}
				<Grid item md={6} xs={12} className={path}>
					<Card>
						<CardContent>
							{form && form.length ? <DetailsForm form={form} data={data} setData={setData} setForm={setForm} /> : ''}
						</CardContent>
						{is_update ? <CardActions style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><Button variant='contained' onClick={handleSubmit}>Update</Button></CardActions> : ''}
					</Card>
					<Grid textAlign='end' style={{ paddingTop: '20px' }}></Grid>
					{/* second column */}
				</Grid>
				<Grid item md={6} xs={12} style={{ paddingLeft: '10px' }} className={path}>
					{is_update
						? <Grid className='menus-container' style={{ display: 'flex', alignItems: 'center' }}>
							{
								manualAction ? assetActions() : ''
							}
							<IconButton sx={{ color: '#1976d2', display: 'flex', marginLeft: 'auto' }} aria-describedby={id} onClick={handleClick}>
								<Comment />
							</IconButton>

							{/* additional comment */}
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								className='add-comment-popover'
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								<Card className='comment-popover-card' sx={{ padding: '10px', width: '100%', boxShadow: 'none' }}>
									<TextareaAutosize
										minRows={2}
										placeholder='Add your comment'
										name='comments'
										style={{ width: '100%', border: '1px solid #e0e0e0', height: '88px', padding: '10px' }}
										onChange={handleAddComment}
										className='comments'
									/>
									<Button onClick={addComment} style={{ marginLeft: 'auto', display: 'block' }} variant='contained'>Add Comment</Button>
								</Card>
							</Popover>
						</Grid>
						: ''}
					<Card>
						<CardContent>
							{activity && activity.map((item: any, index: number) => {
								const obj = typeof item.responseFormResult === 'string' ? safeJSONParse(item.responseFormResult) : typeof (item.responseFormResult) === 'object' ? JSON.parse(JSON.stringify(item.responseFormResult))
									: item.responseFormResult;
								const roundName = typeof item.name === 'string' ? safeJSONParse(item.name) : item.name;
								return (
									<Grid key={index} className='history-content'>
										<Card sx={{ padding: 0, boxShadow: 'none' }}>
											{
												(item.sourceType !== 'comment' && !item.userId && !item.responseId) && is_update ? inputResourceSelect(item) : ''
											}
											{
												item.sourceType !== 'comment' && item.userId && !item.responseId && inputContent(item)
											}
											{
												item.sourceType === 'comment' && <Grid container spacing={1} style={{ marginTop: '10px', marginBottom: '10px' }}>
													<Grid item xs={2} md={1} >
														<Avatar sx={{ bgcolor: '#1976d2' }}>{item.fullName?.substring(0, 1)}</Avatar>
													</Grid>
													<Grid item xs={10} md={11} style={{ marginTop: '10px' }}>
														Comment&nbsp;By&nbsp;<b>{item.fullName} </b> on {item.updatedAt && moment(item.updatedAt).format('DD MMM YY')}
													</Grid>
													<Grid item xs={1} sm={1} md={1}></Grid>
													<Grid item xs={11} sm={11} md={11}>{item.responseComment ? item.responseComment : 'NA'}
													</Grid>
												</Grid>
											}
											{
												item.responseId && item.userId && <Grid container spacing={1} style={{ marginTop: '10px', marginBottom: '10px' }}>
													<Grid item xs={2} md={1} >
														<Avatar sx={{ bgcolor: '#1976d2' }}>{item.fullName?.substring(0, 1)}</Avatar>
													</Grid>
													<Grid item xs={10} md={11} style={{ marginTop: '10px' }}>
														{roundName?.value}&nbsp;
														{roundName?.pp}
														&nbsp;<b>{item.fullName}</b>
													</Grid>
													<Grid item xs={1} md={1} ></Grid>
													<Grid item xs={6} md={6} >
														{obj ? get(obj, 'location')
															? `Status: ${obj.status || 'NA'} at ${obj.location}`
															: `Status: ${obj.status || 'NA'}` : ''}
													</Grid>
													<Grid item xs={5} md={5} className={path + 'date'}>
														Date : {item.updatedAt && moment(item.updatedAt).format('DD MMM YY')}
													</Grid>
													<Grid item xs={1} sm={1} md={1}></Grid>
													<Grid item xs={11} sm={11} md={11}>Comments: {item.responseComment ? item.responseComment : 'NA'}
													</Grid>
												</Grid>
											}
										</Card>
									</Grid>
								);
							},
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};

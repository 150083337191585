import { Col, Input } from 'antd';
import React, {Dispatch, SetStateAction, FC, useEffect, useState} from 'react';

type FieldNameProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const FieldName: FC<FieldNameProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {field_name = ''} = activeElement.element[`${type}_properties`];
	const [fieldName, setFieldName] = useState(field_name);
	// eslint-disable-next-line max-len
	const handleChangeFieldName = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setFieldName(e.target.value);
	};

	useEffect(() => {
		setChangeType('field_name');
		setValue(fieldName);
	}, [fieldName]);
	return (
		<Col span={24}>
			<table>
				<tbody>
					<tr>
						<td>Field Name</td>
						<td>
							<Input
								value={fieldName}
								onChange={handleChangeFieldName}
								placeholder='field_name'
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</Col>
	);
};

export default FieldName;

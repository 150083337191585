import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';
import {camelCase, capitalCase} from 'text-case';
import {useNavigate} from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const MUIDataGrid = (props: any) => {
	const {hideHeader = true, fields = null} = props;
	const navigate = useNavigate();
	const {data = [], formData = {},
		profileTracker} = props;
	const columnDefs = profileTracker
		? data.length && data[0]
		: data.length && JSON.parse(data[0].fieldsResponse);
	const modifiedColumnDefs: GridColDef[]
	= (fields || Object.keys(columnDefs) || []).map((column: any) => ({
		headerName: column === 'resumeDisplay' ? 'Resume' : capitalCase(column),
		field: camelCase(column),
		headerClassName: 'grid-header',
		flex: 1,
		style: {color: 'blue'},
	}));
	const rows
		= profileTracker
			? data.map((item: any, index: any) =>
				({...item, id: index})) : data.map((item: any, index: any) =>
				({
					...JSON.parse(item.fieldsResponse),
					id: index}));

	const handleCellClick = (params:any):void => {
		const {row, field} = params;
		if (field === 'resumeDisplay') {
			window.open(row.resume);
		} else {
			navigate(`/profile-tracker/${row.uid}`);
		}
	};

	return (
		<Box sx={{
			height: 800, background: 'white'}}>
			{hideHeader
			&& <div style={{display: 'flex', justifyContent: 'space-between'}}>
				<p style={{margin: '15px'}}>
				Form Name : {formData.name}
				</p>
				<p style={{margin: '15px'}}
				>
                Total Responses: {data.length}</p>
			</div>}
			<DataGrid
				rows={rows}
				columns={modifiedColumnDefs}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick
				experimentalFeatures={{newEditingApi: true}}
				components={{Toolbar: GridToolbar}}
				onCellClick= { handleCellClick }
				sx={{
					width: '100%',
					cursor: 'pointer',
					'& .grid-header':
					{backgroundColor: 'rgb(206 206 206 / 55%)',
					}}}
			/>
		</Box>
	);
};

import {Col, Input, InputNumber, Modal, Row, Select, Switch} from 'antd';
import React, {useState, useEffect, Dispatch, SetStateAction, FC} from 'react';
import {Icons} from '../../icons/icons-list';
import Icon from '../../icons/get-icon';
import { get, isEmpty } from 'lodash';
import { SketchPicker } from 'react-color';
import { widget_type } from '../../properties-obj/widget-properties-obj';

type ButtonPropertiesProps = {
	setSectionValues: Dispatch<SetStateAction<any>>;
	sectionValues: any;
	activeElement: any;
	pageRoute: string;
}
const ButtonProperties: FC<ButtonPropertiesProps> = ({
	setSectionValues,
	activeElement,
	sectionValues,
}) => {
	const {button} = activeElement.element.column_properties;
	const {
		type,
		size,
		shape,
		icon,
		title,
		block,
		icon_position,
		width = '',
		color = '',
		backgroundColor = '',
		is_submit_button = false,
		is_downlaod_button = false,
		btn_align = '',

	} = button;
	const [buttonType, setButtonType] = useState(type);
	const [buttonSize, setButtonSize] = useState(size);
	const [buttonShape, setButtonShape] = useState(shape);
	const [buttonIcon, setButtonIcon] = useState(icon);
	const [buttonTitle, setButtonTitle] = useState(title);
	const [buttonBlock, setButtonBlock] = useState(block);
	const [changeType, setChangeType] = useState('');
	const [iconPositon, setIconPosition] = useState(icon_position);
	const [buttonWidth, setButtonWidth] = useState(width);
	const [btnContentColor, setBtnContentColor] = useState(color);
	const [isSubmitButton, setISubmitButton] = useState(is_submit_button);
	const [
		isDownloadButton,
		setIsDownloadButton,
	] = useState(is_downlaod_button);
	const [btnAlign, setBtnAlign] = useState(btn_align);
	const [
		btnBackgroundColor,
		setBtnBackgroundColor,
	] = useState(backgroundColor);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleButtonType = (type: string): void => {
		setButtonType(type);
		setChangeType('type');
	};

	const handleButtonSize = (size: string): void => {
		setButtonSize(size);
		setChangeType('size');
	};

	const handleButtonWidth = (width: number | null): void => {
		if (width) {
			setButtonWidth(width);
			setChangeType('width');
		}
	};

	const handleBtnAlign = (value: string):void => {
		setBtnAlign(value);
		setChangeType('btn_align');
	};

	const handleButtonIcon = (icon: string): void => {
		setButtonIcon(icon);
		setChangeType('icon');
	};

	const handleButtonShape = (shape: string): void => {
		setButtonShape(shape);
		setChangeType('shape');
	};

	const handleButtonTitleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setButtonTitle(e.target.value);
		setChangeType('title');
	};

	const handleBlockButton = (checked: boolean): void => {
		setButtonBlock(checked);
		setChangeType('block');
	};

	const handleIconPosition = (value: string): void => {
		setIconPosition(value);
		setChangeType('icon_position');
	};

	const handleIsSubmitButton = (value: boolean): void => {
		setISubmitButton(value);
		setChangeType('is_submit_button');
	};

	const handleIsDownloadButton = (value: boolean): void => {
		setIsDownloadButton(value);
		setChangeType('is_downlaod_button');
	};

	useEffect(() => {
		if (changeType) {
			setSectionValues(
				sectionValues.map((sec: {section_uid: string, row: []}) => {
					const rowRecursion = (row: any): void => {
						row.forEach((row: any) => {
							row.column.forEach((col: any) => {
							// eslint-disable-next-line max-len
								if (col.column_uid === activeElement.element.column_uid) {
									const obj: any = {
										type: buttonType,
										shape: buttonShape,
										size: buttonSize,
										title: buttonTitle,
										block: buttonBlock,
										icon: buttonIcon,
										icon_position: iconPositon,
										width: `${buttonWidth}px`,
										color: btnContentColor,
										backgroundColor: btnBackgroundColor,
										is_submit_button: isSubmitButton,
										is_downlaod_button: isDownloadButton,
										btn_align: btnAlign,
									};
									const value = obj[changeType];
									if (changeType.includes('action')) {
									// eslint-disable-next-line max-len
										col.column_properties.button.action[changeType] = value;
									} else {
									// eslint-disable-next-line max-len
										col.column_properties.button[changeType] = value;
									}
								} else if (widget_type.includes(col.widget_type)) {
								// eslint-disable-next-line max-len
									if (col.column_properties[col.widget_type].row) {
									// eslint-disable-next-line max-len
										rowRecursion(col.column_properties[col.widget_type].row);
									}
								} else if (col.row) {
									rowRecursion(col.row);
								}
							});
						});
					};

					if (sec.section_uid === activeElement.section_uid) {
						rowRecursion(sec.row);
					}

					return sec;
				}),
			);
		}
	}, [
		buttonShape,
		buttonIcon,
		buttonSize,
		buttonTitle,
		buttonType,
		buttonBlock,
		iconPositon,
		buttonWidth,
		btnContentColor,
		btnBackgroundColor,
		isSubmitButton,
		isDownloadButton,
		btnAlign,
	]);

	return (
		<div>
			<Row>
				<Col span={24}>
					<table>
						<thead>
							<tr>
								<th colSpan={2}>Button</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Name</td>
								<td>
									<Input
										placeholder='Button name'
										value={buttonTitle}
										onChange={handleButtonTitleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>Submit Button</td>
								<td>
									<Switch
										onChange={handleIsSubmitButton}
										checked={isSubmitButton}
									/>
								</td>
							</tr>
							<tr>
								<td>Download Button</td>
								<td>
									<Switch
										onChange={handleIsDownloadButton}
										checked={isDownloadButton}
									/>
								</td>
							</tr>
							<tr>
								<td>Button Type</td>
								<td>
									<Select
										style={{width: '100%'}}
										onChange={handleButtonType}
										value={buttonType || 'default'}
										options={[
											{
												value: 'default',
												label: 'Default',
											},
											{
												value: 'primary',
												label: 'Primary',
											},
											{
												value: 'dashed',
												label: 'Dashed',
											},
											{
												value: 'link',
												label: 'Link',
											},
											{
												value: 'text',
												label: 'Text',
											},
										]}
									/>
								</td>
							</tr>
							<tr>
								<td>Size</td>
								<td>
									<Select
										style={{width: '100%'}}
										value={buttonSize || 'default'}
										onChange={handleButtonSize}
										options={[
											{
												value: 'default',
												label: 'Default',
											},
											{
												value: 'large',
												label: 'Large',
											},
											{
												value: 'small',
												label: 'Small',
											},
										]}
									/>
								</td>
							</tr>
							<tr>
								<td>Shape</td>
								<td>
									<Select
										style={{width: '100%'}}
										value={buttonShape || 'default'}
										onChange={handleButtonShape}
										options={[
											{
												value: 'default',
												label: 'Default',
											},
											{
												value: 'circle',
												label: 'Circle',
											},
											{
												value: 'round',
												label: 'Round',
											},
										]}
									/>
								</td>
							</tr>
							<tr>
								<td>Icons</td>
								<td>
									<Select
										style={{width: '100%'}}
										onChange={handleButtonIcon}
										value={icon}
										showSearch
									>
										{Icons.map(
											(item: string, index: number) => (
												<Select.Option
													key={index}
													value={item}
												>
													<div
														style={{
															display: 'flex',
															justifyContent:
															'center',
															alignItems:
															'center',
															height: '100%',
														}}>
														<Icon type={item} />
													</div>
												</Select.Option>
											))}
									</Select>
								</td>
							</tr>
							<tr>
								<td>Button Width</td>
								<td>
									<InputNumber
										value={buttonWidth}
										onChange={handleButtonWidth}
									/>
								</td>
							</tr>
							<tr>
								<td>Button Color</td>
								<td>
									<Input
										className='color-picker'
										placeholder='#000000'
										value={btnBackgroundColor}
										onClick={() => {
											setIsModalVisible(true);
											setChangeType('backgroundColor');
										}}
									/>
								</td>
							</tr>
							<tr>
								<td>Content Color</td>
								<td>
									<Input
										className='color-picker'
										placeholder='#000000'
										value={btnContentColor}
										onClick={() => {
											setIsModalVisible(true);
											setChangeType('color');
										}}
									/>
								</td>
							</tr>
							{
								(!isEmpty(buttonIcon) && !isEmpty(buttonTitle))
								&& (
									<tr>
										<td>Icons Position</td>
										<td>
											<Select
												style={{width: '100%'}}
												value={iconPositon}
												onChange={handleIconPosition}
												options={[
													{
														value: 'left',
														label: 'Left',
													},
													{
														value: 'right',
														label: 'Right',
													},
												]}
											/>
										</td>
									</tr>
								)
							}
							<tr>
								<td>Button Align</td>
								<td>
									<Select
										style={{width: '100%'}}
										options={[
											{
												label: 'Center',
												value: 'center',
											},
											{
												label: 'Left',
												value: 'left',
											},
											{
												label: 'Right',
												value: 'right',
											},
										]}
										value={btnAlign}
										onChange={handleBtnAlign}
									/>
								</td>
							</tr>
							<tr>
								<td>Block Button</td>
								<td>
									<Switch
										checked={buttonBlock}
										onChange={handleBlockButton}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Row>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={
						changeType === 'color' ? btnContentColor
							: btnBackgroundColor
					}
					onChange={
						event => {
							if (changeType === 'color') {
								setBtnContentColor(get(event, 'hex', ''));
							} else if (changeType === 'backgroundColor') {
								setBtnBackgroundColor(get(event, 'hex', ''));
							}
						}
					}
				/>
			</Modal>
		</div>
	);
};

export default ButtonProperties;

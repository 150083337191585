import {Checkbox, Col} from 'antd';
import React, {FC} from 'react';

type CheckBoxCompProps = {
	properties: any;
	dataSource: any;
}

const CheckBoxComp: FC<CheckBoxCompProps> = ({properties}) => {
	const {
		label,
		checkbox,
	} = properties.column_properties;
	const {defaultValue} = checkbox;
	const style = label.label_style;
	const options = [
		{label: '', value: ''},
	];
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Checkbox.Group
				options={options}
				value={defaultValue === 'none' ? undefined : defaultValue}
			/>
		</Col>
	);
};

export default CheckBoxComp;

/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { currentUser, fetchData, updateAPI, uploadFile } from '../api/api';
import { EditForm } from '../forms/edit';
import '../forms/style.css';
import { Backdrop } from '@mui/material';
import { Alerts } from '../shared/components/alerts';
import { camelCase, snakeCase } from 'text-case';
import { getPath } from '../shared/formatting/index';
import { useLocation } from 'react-router-dom';
interface iProps {
	// eslint-disable-next-line no-unused-vars
	onClose: (t: boolean) => void
	toggleModalWithFetch: () => void
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const UplodForm = (props: iProps) => {
	const [form, setForm] = useState([]);
	const [formName, setFormName] = useState('');
	const [formData, setFormData] = useState<any>();
	const [requiredFields, setRequiredFields] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [formError, setFormError] = useState(false);
	const [success, setSuccess] = useState(false);
	const location = useLocation();
	const { pathname } = location;
	const path = getPath(pathname);
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const setFields = (
		formData: any, requireCheck = false, userTypedValue: any = {}) => {
		const required: any = [];
		const parsedFields = formData.map((field: any) => {
			const item = { ...field };
			if (item.fieldProps.required) {
				required.push(camelCase(item.fieldName));
				if (requireCheck
					&& !userTypedValue[camelCase(item.fieldName)]) {
					item.fieldProps.className = 'required error';
					item.fieldProps.helperText
						= 'Please Enter ' + item.fieldName;
				} else {
					delete item.fieldProps.helperText;
					item.fieldProps.className = 'required';
				}
			}

			return {
				...item,
			};
		});
		setRequiredFields(required);
		setForm(parsedFields);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getData = async () => {
		try {
			setLoading(true);
			const element = await fetchData(`/forms/${path}`);
			setFormName(element.results.name);
			setFormData(element.results);
			setFields(JSON.parse(element.results.fieldsJson));
			setFormError(false);
			setLoading(false);
			// eslint-disable-next-line no-unused-vars
		} catch (error: any) {
			setLoading(false);
			setError(true);
		}
	};

	const handleClick = async (formValues: any): Promise<void> => {
		setLoading(true);
		const requireCheck
			= requiredFields.filter((item: any) => formValues[item] && item);
		// eslint-disable-next-line no-negated-condition
		if (requireCheck.length !== requiredFields.length) {
			setLoading(false);
			setFormError(true);
			setFields(form, true, formValues);
		} else {
			setFormError(false);
			setFields(form, true, formValues);

			const files = Object.keys(formValues).reduce((acc: any, key) => {
				if (formValues[key] instanceof File) {
					acc[key] = formValues[key];
				}

				return acc;
			}, {});

			if (Object.keys(files).length) {
				const s3Url = await uploadFile(Object.values(files)[0]);
				formValues[Object.keys(files)[0]] = s3Url;
			}

			const renameKeys = Object.keys(formValues).reduce(
				(acc, key) => ({
					...acc,
					...{ [snakeCase(key)]: formValues[key] },
				}),
				{},
			);
			const params = {
				...renameKeys,
				modified_by: currentUser,
				created_by: currentUser,
				form_uid: formData.uid,
				userId: localStorage.getItem('userLoginId'),
			};
			updateAPI(`/${path}/create`, params).then(() => {
				setLoading(false);
				setSuccess(true);
				props.onClose(true);
				getData();
			}).catch(() => {
				setLoading(false);
				setError(true);
			});
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			{loading
				? (<Backdrop
					sx={
						{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }
					}
					open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop>)
				: <>			<EditForm
					form={form}
					name={formName}
					type={formData && formData.type ? formData.type : ''}
					loading={loading}
					error={formError}
					onClick={handleClick}
					onClose={props.onClose}
				/>
				<Alerts
					severity={error ? 'error' : 'success'}
					message={
						error
							? 'Something went wrong while saving form'
							: 'Successfully saved'
					}
					open={error || success} />
				</>
			}
		</div>
	);
};

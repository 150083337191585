import {InputNumber, Select} from 'antd';
import React, {Dispatch, SetStateAction, useState, FC} from 'react';

type HeightProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	activeElement: any;
    type: string;
}

const Height: FC<HeightProps> = (
	{
		setChangeType,
		setValue,
		activeElement,
		type,
	}) => {
	const {height = null,
	} = activeElement.element[`${type}_style`];

	// eslint-disable-next-line max-len
	const [heightType, setHeightType] = useState<any>(height ? (height.toString().includes('px') ? 'px' : height.toString().includes('%') ? '%' : height.toString().includes('vh') ? 'screen' : '') : null);
	// eslint-disable-next-line max-len
	const handleChange = (value: number | null | string, type: string): void => {
		if (value && type) {
			setValue(`${value}${heightType}`);
			setChangeType(type);
		} else {
			setHeightType(value);
		}
	};

	return (
		<table>
			<tbody>
				<tr>
					<td>Height Type</td>
					<td>
						<Select
							style={{width: '100%'}}
							options={[
								{
									label: 'PX',
									value: 'px',
								},
								{
									label: '%',
									value: '%',
								},
								{
									label: 'Screen',
									value: 'vh',
								},
							]}
							// eslint-disable-next-line max-len
							onChange={(value: string) => handleChange(value, '')}
							value={heightType || undefined}
						/>
					</td>
				</tr>
				{
					heightType && (
						<tr>
							<td>Height</td>
							<td>
								<InputNumber
									// eslint-disable-next-line max-len
									value={height ? height.match(/\d+/g)[0] : null}
									// eslint-disable-next-line max-len
									onChange={(value: number | null) => handleChange(value, 'height')}
									min={0}
									placeholder={heightType}
								/>
							</td>
						</tr>
					)
				}
			</tbody>
		</table>
	);
};

export default Height;

/* eslint-disable max-len */
import { Col, Input, InputNumber, Modal, Row, Select } from 'antd';
import { get } from 'lodash';
import React,
{ Dispatch, SetStateAction, useEffect, useState, FC } from 'react';
import { SketchPicker } from 'react-color';
type SettingsProps = {
	setThemeColor: Dispatch<SetStateAction<string>>;
	setMenuProperties: Dispatch<SetStateAction<any>>;
	menuProperties: any;
}
const Settings: FC<SettingsProps> = ({
	setThemeColor,
	setMenuProperties,
	menuProperties,
}) => {
	const {text, icon, primaryColor, mode} = menuProperties;
	const [isColorModalVisible, setIsColorModalVisible] = useState(false);
	const [textColor, setTextColor] = useState(text.color);
	const [appPrimaryColor, setAppPrimaryColor] = useState(primaryColor);
	const [menuMode, setMenuMode] = useState(mode);
	const [iconColor, setIconColor] = useState(icon.color);
	const [changeType, setChangeType] = useState('');
	const [propertyType, setPropertyType] = useState('');
	const [fontSize, setFontSize] = useState(text.fontSize);
	const [textAlign, setTextAlign] = useState(text.textAlign);
	const [fontWeight, setFontWeight] = useState(text.fontWeight);
	const [fontStyle, setFontStyle] = useState(text.fontStyle);
	const handleChangeMode = (value: string): void => {
		setChangeType('menuMode');
		setMenuMode(value);
	};

	const handleTitleAlign = (align: string): void => {
		setPropertyType('textAlign');
		setChangeType('textAlign');
		setTextAlign(align);
	};

	const handleTitleFontSize = (e: any): void => {
		setPropertyType('fontSize');
		setChangeType('fontSize');
		setFontSize(e);
	};

	const handleTitleFontStyle = (style: string): void => {
		setPropertyType('fontStyle');
		setChangeType('fontStyle');
		setFontStyle(style);
	};

	const handleTitleFontWeight = (weight: string): void => {
		setPropertyType('fontWeight');
		setChangeType('fontWeight');
		setFontWeight(weight);
	};

	useEffect(() => {
		if (changeType === 'appPrimaryColor') {
			setThemeColor(appPrimaryColor);
			setMenuProperties({
				...menuProperties,
				primaryColor: appPrimaryColor,
			});
		} else if (changeType === 'textColor') {
			setMenuProperties({
				...menuProperties,
				text: {
					...menuProperties.text,
					color: textColor,
				},
			});
		} else if (changeType === 'iconColor') {
			setMenuProperties({
				...menuProperties,
				icon: {
					...menuProperties.icon,
					color: iconColor,
				},
			});
		} else if (changeType === 'menuMode') {
			setMenuProperties({...menuProperties, mode: menuMode});
		} else if (changeType === 'fontSize') {
			setMenuProperties({
				...menuProperties,
				text: {
					...menuProperties.text,
					fontSize,
				},
			});
		} else if (changeType === 'fontStyle') {
			setMenuProperties({
				...menuProperties,
				text: {
					...menuProperties.text,
					fontStyle,
				},
			});
		} else if (changeType === 'fontWeight') {
			setMenuProperties({
				...menuProperties,
				text: {
					...menuProperties.text,
					fontWeight,
				},
			});
		} else if (changeType === 'textAlign') {
			setMenuProperties({
				...menuProperties,
				text: {
					...menuProperties.text,
					textAlign,
				},
			});
		}
	}, [
		appPrimaryColor,
		menuMode,
		textColor,
		iconColor,
		fontSize,
		fontStyle,
		fontWeight,
		textAlign,
	]);

	return (
		<Row>
			<Col span={24}>
				<Col span={24}>
					<h3
						style={{
							padding: '5px 10px',
							textAlign: 'center',
							textTransform: 'capitalize',
						}}>Menu Properties</h3>
				</Col>
				<Col span={24}>
					<table>
						<tbody>
							<tr>
								<td>Menu Mode</td>
								<td>
									<Select
										style={{width: '100%'}}
										onChange={handleChangeMode}
										value={menuMode}
										options={[
											{
												value: 'vertical',
												label: 'Vertical',
											},
											{
												value: 'horizontal',
												label: 'Horizontal',
											},
											{
												value: 'inline',
												label: 'Inline',
											},
										]}
									/>
								</td>
							</tr>
							<tr>
								<td>App Theme</td>
								<td>
									<div>
										<Input
											className='color-picker'
											placeholder='#000000'
											value={appPrimaryColor}
											onClick={() => {
												setIsColorModalVisible(true);
												setPropertyType('appPrimaryColor');
											}}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={2} style={{padding: '0'}}>
									<table>
										<thead>
											<tr>
												<th colSpan={2}>
													Text Properties
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Text Color</td>
												<td>
													<div>
														<Input
															className='color-picker'
															placeholder='#000000'
															value={textColor}
															onClick={() => {
																setIsColorModalVisible(true);
																setPropertyType('textColor');
															}}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>Align</td>
												<td>
													<Select
														style={{width: '100%'}}
														value={textAlign}
														onChange={handleTitleAlign}
														options={[
															{
																value: 'left',
																label: 'Left',
															},
															{
																value: 'right',
																label: 'Right',
															},
															{
																value: 'center',
																label: 'Center',
															},
															{
																value: 'justify',
																label: 'Justify',
															},
														]}
													/>
												</td>
											</tr>
											<tr>
												<td>Font Size</td>
												<td>
													<InputNumber
														value={fontSize}
														min={'1'}
														onChange={handleTitleFontSize}
													/>
												</td>
											</tr>
											<tr>
												<td>Font Style</td>
												<td>
													<Select
														style={{width: '100%'}}
														value={fontStyle}
														onChange={handleTitleFontStyle}
														options={[
															{
																value: 'normal',
																label: 'Normal',
															},
															{
																value: 'italic',
																label: 'Italic',
															},
														]}
													/>
												</td>
											</tr>
											<tr>
												<td>Font Weight</td>
												<td>
													<Select
														style={{width: '100%'}}
														value={fontWeight}
														onChange={handleTitleFontWeight}
														options={[
															{
																value: '300',
																label: '300',
															},
															{
																value: '400',
																label: '400',
															},
															{
																value: '500',
																label: '500',
															},
															{
																value: '600',
																label: '600',
															},
															{
																value: '700',
																label: '700',
															},
															{
																value: '800',
																label: '800',
															},
														]}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan={2} style={{padding: '0'}}>
									<table>
										<thead>
											<tr>
												<th colSpan={2}>
													Icon Properties
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Icon Color</td>
												<td>
													<div>
														<Input
															className='color-picker'
															placeholder='#000000'
															value={iconColor}
															onClick={() => {
																setIsColorModalVisible(true);
																setPropertyType('iconColor');
															}}
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</Col>
			</Col>
			<Modal
				title='Color Picker'
				open={isColorModalVisible}
				onOk={() => setIsColorModalVisible(false)}
				onCancel={() => setIsColorModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={
						propertyType === 'appPrimaryColor' ? appPrimaryColor
							: propertyType === 'textColor' ? textColor
								: iconColor
					}
					onChange={
						event => {
							if (propertyType === 'appPrimaryColor') {
								setAppPrimaryColor(get(event, 'hex', ''));
								setChangeType('appPrimaryColor');
							} else if (propertyType === 'textColor') {
								setTextColor(get(event, 'hex', ''));
								setChangeType('textColor');
							} else if (propertyType === 'iconColor') {
								setIconColor(get(event, 'hex', ''));
								setChangeType('iconColor');
							}
						}
					}
				/>
			</Modal>
		</Row>
	);
};

export default Settings;

/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { get, uniqBy } from 'lodash';
import { Radio, Tag } from 'antd';
import { getActionResponse, getActivityView, putAPI } from '../../api/api';
const path = window.location.pathname;

const stopHandler = async (stopuid: any, actionid: any, response: any): Promise<any> => {
	const currentDate = new Date();
	const payload = { uid: stopuid, action_id: actionid, isActionUpdate: 'true', userId: localStorage.getItem('user'), created_by: localStorage.getItem('userLoginId') };
	await putAPI(`/${path}/processWorkflow`, payload);

	let activityid = '';

	getActivityView(`/${path}/getActivity/${stopuid}`).then((res: any) => {
		res.data.forEach((item: any) => {
			if (item.responseId === null && item.sourceType === null) {
				activityid = get(item, 'activityId');
			}
		});
		const payload = {
			uid: stopuid,
			actionId: actionid,
			response: response,
			activityId: activityid,
			last_stop_date: moment(currentDate).format('YYYY-MM-DD HH:mm:ss'),
			created_by: localStorage.getItem('user'),
			userId: localStorage.getItem('userLoginId'),
		};
		putAPI(`/${path}/processWorkflow`, payload);
	});
};

export const columnDef: any = (showModal: any) => (
	{
		'profile-tracker': (obj: any) => [
			{
				title: 'Name', dataIndex: 'name', key: 'name',
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`}
						relative='path'>
						{get(data, 'name', '')}</Link>,
			},
			{
				title: 'Email ID', dataIndex: 'emailId',
				key: 'emailId', width: 200,
			},
			{
				title: 'Mobile Number', dataIndex: 'mobileNumber',
				key: 'mobileNumber', width: 130,
			},
			{
				title: 'Resume', dataIndex: 'resume', key: 'resume',
				render: (text: any) => <a href={text} target='blank'>Link</a>,
			},
			{ title: 'Status', dataIndex: 'status', key: 'status', width: 210 },
			{
				title: 'Current Ctc',
				dataIndex: 'currentCtc', key: 'currentCtc',
			},
			{
				title: 'Expected Ctc', dataIndex: 'expectedCtc',
				key: 'expectedCtc',
			},
			{
				title: 'Notice Period', dataIndex: 'noticePeriod',
				key: 'noticePeriod', ellipsis: true,
			},
			{
				title: 'Position Applied', dataIndex: 'positionApplied',
				key: 'positionApplied', width: 170,
				filters: uniqBy(obj
					.filter((item: any) => item.positionApplied !== '')
					.map((item: any) => (
						{
							text: item.positionApplied,
							value: item.positionApplied,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					String(record.positionApplied).startsWith(value),
			},
			{
				title: 'Years Of Experience', dataIndex: 'yearsOfExperience',
				key: 'yearsOfExperience', ellipsis: true,
			},
			{
				title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt',
				render: (date: any) => moment(date).format('DD-MM-YYYY'),
			},
			{
				title: 'Modified By',
				dataIndex: 'modifiedBy', key: 'modifiedBy',
				ellipsis: true,
			},
		],
		'asset-tracker': (obj: any) => [
			{
				title: 'Serial Number',
				dataIndex: 'serialNumber', key: 'serialNumber',
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`} relative='path'>
						{get(data, 'serialNumber', '')}</Link>,
			},
			{
				title: 'Asset Number', dataIndex: 'assetNumber',
				key: 'emailId',
			},
			{
				title: 'Asset Group', dataIndex: 'assetGroup',
				key: 'assetGroup',
			},
			{
				title: 'Asset Type', dataIndex: 'assetType', key: 'assetType',
				filters: uniqBy(obj.filter((item: any) => item.assetType !== '')
					.map((item: any) => (
						{
							text: item.assetType,
							value: item.assetType,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					String(record.assetType).startsWith(value),
			},
			{ title: 'Brand', dataIndex: 'brand', key: 'brand' },
			{ title: 'Status', dataIndex: 'status', key: 'status', width: 150 },
			{ title: 'Assigned To', dataIndex: 'assignedTo', key: 'assignedTo' },
			{
				title: 'Warranty Status', dataIndex: 'warrantyStatus',
				key: 'warrantyStatus',
			},
			{
				title: 'Invoice', dataIndex: 'invoice', key: 'invoice',
				render: (text: any) =>
					<a href={text} target='blank'>Link</a>,
			},
		],
		'tfw-asset-tracker': (obj: any) => [
			{
				title: 'Serial Number',
				dataIndex: 'serialNumber', key: 'serialNumber', width: 80,
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`} relative='path'>
						{get(data, 'serialNumber', '')}</Link>,
			},
			{
				title: 'Asset Type', dataIndex: 'assetType', key: 'assetType',
				width: 120,
				filters: uniqBy(obj.filter((item: any) => item.assetType !== '')
					.map((item: any) => (
						{
							text: item.assetType,
							value: item.assetType,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.assetType.startsWith(value),
			},
			{ title: 'Brand', dataIndex: 'brand', key: 'brand' },
			{
				title: 'Additional Attributes', dataIndex: 'additionalAttributes',
				key: 'additionalAttributes', width: 150,
			},
			{
				title: 'Kiosk Number', dataIndex: 'kioskNumber',
				key: 'kioskNumber',
			},
			{
				title: 'Status', dataIndex: 'status', key: 'status', width: 210,
				filters: uniqBy(obj.filter((item: any) => item.status !== '')
					.map((item: any) => (
						{
							text: item.status,
							value: item.status,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.status.startsWith(value),
			},
			{
				title: 'Assigned to', dataIndex: 'assignedTo',
				key: 'assignedTo', width: 150,
				filters: uniqBy(obj.filter((item: any) => item.assignedTo !== '')
					.map((item: any) => (
						{
							text: item.assignedTo,
							value: item.assignedTo,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.assignedTo.startsWith(value),
			},
			{
				title: 'Location Details', dataIndex: 'locationDetails',
				key: 'locationDetails',
			},
			{ title: 'Tenant', dataIndex: 'tenant', key: 'tenant' },
		],
		'devops-service-request': (obj: any) => [
			{
				title: 'Project Name',
				dataIndex: 'projectName', key: 'projectName', width: 150,
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`} relative='path'>
						{get(data, 'projectName', '')}</Link>,
			},
			{
				title: 'Request Brief', dataIndex: 'requestBrief',
				key: 'requestBrief',
			},
			{
				title: 'Status', dataIndex: 'status', key: 'status', width: 150,
				filters: uniqBy(obj.filter((item: any) => item.status !== '')
					.map((item: any) => (
						{
							text: item.status,
							value: item.status,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.status.startsWith(value),
			},
			{
				title: 'Due Date', dataIndex: 'dueDate',
				key: 'dueDate',
				render: (date: any) => moment(date).format('YYYY-MM-DD'),
			},
			{
				title: 'Assigned to', dataIndex: 'assignedTo',
				key: 'assignedTo',
				filters: uniqBy(obj.filter((item: any) => item.assignedTo !== '')
					.map((item: any) => (
						{
							text: item.assignedTo,
							value: item.assignedTo,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.assignedTo.startsWith(value),
			},
			{
				title: 'Hours Spent', dataIndex: 'totalHours', key: 'totalHours',
			},
			{
				title: 'Reported By', dataIndex: 'reportedBy',
				key: 'reportedBy',
				filters: uniqBy(obj.filter((item: any) => item.reportedBy !== '')
					.map((item: any) => (
						{
							text: item.reportedBy,
							value: item.reportedBy,
						}
					)), 'value'),
				onFilter: (value: string, record: any) =>
					record.reportedBy.startsWith(value),
			},
		],
		'leave-tracker': () => [

			{
				title: 'Name', dataIndex: 'name', key: 'name', width: 150,
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`}
						relative='path'>
						{get(data, 'name', '')}</Link>,
			},
			{
				title: 'Start date', dataIndex: 'startDate', key: 'startDate',
				render: (date: any) => moment(date).format('DD-MM-YYYY'),
				width: 110,
			},

			{
				title: 'End date', dataIndex: 'endDate', key: 'endDate',
				width: 110,
				render: (date: any) => moment(date).format('DD-MM-YYYY'),
			},
			{
				title: 'Type of leave',
				dataIndex: 'typeOfLeave', key: 'typeOfLeave',
				width: 130,
			},
			{ title: 'Status', dataIndex: 'status', key: 'status', width: 290 },
			{
				title: 'Reason for leave',
				dataIndex: 'reasonForLeave', key: 'reasonForLeave',
			},

		],
		'wfh-tracker': () => [

			{
				title: 'name', dataIndex: 'name', key: 'name',
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`}
						relative='path'>
						{get(data, 'name', '')}</Link>, width: 200,
			},
			{
				title: 'Start date', dataIndex: 'startDate', key: 'startDate',
				render: (date: any) => moment(date).format('DD-MM-YYYY'),
			},

			{
				title: 'End date', dataIndex: 'endDate', key: 'endDate',
				render: (date: any) => moment(date).format('DD-MM-YYYY'),
			},
			{
				title: 'Type',
				dataIndex: 'type', key: 'type',
				width: 130,
			},
			{ title: 'Status', dataIndex: 'status', key: 'status', width: 210 },
			{
				title: 'Reason for applying',
				dataIndex: 'reasonForApplying', key: 'reasonForApplying',
			},

		],

		'service-management': () => [

			{
				title: 'Service name',
				dataIndex: 'serviceName', key: 'serviceName',
				render: (_: any, data: {}) =>
					<Link to={`${path}/${get(data, 'uid', '')}`}
						relative='path'>
						{get(data, 'serviceName', '')}</Link>,
			},
			{
				title: 'URL',
				dataIndex: 'url', key: 'urlPointed',
				width: 200,
			},
			{
				title: 'Last start date', dataIndex: 'lastStartDate',
				render: (lastStartDate: any) => {
					if (lastStartDate) {
						return (
							moment(lastStartDate)
								.format('DD-MM-YYYY HH:mm:ss'));
					}
				},
				key: 'laststartDate',
				width: 100,
			},
			{
				title: 'Last end Date', dataIndex: 'lastEndDate',
				key: 'lastEndDate',
				render:
					(lastEndDate: any) => {
						if (lastEndDate) {
							return (moment(lastEndDate)
								.format('DD-MM-YYYY HH:mm:ss'));
						}
					},
				width: 100,
			},
			{
				title: 'Duration',
				dataIndex: 'duration', key: 'Duration',
			},

			{
				title: 'Status', dataIndex: 'status', key: 'status', width: 150,
			},
			{
				title: 'Action',
				dataIndex: 'containerManage',
				key: 'action', width: 150,

				render: (text: any, record: any) => {
					if (record.containerManage === 'Yes') {
						const startActionId
							= 'd2405b2b-8d0b-435c-bb61-6e28585262a4';
						const startResponseId
							= '2c0f6c73-0005-486f-8b82-e5007e4210e8';
						const stopActionId
							= 'c7844238-85c6-47c8-9c5f-0c7b9432aa53';
						const stopResponseId
							= '53a9370c-9ba5-43f4-975a-826df53cefce';

						// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
						const onChange = async (records: any, e: any) => {
							const actionType = e.target.value;
							if (actionType === 'start') {
								showModal(records.uid, startActionId, startResponseId);
							} else if ((actionType === 'stop')) {
								stopHandler(records.uid, stopActionId, stopResponseId);
							}
						};

						const defaultvalue = ['Initiating Service', 'Service Started'].includes(record.status) ? 'start' : 'stop';
						return (
							<div style={{
								display: 'flex',
								gap: '8px', alignItems: 'center',
							}}>

								<Radio.Group optionType='button' buttonStyle='solid' onChange={e => onChange(record, e)} >
									<Radio.Button
										value='start'
										style={['Stopping Service', 'Service Stopped', null].includes(record.status) ? { backgroundColor: '#1890ff', color: 'white' } : {}}
										disabled={!['Stopping Service', 'Service Stopped', null].includes(record.status)}
									>
										Start
									</Radio.Button>

									<Radio.Button
										value='stop'
										style={['Stopping Service', 'Service Stopped', null].includes(record.status) ? {} : { backgroundColor: '#1890ff', color: 'white' }}
										disabled={['Stopping Service', 'Service Stopped'].includes(record.status)}
									>
										Stop
									</Radio.Button>
								</Radio.Group>

							</div>
						);
					}

					return null;
				},
			},
			{
				title: 'Container Manager', dataIndex: 'containerManage',
				key: 'containerManager',
			},
		],

		exceeded: () => [

			{
				title: 'Name', dataIndex: 'name', key: 'name',
			},
			{
				title: 'Leave Count', dataIndex: 'leaveCount', key: 'leaveCount',
				render: (leaveCount: any) => (
					<Tag color={leaveCount >= 24 ? 'red' : 'blue'}>
						{leaveCount}
					</Tag>
				),
			},
		],
	}
);


/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
interface iProps {
	apiData: {}[],
	fileName: string
}

const orderdData = (apiData: any, fileName: string) => {
	switch (fileName) {
		case 'profile-tracker':
			return apiData.map((item: any) => (
				{
					Name: item.name,
					'Email Id': item.emailId,
					'Mobile Number': item.mobileNumber,
					'Years of Experience': item.yearsOfExperience,
					Resume: item.resume,
					'Position Applied': item.positionApplied,
					'Current CTC': item.currentCtc,
					'Expected CTC': item.expectedCtc,
					'Notice Period': item.noticePeriod,
					Status: item.status,
				}
			));
		case 'asset-tracker':
			return apiData.sort((a: any, b: any) =>
				a.assetNumber > b.assetNumber ? 1 : -1).map((item: any) => (
				{
					'Asset Number': item.assetNumber,
					'Serial Number': item.serialNumber,
					Status: item.status,
					'Asset Type': item.assetType,
					'Assigned to': item.assignedTo,
					Remarks: item.remarks,
					'Warranty Status': item.warrantyStatus,
					'Asset Group': item.assetGroup,
					Brand: item.brand,
					Email: item.email,
					'Mobile Number': item.mobile,

				}
			));
		case 'tfw-asset-tracker':
			return apiData.sort((a: any, b: any) =>
				a.assetNumber > b.assetNumber ? 1 : -1).map((item: any) => (
				{
					'Asset Number': item.assetNumber,
					'Serial Number': item.serialNumber,
					Status: item.status,
					'Assigned to': item.assignedTo,
					Remarks: item.remarks,
					'Additional Attributes': item.additionalAttributes,
					'Kiosk Number': item.kioskNumber,
				}
			));
		case 'devops-service-request':
			return apiData.sort((a: any, b: any) =>
				a.assetNumber > b.assetNumber ? 1 : -1).map((item: any) => (
				{
					'Asset Number': item.projectName,
					'Serial Number': item.requestBrief,
					Status: item.status,
					'Assigned to': item.assignedTo,
					'Reported By': item.reportedBy,
					Remarks: item.remarks,
					'Total Hours': item.totalHours,
				}
			));
		case 'leave-tracker':
			return apiData.map((item: any) => (
				{
					Name: item.name,
					'Start date': item.startDate,
					Status: item.status,
					'End date': item.endDate,
					'Type of leave': item.typeOfLeave,
					'Reason for leave': item.reasonForLeave,
				}
			));
		case 'wfh-tracker':
			return apiData.map((item: any) => (
				{
					Name: item.name,
					'Start date': item.startDate,
					'End date': item.endDate,
					Status: item.status,
					Type: item.type,
					'Reason for Applying': item.reasonForApplying,

				}
			));
		case 'exceeded':
			return apiData.map((item: any) => (
				{
					Name: item.name,
					'Leave Count': item.leaveCount,
				}
			));
		default:
			return [];
	}
};

export const ExportToExcel = ({ apiData, fileName }: iProps) => {
	const fileType
		= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

	const result = orderdData(apiData, fileName);
	const fileExtension = '.xlsx';
	const exportToCSV = (orddata: {}[], fileName: string) => {
		const ws = XLSX.utils.json_to_sheet(orddata);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	return (
		<Button onClick={() => exportToCSV(result, fileName)}
			type='primary'
			shape='circle'>
			<CloudDownloadOutlined className='cloud-dwnld' /> </Button>
	);
};

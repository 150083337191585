/* eslint-disable no-constant-binary-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {Button, ButtonProps} from '@mui/material';
import React from 'react';

type CustomButtonProps = ButtonProps & {
  text: string;
}

const ButtonComp = (props: CustomButtonProps) => (
	<Button
		{...props}
	>
		{props.text}
	</Button>
);

ButtonComp.defaultProps = {
	size: 'medium',
	type: null || '',
	icon: null,
	className: '',
	style: {},
	loading: false,
};

export default ButtonComp;

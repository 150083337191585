import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
	useRef,
} from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Spin,
	Switch,
} from 'antd';
import { useParams } from 'react-router-dom';
import ReactFlow, {
	addEdge,
	MiniMap,
	Controls,
	Background,
	useNodesState,
	useEdgesState,
	Node,
	Edge,
	updateEdge,
	NodeChange,
	EdgeChange,
	applyEdgeChanges,
	applyNodeChanges,
	Connection,
} from 'reactflow';
import '../style.css';
import 'reactflow/dist/style.css';
import { currentUser, getAPI, updateAPI } from '../../../api/api';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

type CreateWorkFLowActionsProps = {
    setFullScreen: Dispatch<SetStateAction<boolean>>;
    setThemeColor: Dispatch<SetStateAction<string>>;
};
const CreateWorkFLowActions: FC<CreateWorkFLowActionsProps> = ({
	setFullScreen,
}) => {
	const currentEdge = useRef(true);
	const { workflow_id } = useParams();
	const [workflow] = Form.useForm();
	const [nodes, setNodes] = useNodesState<Node[]>([]);
	const [edges, setEdges] = useEdgesState<Edge[]>([]);
	const [workFlowActions, setWorkFlowActions] = useState<any[]>([]);
	const [
		workFlowActionResponse,
		setWorkFlowActionResponse,
	] = useState<any[]>([]);
	const [connection, setConnection] = useState<Connection | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showBackGround, setShowBackGround] = useState(true);
	const [showControls, setShowControls] = useState(true);
	const [showMiniMap, setShowMiniMap] = useState(true);
	const [actionResponse, setActionResponse] = useState(false);
	const [loading, setLoading] = useState(false);
	const [activeNode, setActiveNode] = useState<Node<any> | null>(null);
	const [activeEdge, setActiveEdge] = useState<Edge<any> | null>(null);
	const [deletedNodes, setDeletedNodes] = useState<any>({
		action_id: [],
		action_response_id: [],
	});

	const fetchData = (): void => {
		setLoading(true);
		getAPI(`/workflow/${workflow_id}`).then(res => {
			if (res.data.success) {
				const {
					wfActionResponse = [],
					wfActions = [],
					workflow = [],
				} = res.data.data[0];
				if (workflow.length) {
					const json = JSON.parse(workflow[0].workflow_json);
					if (isEmpty(json)) {
						setEdges([]);
						setNodes([]);
					} else {
						const {edges = [], nodes = []} = json;
						setEdges(edges);
						setNodes(nodes);
					}
				}

				if (wfActions.length) {
					setWorkFlowActions(wfActions);
				} else {
					setWorkFlowActions([]);
				}

				if (wfActionResponse.length) {
					setWorkFlowActionResponse(wfActionResponse);
				} else {
					setWorkFlowActionResponse([]);
				}
			} else {
				message.error('Something went wrong when loading data');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong when loading data');
			setLoading(false);
		});
	};

	const getActiveElement = (id: string, type: string): any => {
		if (type === 'node') {
			return workFlowActions.find(action => action.action_id === id);
		}

		if (type === 'edge') {
			// eslint-disable-next-line max-len
			return workFlowActionResponse.find(response => response.response_id === id);
		}
	};

	const onConnect = (connection: Connection): void => {
		setEdges((eds: Edge<Edge<any>[]>[]) => addEdge(connection, eds));
		setConnection(connection);
		setActionResponse(true);
		setIsModalOpen(true);
	};

	// eslint-disable-next-line max-len
	const onNodesChange = (changes: NodeChange[]): void => setNodes((nds: Node<Node<any, string | undefined>[], string | undefined>[]) => applyNodeChanges(changes, nds));

	// eslint-disable-next-line max-len
	const onEdgesChange = (changes: EdgeChange[]): void => setEdges((eds: Edge<Edge<any>[]>[]) => applyEdgeChanges(changes, eds));

	// eslint-disable-next-line max-len
	const onEdgeUpdate = (oldEdge: Edge<any>, newConnection: Connection): void => {
		currentEdge.current = true;
		setEdges((els: Edge<Edge<any>[]>[]) =>
			updateEdge(oldEdge, newConnection, els));
	};

	const onEdgeUpdateStart = (): void => {
		currentEdge.current = false;
	};

	// eslint-disable-next-line max-len
	const onEdgeUpdateEnd = (_: MouseEvent | TouchEvent, edge: Edge<any>): void => {
		if (!currentEdge.current) {
			const {id = ''} = edge;
			setEdges((eds: Edge<Edge<any>[]>[]) =>
				eds.filter((e: Edge<any>) => e.id !== id));
			const wf_response = getActiveElement(id, 'edge');
			if (wf_response) {
				setDeletedNodes({
					...deletedNodes,
					// eslint-disable-next-line max-len
					action_response_id: [...deletedNodes.action_response_id, wf_response.response_id],
				});
			}
		}

		currentEdge.current = true;
	};

	const onFinishAction = (values: any): void => {
		const {
			action_response = '',
			description = '',
			name = '',
			type = 'default',
			sequence = null,
		} = values;
		const action_id = uuid();
		const action = {
			action_id,
			action_response,
			description,
			name,
			action_type: 'MANUAL',
			role_id: null,
			user_id: null,
			sequence: sequence || workFlowActions.length + 1,
			workflow_id,
			notification_template_id: null,
			created_by: currentUser,
			modified_by: currentUser,
		};
		const node: Node = {
			id: action_id,
			type,
			data: { label: name },
			position: {
				x: Math.random() * 500,
				y: Math.random() * 500,
			},
			style: {},
		};
		if (activeNode) {
			if (activeNode) {
				const {id = ''} = activeNode;
				// eslint-disable-next-line max-len
				setWorkFlowActions([...workFlowActions, {...action, action_id: id}]);
				setNodes([...nodes, {
					...node, id,
					position: activeNode.position,
				}]);
			}
		} else {
			setWorkFlowActions([...workFlowActions, action]);
			setNodes([...nodes, node]);
		}

		setIsModalOpen(false);
		workflow.resetFields();
	};

	const onFinishActionResponse = (values: any): void => {
		if (!isEmpty(values)) {
			const response_id = uuid();
			const { response_name = '', response_result = '' } = values;
			const data: any = {
				response_name,
				response_result,
				response_id,
				response_form_id: null,
				response_result_type: 'API',
				created_by: currentUser,
				modified_by: currentUser,
			};
			if (activeEdge) {
				const {id = ''} = activeEdge;
				const activeResponse = getActiveElement(id, 'edge');

				setEdges(edges.map((eds: Edge<any>) => {
					if (eds.id === id) {
						eds.label = response_name;
					}

					return eds;
				}));
				// eslint-disable-next-line max-len
				setWorkFlowActionResponse([...workFlowActionResponse, {...data, response_id: activeEdge.id, action_id: activeResponse.action_id, next_action_id: activeResponse.next_action_id}]);
				setIsModalOpen(false);
				setActionResponse(false);
			} else if (!activeEdge && connection) {
				const { source, target } = connection;
				setEdges(edges.map((eds: Edge<any>) => {
					if (eds.source === source && eds.target === target) {
						eds.label = response_name;
						eds.id = response_id;
					}

					return eds;
				}));
				// eslint-disable-next-line max-len
				setWorkFlowActionResponse([...workFlowActionResponse, {...data, action_id: source, next_action_id: target}]);
				workflow.resetFields();
				setIsModalOpen(false);
				setActionResponse(false);
			}
		}
	};

	const handleSaveAction = (): void => {
		setLoading(true);
		const data: any = {
			wf_actions: workFlowActions,
			wf_action_response: workFlowActionResponse,
			workflow_json: JSON.stringify({
				nodes,
				edges,
			}),
			deleted_nodes: deletedNodes,
		};
		updateAPI(`/workflow/wf_actions/${workflow_id}`, {data}).then(res => {
			if (res.data.success) {
				fetchData();
				message.success('Actions saved successfully');
			} else {
				message.error('Something went wrnog while saving Actions');
			}

			setIsModalOpen(false);
			setLoading(false);
			workflow.resetFields();
		}).catch(() => {
			message.error('Something went wrnog while saving Actions');
			setIsModalOpen(false);
			setLoading(false);
			workflow.resetFields();
		});
	};

	const handleEdit = (): void => {
		if (activeNode) {
			const {id = ''} = activeNode;
			const activeAction = getActiveElement(id, 'node');
			if (!isEmpty(activeAction)) {
				const {
					action_response = '',
					description = '',
					name = '',
				} = activeAction;
				workflow.setFieldsValue({
					action_response,
					description,
					name,
				});
			}

			setIsModalOpen(true);
			setActionResponse(false);
		} else if (activeEdge) {
			const {id = ''} = activeEdge;
			// eslint-disable-next-line max-len
			const activeResponse = workFlowActionResponse.find(response => response.response_id === id);
			if (!isEmpty(activeResponse)) {
				const {response_name = '', response_result} = activeResponse;
				workflow.setFieldsValue({
					response_result,
					response_name,
				});
				setIsModalOpen(true);
				setActionResponse(true);
			}
		}
	};

	const handleDelete = (): void => {
		if (activeNode) {
			const {id = ''} = activeNode;
			const node = nodes.filter(node => node.id !== id);
			// eslint-disable-next-line max-len
			const edge = edges.filter(edge => edge.target !== id || edge.source !== id);
			// eslint-disable-next-line max-len
			const wf_response = workFlowActionResponse.filter(response => response.action_id === id || response.next_action_id === id).map(response => response.response_id);
			setDeletedNodes({
				...deletedNodes,
				action_id: [...deletedNodes.action_id, id],
				// eslint-disable-next-line max-len
				action_response_id: [...deletedNodes.action_response_id, ...wf_response],
			});
			setNodes(node);
			setEdges(edge);
		} else if (activeEdge) {
			const {source = '', target, id = ''} = activeEdge;
			const edge = edges.filter(edge => edge.id !== id);
			setEdges(edge);
			// eslint-disable-next-line max-len
			const wf_response = workFlowActionResponse.filter(response => response.action_id === source || response.next_action_id === target).map(response => response.response_id);
			if (wf_response) {
				setDeletedNodes({
					...deletedNodes,
					// eslint-disable-next-line max-len
					action_response_id: [...deletedNodes.action_response_id, ...wf_response],
				});
				// eslint-disable-next-line max-len
				setWorkFlowActionResponse(workFlowActionResponse.filter(response => response.response_id !== id));
			}
		}
	};

	useEffect(() => {
		setFullScreen(true);
		fetchData();
	}, []);

	return (
		<Spin spinning={loading}>
			<div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
				<Col span={24} style={{
					padding: '10px',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
					<Col style={{
						display: 'flex',
						gap: '10px',
						alignItems: 'center',
					}}>
						<Button
							type='primary'
							icon={<PlusOutlined />}
							size='large'
							onClick={() => {
								setIsModalOpen(true);
								setActiveEdge(null);
								setActiveNode(null);
							}}
						>
							Action
						</Button>
						<Switch
							checkedChildren='Layout'
							unCheckedChildren='Layout'
							onChange={
								(value: boolean) => setShowBackGround(value)
							}
							checked={showBackGround}
						/>
						<Switch
							checkedChildren='Controls'
							unCheckedChildren='Controls'
							onChange={
								(value: boolean) => setShowControls(value)
							}
							checked={showControls}
						/>
						<Switch
							checkedChildren='MiniMap'
							unCheckedChildren='MiniMap'
							onChange={(value: boolean) => setShowMiniMap(value)}
							checked={showMiniMap}
						/>
						{
							(activeEdge || activeNode) && (
								<Col style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'center',
								}}>
									<Button
										type='primary'
										icon={<EditOutlined />}
										onClick={handleEdit}
									>
										{activeNode ? 'Action' : 'Response'}
									</Button>
									<Button
										type='primary'
										icon={<DeleteOutlined />}
										onClick={handleDelete}
									>
										{activeNode ? 'Action' : 'Response'}
									</Button>
								</Col>
							)
						}
					</Col>
					<Col style={{
						display: 'flex',
						gap: '10px',
					}}>
						<Button type='default' size='large'>Cancel</Button>
						<Button
							type='primary'
							size='large'
							onClick={handleSaveAction}
						>Save</Button>
					</Col>
				</Col>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onEdgeUpdate={onEdgeUpdate}
					onEdgeUpdateStart={onEdgeUpdateStart}
					onEdgeUpdateEnd={onEdgeUpdateEnd}
					onConnect={onConnect}
					onNodeClick={
						(_: React.MouseEvent, node: Node) => {
							setActiveNode(node);
							setActiveEdge(null);
						}}
					onEdgeClick={
						(_: React.MouseEvent, edge: Edge) => {
							setActiveEdge(edge);
							setActiveNode(null);
						}}
				>
					{showMiniMap && <MiniMap />}
					{showControls && <Controls />}
					{showBackGround && <Background />}
				</ReactFlow>
				<Modal
					title={actionResponse ? 'Response' : 'Action'}
					open={isModalOpen}
					onCancel={() => {
						setIsModalOpen(false);
						workflow.resetFields();
					}}
					footer={false}
				>
					<Col span={24}>
						<Form
							name='workflow'
							autoComplete='off'
							layout='vertical'
							form={workflow}
							onFinish={
								actionResponse ? onFinishActionResponse
									: onFinishAction
							}
						>
							{
								!actionResponse && (
									<>
										<Form.Item
											label='Action Name'
											name='name'
											// eslint-disable-next-line max-len
											rules={[{ required: true, message: 'Please Enter Action Name!' }]}
										>
											<Input
												placeholder={
													'Enter Work Action Name'
												} />
										</Form.Item>
										<Form.Item
											label='Response'
											name='action_response'
											// eslint-disable-next-line max-len
											rules={[{ required: true, message: 'Please Enter Response!' }]}
										>
											<Input
												placeholder='Enter Response' />
										</Form.Item>
										<Form.Item
											label='Sequence'
											name='sequence'
											// eslint-disable-next-line max-len
											rules={[{ required: true, message: 'Please Enter Response Result!' }]}
										>
											<InputNumber
												placeholder='Enter Sequence'
												style={{width: '100%'}}
											/>
										</Form.Item>
										<Form.Item
											label='Description'
											name='description'
										>
											<Input placeholder={
												'Enter Description'
											} />
										</Form.Item>
									</>
								)
							}
							{
								actionResponse && (
									<>
										<Form.Item
											label='Response Name'
											name='response_name'
											rules={[{
												required: true,
												message:
											'Please Enter Action Name!',
											}]}
										>
											<Input
												placeholder={
													'Enter Work Response Name'
												}
											/>
										</Form.Item>
										<Form.Item
											label='Response Result'
											name='response_result'
											// eslint-disable-next-line max-len
											rules={[{ required: true, message: 'Please Enter Response Result!' }]}
										>
											<Input
												placeholder='Enter Response' />
										</Form.Item>
									</>
								)
							}
							<Form.Item>
								<Col span={24}>
									<Row style={{
										justifyContent: 'end',
										gap: '10px',
									}}>
										<Button type='default'
											onClick={() => {
												setIsModalOpen(false);
												workflow.resetFields();
											}}
										> Cancel </Button>
										<Button
											type='primary'
											htmlType='submit'
										> Submit </Button>
									</Row>
								</Col>
							</Form.Item>
						</Form>
					</Col>
				</Modal>
			</div>
		</Spin>
	);
};

export default CreateWorkFLowActions;


/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import ButtonComp from '../shared/components/button/button';
import copyToClip from 'copy-to-clipboard';
import { currentUser, deleteAPI, fetchData, getAPI, updateAPI } from '../api/api';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
	CardActions,
	SelectChangeEvent,
} from '@mui/material';
import _ from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { Add, CloseOutlined, ContentCopy, Clear, Delete, Done } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/RemoveRedEye';
import TextFieldComp from '../shared/components/textfield/textfield';
import { Alerts } from '../shared/components/alerts';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { EditForm } from '../forms/edit';
import { notification } from 'antd';

export const formTypes = ['Text', 'Select', 'Email', 'Date', 'Radio', 'Checkbox', 'Number', 'Tel', 'Textarea', 'FileUpload'];
export const lookUpFields = ['select', 'radio', 'checkbox'];
export const defaultField = [{
	fieldName: '',
	fieldType: '',
	lookupUid: '',
	required: false,
}];

interface MyState {
	name: string;
	items: string[];
}

function getObjectValues(arr: object[]): any[] {
	try {
		const values = arr.map(obj => Object.values(obj));
		return values.flat();
	} catch (error) {
		console.error(error);
		return [];
	}
}

export const AppsList: React.FC = () => {
	const [formList, setFormList] = useState([]);
	const [viewResponseData, setViewResponseData] = useState();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [currentFormData, setCurrentFormData] = useState<any>();
	const [del, setDel] = useState(false);
	const [add, setAdd] = useState(false);
	const [deleteVal, setDeleteVal] = useState();
	const [viewResponse, setViewResponse] = useState(false);
	const [send, setSend] = useState(false);
	const [copy, setCopy] = useState(false);
	const [viewResponseLoading, setViewResponseLoading] = useState(false);
	const [type, setType] = useState('');
	const [name, setName] = useState('');
	const [selectAllChecked, setSelectAllChecked] = useState(true);
	const [formType, setFormType] = useState('');
	const [message, setMessage] = useState('Success');
	const [lookup, setLookup] = useState([]);
	const [lookupValue, setLookupValue] = useState([]);
	const [userInfo, setUserInfo] = useState<any>([]);
	const [chips, setChips] = useState<any>([]);
	const [selectedFile, setSelectedFile] = useState<any>({});
	const [mailLoader, setMailSendLoader] = useState(false);
	const [mailSent, setSentMail] = useState(false);
	const [deleteLoader, setDeleteLoader] = useState(false);
	const [preview, setPreview] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [formValues, setFormValues] = useState<any | []>(defaultField);

	const [newoption, setNewoption] = useState(['Oliver Hansen', 'Van Henry', 'April Tucker', 'Ralph Hubbard', 'Omar Alexander', 'Carlos Abbott', 'Miriam Wagner', 'Bradley Wilkerson', 'Virginia Andrews', 'Kelly Snyder']);
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState<String[]>([]);

	const [count, setCount] = useState(0);
	const [arr, setArr] = useState([]);
	const [app_uid, setApp_uid] = useState();
	const [laneInfo, setLaneInfo] = useState<MyState[]>([]);
	useEffect(() => {
		fetchData('/lookups')
			.then(res => setLookup(res.data))
			.catch(() => setError(true));
		fetchData('/lookups/values')
			.then(res => setLookupValue(res.data))
			.catch(() => setError(true));
		fetchData('/user/all-users')
			.then(res => setUserInfo(res.data.map((user: any) => ({ email: user.email, checked: true }))))
			.catch(() => setError(true));
	}, []);
	const handleChange = (e: any, index: number): void => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.target.type === 'checkbox' ? e.target.checked : e.currentTarget.value;
		const newFormData = [...formValues];
		newFormData[index][fieldName] = fieldValue;
		setFormValues(newFormData);
	};

	const handleSelectChange = (e: any, index: number): void => {
		setType(e.target.value);
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		const newFormData = [...formValues];
		if (lookUpFields.includes(fieldValue)) {
			newFormData[index].lookupUid = '';
		}

		newFormData[index][fieldName] = fieldValue;
		setFormValues(newFormData);
	};

	const handleSubmit = (): void => {
		setLoading(true);
		setSuccess(false);
		const modifiedFormValues = formValues.map((item: any) => {
			const field = { ...item };
			let selectOptions: any = [];
			if (lookUpFields.includes(field.fieldType)) {
				selectOptions = lookupValue.filter((lookup: any) => lookup.lookupUid === item.lookupUid);
			}

			return {
				...field,
				fieldProps: {
					type: item.fieldType,
					required: item.required,
				},
				value: selectOptions.length ? JSON.parse(selectOptions[0].value) : '',
			};
		});
		const params = {
			name,
			type: formType,
			fields_json: JSON.stringify(modifiedFormValues),
			modified_by: currentUser,
			created_by: currentUser,
			user_json: JSON.stringify([]),
			is_app: 1,
		};
		updateAPI('/forms/create', params)
			.then(() => {
				setLoading(false);
				setSuccess(true);
				setMessage('Form created successfully');
				handleClose();
				getData();
			})
			.catch(() => setError(true));
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleOpen = async (data: any) => {
		setViewResponseLoading(true);
		setViewResponse(true);
		setOpen(true);
		setCurrentFormData(data);
		fetchData(`/user?uid=${data.uid}`)
			.then((res: any) => {
				setViewResponseLoading(false);
				setViewResponseData(res.data);
			})
			.catch(() => {
				setViewResponseLoading(false);
				handleClose();
				setError(true);
			});
	};

	const handleClose = (): void => {
		setDel(false);
		setViewResponse(false);
		setSend(false);
		setOpen(false);
		setAdd(false);
		setDeleteLoader(false);
		setSelectAllChecked(false);
		setChips([]);
		setPreview(false);
		setIsEdit(false);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const getData = async () => {
		try {
			setLoading(true);
			const element = await getAPI('/forms', { isApp: 1 });
			setFormList(element.data.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(true);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const onAddButtonClick = (): void => {
		setFormValues(formValues.concat(defaultField));
	};

	const handlePreview = (form: any, isEdit = false): void => {
		if (isEdit) {
			setIsEdit(true);
		} else {
			setPreview(true);
		}

		setOpen(true);
		setCurrentFormData(form);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const userInfoModified = userInfo.map((user: any) => ({ ...user, checked: event.target.checked }));
		if (event.target.value === 'Select All') {
			setSelectAllChecked(event.target.checked);
			setUserInfo(userInfoModified);
		} else {
			const checkUpdatedData = userInfo.map((item: any) => (item.email === event.target.value ? { ...item, checked: event.target.checked } : { ...item }));
			setUserInfo(checkUpdatedData);
		}
	};

	const handleRemoveField = (index: any): void => {
		const updateForm = [...formValues];
		updateForm.splice(index, 1);
		setFormValues(updateForm);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const uploadFile = async (file: any) => {
		const { name = '', type = '' } = selectedFile;
		updateAPI('/s3/getSignedUrl', { name: name.replace(/ /g, '_'), type }).then(async res => {
			const { data } = res.data;
			const options = {
				headers: { 'Content-Type': file.type },
				reportProgress: true,
			};
			await axios.put(data.info.signedRequest, selectedFile, { ...options }).catch(err => console.log(err));
		});
	};

	const handleLane = (form: any): void => {
		setVisible(true);
		setCurrentFormData(form);
		setApp_uid(form.uid);
		const currentFormName = _.snakeCase(form.name);
		const arr = [getAPI(`/laneview?tableName=${currentFormName}&distinictField=status`),
			getAPI(`/laneview/getLaneData?uid=${form.uid}`)];
		Promise.all(arr).then((res: any) => {
			const opt = getObjectValues(res[0].data.data);
			setNewoption(opt);
			const listData: any = res[1].data;
			if (listData?.success) {
				const {attributes = []} = listData.results;
				const jsondata = JSON.parse(attributes);
				setLaneInfo(jsondata.length ? jsondata : []);
				setCount(jsondata.length);
			}
		});
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleLaneSave = (form: any) => {
		const data = JSON.stringify(laneInfo);
		const params = {
			app_uid,
			created_by: currentUser,
			modified_by: currentUser,
			attributes: data,
		};
		updateAPI('/laneview/createLane', params).then(res => {
			notification.success({ message: 'Saved successfully', placement: 'topRight' });
		});
		handleClear();
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleClear = () => {
		setVisible(false);
		setCount(0);
		setLaneInfo([]);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleLaneChange = (e: SelectChangeEvent<typeof selected>, index: number) => {
		const { target: { value } } = e;
		setSelected(prevarr => prevarr.concat(value));
		setLaneInfo(prevState =>
			prevState.map((item, i) => index === i ? { ...item, items: (typeof value === 'string' ? value.split(',') : value) as string[] } : item));
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleLaneAdd = () => {
		setLaneInfo([...laneInfo, { name: '', items: [] }]);
		setCount(count + 1);
		const newop = newoption.filter(name => !selected.includes(name));
		setNewoption(newop);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleOptionDelete = (e: any, value: any, index: number) => {
		setNewoption(current => current.concat(value));
		for (let i = 0; i < laneInfo.length; i++) {
			if (Array.isArray(laneInfo[i].items)) {
				laneInfo[i].items = laneInfo[i].items.filter(val => val !== value);
			}
		}
	};

	const handleDelete = (index: number): void => {
		const laneDetails = [...laneInfo];
		laneDetails.splice(index, 1);
		setCount(count - 1);
		setLaneInfo(laneDetails);
	};

	return (
		<div>
			{(mailSent) && <Alerts
				severity={error ? 'error' : 'success'}
				message={
					error
						? 'Something went wrong'
						: mailSent ? 'Sent Successfully' : message
				}
				open={error || success || mailSent} />}
			<Dialog open={add}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: '60%',
						},
					},
				}}
			>
				<DialogTitle>
					<Typography variant='h5'>{'Add Form'}</Typography>
					<IconButton
						aria-label='close'
						onClick={() => setAdd(false)}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500],
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				<>
					<DialogContent dividers>
						<Grid container>
							<Grid item xs={6}>
								<FormGroup sx={{ p: 2 }}>
									<FormLabel htmlFor='name'>Form Name</FormLabel>
									<TextFieldComp name='name' onChange={e => setName(e.target.value)} />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
								<FormGroup sx={{ p: 2 }}>
									<FormLabel htmlFor='type'>Form Type</FormLabel>
									<TextFieldComp name='type' onChange={e => setFormType(e.target.value)} />
								</FormGroup>
							</Grid>
							{/* my map function */}
							{formValues.map((item: any, index: number) =>
								<Grid item xs={12} key={index} style={{ display: 'flex', marginTop: '15px' }} className='form-grid'>
									<Grid xs={4}>
										<FormGroup sx={{ p: 2 }}>
											<FormLabel htmlFor='fieldName'>Field Name</FormLabel>
											<TextFieldComp name='fieldName' value={item.fieldName} onChange={e => handleChange(e, index)} />
										</FormGroup>
										<FormGroup style={{ marginLeft: '20px' }}>
											<FormControlLabel control={<Checkbox name='required' onChange={e => handleChange(e, index)} />} label='Required' />
										</FormGroup>
									</Grid>
									<Grid xs={4}>
										<FormGroup sx={{ p: 2 }}>
											<FormLabel htmlFor='fieldType'>Type</FormLabel>
											<Select value={item.fieldType} name='fieldType' onChange={e => handleSelectChange(e, index)}>
												{formTypes.map((val: any) => (
													// eslint-disable-next-line react/jsx-key
													<MenuItem value={val.toLowerCase()}>{val}</MenuItem>
												))}
											</Select>
										</FormGroup>
									</Grid>
									{lookUpFields.includes(item.fieldType)
										&& <Grid xs={4}>
											<FormGroup sx={{ p: 2 }}>
												<FormLabel htmlFor='lookupUid'>Look Up Name</FormLabel>
												<Select name='lookupUid' value={item.lookupUid} onChange={e => handleSelectChange(e, index)}>
													{lookup.map((val: any) => (
														// eslint-disable-next-line react/jsx-key
														<MenuItem value={val.uid}>{val.name}</MenuItem>
													))}
												</Select>
												{/* <TextFieldComp name='fieldValue' onChange={e => handleChange(e, index)} /> */}
											</FormGroup>
										</Grid>
									}
									<div><Clear className='cursor-pointer' onClick={() => handleRemoveField(index)}></Clear></div>
								</Grid>,
							)}
							<Grid item style={{ width: '100%' }}>
								<FormControl sx={{ p: 2 }} style={{ float: 'right' }}>
									<ButtonComp variant='contained' startIcon={<Add />} onClick={onAddButtonClick} text='Add Field' />
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
				</>
				<DialogActions>
					<Button variant='outlined' onClick={handleClose}>Cancel</Button>
					<LoadingButton
						onClick={handleSubmit}
						endIcon={<Add />}
						loading={loading}
						loadingPosition='end'
						variant='contained'
					>
						Save
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Dialog open={open}
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
							width: '100%',
							maxWidth: (del || send) ? '500px' : '80%',
						},
					},
				}}
			>
				<DialogTitle style={{ position: 'sticky', background: 'white', top: 0 }}>
					<Typography variant='h5'>{del ? 'Delete Form' : send
						? <h5>Share Form <Tooltip onClose={() => setTimeout(() => setCopy(false), 200)} title={copy ? 'copied' : 'click to copy form url'}>
							<ContentCopy onClick={() => {
								copyToClip(`${window.location.origin}/forms/${currentFormData.uid}`, {
									debug: true,
									message: 'Press to copy',
								});
								setCopy(true);
							}} style={{ fontSize: '15px' }} className='cursor-pointer' /></Tooltip></h5> : preview ? 'Preview' : 'Response'}</Typography>
					<IconButton
						aria-label='close'
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: theme => theme.palette.grey[500],
						}}
					>
						<CloseOutlined />
					</IconButton>
				</DialogTitle>
				{(preview || isEdit)
					&& <>
						<DialogContent>
							<EditForm disabled={true} editFields={isEdit} form={JSON.parse(currentFormData.fieldsJson)} name={currentFormData.name} />
						</DialogContent>
					</>
				}
			</Dialog>
			<ButtonComp variant='contained' startIcon={<Add />} onClick={
				() => setAdd(true)} text='Add Form' />
			{loading ? <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', margin: '13px 0px' }}>{[...Array(Number(localStorage.getItem('formLegth') || 3))].map((index: number) => <Card
				key={index}
				style={{
					flexGrow: '1',
					minWidth: '350px',
					maxWidth: '350px',
					margin: '13px 0px',
					borderLeft: '10px solid #006bb6',
					borderRadius: '15px 0px 0px 15px',
				}}
			>
				<CardContent><Skeleton count={4} /></CardContent></Card>)}</div> : <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap', margin: '13px 0px' }}>
				{
					formList.map((form: any) => (
						<Card
							key={form.uid}
							style={{
								flexGrow: '1',
								minWidth: '350px',
								maxWidth: '350px',
								display: 'flex',
								flexDirection: 'column',
								borderLeft: '10px solid #006bb6',
								borderRadius: '15px 0px 0px 15px',
								cursor: 'pointer',
								marginLeft: '10px',
							}}
						>
							<CardContent>
								<Typography mb={1.5}
									variant='h5'
									component='div'
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<a style={{ color: '#006bb6' }} target='_blank' href={`${window.location.origin}/${form.type}`} rel='noreferrer'>{form.name}</a>
									<div style={{ display: 'flex', cursor: 'pointer', gap: '1rem', flexWrap: 'wrap' }}>
										<SignalCellularAltIcon onClick={() => handleLane(form)} sx={{ color: theme => theme.palette.grey[900] }}></SignalCellularAltIcon>
										<PreviewIcon onClick={() => handlePreview(form)} className='cursor-pointer'></PreviewIcon>
									</div>
								</Typography>
								<Typography mb={1.5}
									variant='body2'>
									{form.type}
								</Typography>

								<Dialog open={visible} PaperProps={{ sx: { width: '100%', height: '100%', maxWidth: '80%' } }}>
									<DialogTitle variant='h5' sx={{ display: 'flex', justifyContent: 'space-between' }}><>Lane View</>
										<IconButton aria-label='close' onClick={handleClear} sx={{ color: theme => theme.palette.grey[500] }}><CloseOutlined /></IconButton></DialogTitle>
									<DialogContent dividers >
										<Grid container sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'strech', alignItems: 'center', gap: 3 }}>
											<>
												{[...Array(count)].map((input, index) => <div key={index}>
													<Card sx={{ borderRadius: '16px', boxShadow: 3, marginTop: '10px', height: '400px' }}>
														<CardHeader action={
															<>
																<Button
																	sx={{ color: 'black', m: 1, p: 0, minWidth: 0 }}
																	size='small' variant='text'><Delete onClick={() => handleDelete(index)} /></Button>
															</>}></CardHeader>
														<CardContent>
															<Grid item style={{ marginTop: '3px' }}>
																<FormControl sx={{ m: 1, minWidth: 120 }}>
																	<TextField size='small' name='name' id='outlined-basic' value={laneInfo[index].name} onChange={e => setLaneInfo(laneInfo.map((item, i) => (i === index ? { ...item, name: e.target.value } : item)))} />
																</FormControl>
															</Grid>
															<Grid item style={{ marginTop: '30px' }}>
																<FormControl sx={{ minWidth: 210 }}>
																	<Select variant={undefined} sx={{ boxShadow: 'none', outline: 'none !important', maxHeight: '200px', overflowY: 'auto !important' }}
																		multiple
																		value={laneInfo[index].items}
																		onChange={e => {
																			handleLaneChange(e, index);
																		}}
																		placeholder='select'
																		renderValue={list => (list.map(value => <Grid sx={{ direction: 'column' }} key={value}><Chip key={value} label={value} clickable deleteIcon={<CancelIcon onMouseDown={event => event.stopPropagation()} />} onDelete={e => handleOptionDelete(e, value, index)} /></Grid>))}
																	>
																		{newoption.map(name1 => (
																			<MenuItem key={name1}
																				value={name1}>
																				{name1}
																			</MenuItem>
																		))}
																	</Select>
																</FormControl>
															</Grid>
														</CardContent>
													</Card>
												</div>,
												)}
											</>
											<Button onClick={handleLaneAdd} color='primary' sx={{ height: '20%', marginTop: '30px', marginLeft: '10px' }} variant='contained' startIcon={<Add />}>Add</Button>
										</Grid>
									</DialogContent>
									<DialogActions sx={{ justifyContent: 'flex-end' }}>
										<Button style={{ marginTop: '2px', marginRight: '2px' }} color='primary' variant='contained' onClick={() => handleLaneSave(form)}>Save</Button>
									</DialogActions>
								</Dialog>
							</CardContent>
						</Card>
					))
				}
			</div>}
		</div>
	);
};

import React, {FC, CSSProperties} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import './style.css';
import WidgetElemet from './widgets-dnd';
import {SVGProps } from 'react';
import {Col, Row } from 'antd';
import { snakeCase } from 'lodash';
import Icon from '../icons/get-icon';

type ElementProps = {
	widgets: {
    id: string;
    name: string;
    type: string;
    icon: SVGProps<SVGElement>;
  }[];
  component: {
	componentUid: string;
	componentName: string;
	componentIcon: string;
  }[];
  identifier: string;
};

const getItemStyle = (isDraggingOver: boolean): CSSProperties => ({
	padding: '8px',
	background: isDraggingOver ? '#c1c1c1' : 'inherit',
	transition: 'background 0.2s ease-in',
});

const Widgets: FC<ElementProps> = ({widgets, identifier, component}) => (
	<Row>
		<Col
			className='card-title'
			span={24}
			style={{padding: '7px 10px'}}
		>
			<p>Components</p>
		</Col>
		<Col span={24} className='elements'>
			<Droppable droppableId={identifier} key={identifier}>
				{(provided, snapshots) => (
					<div
						className='elements-container'
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getItemStyle(snapshots.isDraggingOver)}
					>
						{widgets.map((item, index) => (
							<WidgetElemet
								id={item.id}
								name={item.name}
								type={item.type}
								icon={item.icon}
								index={index}
								key={index}
							/>
						))}
						{component.map((item: any, index) => (
							<WidgetElemet
								id={item.componentUid}
								name={item.componentName}
								icon={<Icon type={item.componentIcon}/>}
								type={snakeCase(item.componentName)}
								index={index}
								key={index}
							/>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</Col>
	</Row>
);

export default Widgets;


import {
	DeleteOutlined,
	PlusOutlined,
	LayoutOutlined,
	EditOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	Popconfirm,
	Row,
	Select,
	Table,
} from 'antd';
import {isEmpty } from 'lodash';
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import './style.css';
import { currentUser, deleteAPI, getAPI, updateAPI } from '../../api/api';

type CreateAppProps = {
	setFullScreen: Dispatch<SetStateAction<boolean>>;
	setThemeColor: Dispatch<SetStateAction<string>>;
};
const CreateApp:FC<CreateAppProps> = ({setFullScreen}) => {
	const [applications] = Form.useForm();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [appData, setAppData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [appID, setAppID] = useState('');
	const [pages, setPages] = useState<any>([]);

	const handleLayout = (application_uid: string): any => {
		setLoading(true);
		getAPI(`/apps/${application_uid}`).then((res: any) => {
			if (res.data.success) {
				const {data} = res.data;
				const applicationLayout = JSON.parse(data[0].applicationLayout);
				// eslint-disable-next-line no-negated-condition
				if (!isEmpty(applicationLayout)) {
					const {layout_type} = applicationLayout;
					navigate(`/layout/${layout_type}/${application_uid}`);
				} else {
					navigate(`/layout/${application_uid}`);
				}
			} else {
				message.error('Something Went Wrong');
			}

			setLoading(false);
		}).catch(() => {
			setLoading(false);
			message.error('Something Went Wrong');
		});
	};

	const handleEdit = (app: any): void => {
		const {
			applicationName = '',
			applicationBasicName = '',
			loginPageUid = '',
		} = app;
		applications.setFieldsValue({
			application_name: applicationName,
			application_basic_name: applicationBasicName,
			login_page_uid: loginPageUid,
		});
		setAppID(app.applicationUid);
		setIsEdit(true);
		setIsModalOpen(true);
	};

	const fetchAppData = (): void => {
		setLoading(true);
		getAPI('/apps').then((res: any) => {
			if (res.data.success) {
				setAppData(res.data.data);
			} else {
				message.error('Something went wrong');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	const fetchPageData = (app: any): void => {
		setLoading(true);
		getAPI(`/pages/${app.applicationUid}`).then((res: any) => {
			if (res.data.success) {
				const {data} = res.data;
				// eslint-disable-next-line no-negated-condition
				if (!isEmpty(data)) {
					const options = [];
					for (let i = 0; i < data.length; i++) {
						options.push({
							label: data[i].pageName,
							value: data[i].pageUid,
						});
					}

					setPages(options);
				} else {
					setPages([]);
				}
			} else {
				message.error('Something went wrong');
				setPages([]);
			}

			handleEdit(app);
			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setPages([]);
			handleEdit(app);
			setLoading(false);
		});
	};

	const deleteApp = (applicationUid: string): void => {
		setLoading(true);
		const data = {application_uid: applicationUid};
		deleteAPI('/apps', {data}).then(res => {
			if (res.data.success) {
				fetchAppData();
				message.success('App deleted successfully');
			} else {
				message.error('App deleted failed');
			}

			setLoading(false);
		}).catch(() => {
			message.error('Something went wrong');
			setLoading(false);
		});
	};

	const onFinish = (values: any): void => {
		setLoading(true);
		const {
			application_name = '',
			application_basic_name = '',
			login_page_uid = '',
		} = values;
		let data: any = {
			application_name,
			application_basic_name,
			login_page_uid,
			is_active: 1,
			created_by: currentUser,
			modified_by: currentUser,
		};
		if (isEdit) {
			data = {...data, application_uid: appID};
		} else {
			data = {
				...data,
				application_uid: uuid(),
				application_layout: JSON.stringify({}),
			};
		}

		updateAPI('/apps', {data}).then(res => {
			if (res.data.success) {
				fetchAppData();
				message.success('App saved successfully');
			} else {
				message.error('Something went wrnog while saving App');
			}

			setIsEdit(false);
			setIsModalOpen(false);
			setLoading(false);
			applications.resetFields();
		}).catch(() => {
			message.error('Something went wrong');
			setIsEdit(false);
			setIsModalOpen(false);
			setLoading(false);
			applications.resetFields();
		});
	};

	useEffect(() => {
		setFullScreen(true);
		fetchAppData();
	}, []);
	const dataSource = appData;
	const columns:any = [
		{
			title: 'App Name',
			dataIndex: 'applicationName',
			width: '30%',
		},
		{
			title: 'Basic Name',
			dataIndex: 'applicationBasicName',
			width: '30%',
		},
		{
			title: 'Layout',
			dataIndex: '',
			width: '6%',
			render: (app: {applicationUid: string}) =>
				<LayoutOutlined
					key={app.applicationUid}
					onClick={() => handleLayout(app.applicationUid)}/>,
		},
		{
			title: 'Edit',
			dataIndex: '',
			width: '15%',
			render: (app: any) => (
				<EditOutlined onClick={() => {
					fetchPageData(app);
				}} />),
		},
		{
			title: 'Delete',
			dataIndex: '',
			width: '15%',
			render: (app: {applicationUid: string}) => (
				<Popconfirm
					title='Are you sure to delete this app?'
					onConfirm={() => {
						deleteApp(app.applicationUid);
					}}
					onCancel={() => null}
					okText='Yes'
					cancelText='No'
				>
					<DeleteOutlined key={app.applicationUid} />
				</Popconfirm>),
		},
	];
	return (
		<Row>
			<Col span={24}>
				<Button
					type='primary'
					icon={<PlusOutlined />}
					size='large'
					onClick={() => {
						setIsModalOpen(true);
						setIsEdit(false);
					}}
				>
                    Create App
				</Button>
			</Col>
			<Col span={24} style={{marginTop: '10px'}}>
				<Table
					loading={loading}
					dataSource={dataSource}
					columns={columns}
				/>
			</Col>
			<Modal
				title={isEdit ? 'Update App' : 'Create App'}
				open={isModalOpen}
				onCancel={() => {
					setIsModalOpen(false);
					applications.resetFields();
				}}
				footer={false}
			>
				<Col span={24}>
					<Form
						name='applications'
						autoComplete='off'
						layout='vertical'
						form={applications}
						onFinish={onFinish}
					>
						<Form.Item
							label='Application Name'
							name={'application_name'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please Enter App Name!' }]}
						>
							<Input placeholder='Enter App Name' />
						</Form.Item>
						<Form.Item
							label='Application Basic Name'
							name={'application_basic_name'}
							// eslint-disable-next-line max-len
							rules={[{ required: true, message: 'Please input Application Basic Name!' }]}
						>
							<Input placeholder='Enter App Basic Name' />
						</Form.Item>
						<Form.Item
							label='Login Page'
							name={'login_page_uid'}
						>
							<Select options={pages} />
						</Form.Item>
						<Form.Item>
							<Col span={24}>
								<Row style={{
									justifyContent: 'end', gap: '10px'}}>
									<Button type='default'
										onClick={() => {
											setIsModalOpen(false);
											applications.resetFields();
										}}
									>
										Cancel
									</Button>
									<Button type='primary' htmlType='submit'>
										Submit
									</Button>
								</Row>
							</Col>
						</Form.Item>
					</Form>
				</Col>
			</Modal>
		</Row>
	);
};

export default CreateApp;

/* eslint-disable max-len */
/* eslint-disable complexity */
import {
	Col,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Switch,
} from 'antd';
import { get, isEmpty } from 'lodash';
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
	FC,
} from 'react';
import Icon from '../icons/get-icon';
import {Icons} from '../icons/icons-list';
import { SketchPicker } from 'react-color';
import ColAction from './properties/action/action';
import ButtonProperties from './properties/button-properties';
import CheckBoxProperties from './properties/checkbox-properties';
import CollapseProperties from './properties/collapse-properties';
import CommonProperties from './properties/common/common-properties';
import DateProperties from './properties/date-properties';
import DropDownProperties from './properties/drop-down-properties';
import FileUploadProperties from './properties/file-upload-properties';
import ModalProperties from './properties/modal-properties';
import NumberProperties from './properties/number-properties';
import RadioProperties from './properties/radio-properties';
import SwitchProperties from './properties/switch-properties';
import TableProperties from './properties/table-properties';
import TextAreaProperties from './properties/text-area-properties';
import TextProperties from './properties/text-properties';
import ImagePropeties from './properties/image-properties';
import { title_style as titleStyle } from '../properties-obj/properties-obj';
import './style.css';
import LabelStyle from './properties/label/label';
import StepsProperites from './properties/steps-propertie';

type SettingProps = {
	value: any
	sectionValues: any;
	activeElement: any;
	propertiesType: string;
	pageRoute: string;
	isCustomComponent: boolean,
	componentName: string;
	componentIcon: string;
	setSectionValues: Dispatch<SetStateAction<any>>;
	setComponentName: Dispatch<SetStateAction<any>>;
	setComponentIcon: Dispatch<SetStateAction<any>>;
}

const Settings: FC<SettingProps> = (
	{
		value,
		sectionValues,
		activeElement,
		propertiesType,
		pageRoute,
		isCustomComponent,
		componentName,
		componentIcon,
		setSectionValues,
		setComponentName,
		setComponentIcon,
	},
) => {
	const { section_properties: {title = '', data_source = '', where = ''} } = value;
	const { title_style } = title;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [titleColor, setTitleColor] = useState(title_style.color);
	const [changeType, setChangeType] = useState('');
	const [section_title, setSectionTitle] = useState(title.section_title);
	const [fontSize, setFontSize] = useState(title_style.fontSize);
	const [textAlign, setTextAlign] = useState(title_style.textAlign);
	const [fontWeight, setFontWeight] = useState(title_style.fontWeight);
	const [fontStyle, setFontStyle] = useState(title_style.fontStyle);
	const [showTitle, setshowTitle] = useState(title.show_title);
	const [sectionDataSource, setSectionDataSource] = useState(data_source);
	const [sectionWhere, setSectionWhere] = useState(where);

	const handleChange = (value: string | boolean | number, type: string): void => {
		setChangeType(type);
		// eslint-disable-next-line default-case
		switch (type) {
			case 'show_title':
				setshowTitle(value);
				break;
			case 'data_source':
				setSectionDataSource(value);
				break;
			case 'where':
				setSectionWhere(value);
				break;
			case 'section_title':
				setSectionTitle(value);
				break;
			case 'fontSize':
				setFontSize(value);
				break;
			case 'fontStyle':
				setFontStyle(value);
				break;
			case 'fontWeight':
				setFontWeight(value);
				break;
			case 'textAlign':
				setTextAlign(value);
				break;
			case 'componentName':
				setComponentName(value);
				break;
			case 'icon':
				setComponentIcon(value);
				break;
		}
	};

	useEffect(() => {
		if ((propertiesType === 'section' && isEmpty(activeElement.element.row))) {
			return message.error('Please Add Row');
		}

		const color = titleColor;
		const section_obj: any = {
			color,
			fontSize,
			textAlign,
			fontWeight,
			fontStyle,
			show_title: showTitle,
			section_title,
			data_source: sectionDataSource,
			where: sectionWhere,
		};
		if (changeType) {
			setSectionValues(
				sectionValues.map((sec: any) => {
					if (sec.section_uid === value.section_uid) {
						if (changeType === 'color' || changeType === 'fontSize'
						|| changeType === 'textAlign' || changeType === 'fontWeight'
						|| changeType === 'fontStyle') {
							sec.section_properties.title.title_style[changeType] = section_obj[changeType];
						} else if (changeType === 'data_source' || changeType === 'where') {
							sec.section_properties[changeType] = section_obj[changeType];
						} else if (changeType === 'show_title' || changeType === 'section_title') {
							if (changeType === 'show_title') {
								sec.section_properties.title[changeType] = section_obj[changeType];
								sec.section_properties.title.title_style = {...sec.section_properties.title.title_style, ...titleStyle};
							} else {
								sec.section_properties.title[changeType] = section_obj[changeType];
							}
						}
					}

					return sec;
				}),
			);
		}
	}, [
		titleColor,
		fontSize,
		textAlign,
		fontStyle,
		fontWeight,
		fontStyle,
		section_title,
		showTitle,
		sectionDataSource,
		sectionWhere,
	]);

	return (
		<div>
			{
				isCustomComponent && (
					<table>
						<thead>
							<tr>
								<th colSpan={2}> Custom Component</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Component Name</td>
								<td>
									<Input
										value = {componentName}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'componentName')}/>
								</td>
							</tr>
							<tr>
								<td>Icons</td>
								<td>
									<Select
										style={{width: '100%'}}
										onChange={(value: string) => handleChange(value, 'icon')}
										value={componentIcon}
										showSearch
									>
										{Icons.map(
											(item: string, index: number) => (
												<Select.Option
													key={index}
													value={item}
												>
													<div
														style={{
															display: 'flex',
															justifyContent:
															'center',
															alignItems:
															'center',
															height: '100%',
														}}>
														<Icon type={item} />
													</div>
												</Select.Option>
											))}
									</Select>
								</td>
							</tr>
						</tbody>
					</table>
				)}
			{
				(propertiesType === 'section' && !isEmpty(activeElement) && activeElement.element.row) && (
					<Row>
						<Col span={24} className='elements'>
							<Col span={24}>
								<Col span={24}>
									<Col>
										<h3
											style={{
												padding: '5px 10px',
												textAlign: 'center',
												textTransform: 'capitalize',
											}}
										>
											Section Properties
										</h3>
									</Col>
									<Col span={24}>
										<Col span={24}>
											<table>
												<tbody>
													<tr>
														<td colSpan={2} style={{padding: '0'}}>
															<thead>
																<tr>
																	<th colSpan={2}>
																		<p>Data Source</p>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td colSpan={2}>
																		<Input
																			placeholder='Data Source....'
																			value={sectionDataSource}
																			onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'data_source')}
																		/>
																	</td>
																</tr>
															</tbody>
														</td>
													</tr>
													<tr>
														<td>Where</td>
														<td>
															<Input
																placeholder='Condition....'
																value={sectionWhere}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'where')}
															/>
														</td>
													</tr>
													<tr>
														<td>Show Title</td>
														<td>
															<Switch
																checked={
																	showTitle
																}
																onChange={(value: boolean) => handleChange(value, 'show_title')}
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</Col>
										{showTitle && (
											<Col span={24}>
												<table>
													<thead>
														<tr>
															<th colSpan={2}>Section Title</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Title</td>
															<td><Input
																placeholder='Enter Title..'
																value={section_title}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'section_title')}
															/>
															</td>
														</tr>
														<tr>
															<td>Color</td>
															<td>
																<Input
																	className='color-picker'
																	maxLength={7}
																	value={titleColor}
																	style={{ width: '100%' }}
																	placeholder='#ffffff'
																	onClick={() => {
																		setChangeType('color');
																		setIsModalVisible(true);
																	}}
																/>
															</td>
														</tr>
														<tr>
															<td>Align</td>
															<td>
																<Select
																	style={{ width: '100%' }}
																	value={textAlign}
																	onChange={(value: string) => handleChange(value, 'textAlign')}
																	options={[
																		{
																			value: 'left',
																			label: 'Left',
																		},
																		{
																			value: 'right',
																			label: 'Right',
																		},
																		{
																			value: 'center',
																			label: 'Center',
																		},
																		{
																			value: 'justify',
																			label: 'Justify',
																		},
																	]}
																/>
															</td>
														</tr>
														<tr>
															<td>Font Size</td>
															<td>
																<InputNumber
																	value={fontSize}
																	min={1}
																	onChange={(value: number | null) => value && handleChange(value, 'fontSize')}
																/>
															</td>
														</tr>
														<tr>
															<td>Font Style</td>
															<td>
																<Select
																	style={{ width: '100%' }}
																	value={fontStyle}
																	onChange={(value: string) => handleChange(value, 'fontStyle')}
																	options={[
																		{
																			value: 'normal',
																			label: 'Normal',
																		},
																		{
																			value: 'italic',
																			label: 'Italic',
																		},
																	]}
																/>
															</td>
														</tr>
														<tr>
															<td>Font Weight</td>
															<td>
																<Select
																	style={{ width: '100%' }}
																	value={fontWeight}
																	onChange={(value: string) => handleChange(value, 'fontWeight')}
																	options={[
																		{
																			value: '300',
																			label: '300',
																		},
																		{
																			value: '400',
																			label: '400',
																		},
																		{
																			value: '500',
																			label: '500',
																		},
																		{
																			value: '600',
																			label: '600',
																		},
																		{
																			value: '700',
																			label: '700',
																		},
																		{
																			value: '800',
																			label: '800',
																		},
																	]}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</Col>
										)}
									</Col>
									<Col span={24}>
										<CommonProperties
											key={`section_${activeElement.element.column_uid}`}
											setSectionValues={setSectionValues}
											activeElement={activeElement}
											sectionValues={sectionValues}
											type={propertiesType}
										/>
									</Col>
								</Col>
							</Col>
						</Col>
					</Row>
				)
			}
			{
				(propertiesType === 'row' && activeElement.element_type === 'row') && (
					<div className='elements'>
						<Row>
							<Col span={24}>
								<h3 style={{
									padding: '5px 10px',
									textAlign: 'center',
									textTransform: 'capitalize',
								}}>Row Properties</h3>
							</Col>
							<Col span={24}>
								<CommonProperties
									key={`row_${activeElement.element.column_uid}`}
									setSectionValues={setSectionValues}
									activeElement={activeElement}
									sectionValues={sectionValues}
									type={propertiesType}
								/>
							</Col>
						</Row>
					</div>
				)
			}
			{
				(propertiesType === 'column' && activeElement.element_type === 'column') && (
					<>
						{
							<div
								className='elements'
								key={activeElement.element.column_uid}
							>
								<Row>
									<Col span={24}>
										<h3 style={{
											padding: '5px 10px',
											textAlign: 'center',
											textTransform: 'capitalize',
										}}>Column Properties</h3>
									</Col>
								</Row>
								<CommonProperties
									key={`column_${activeElement.element.column_uid}`}
									setSectionValues={setSectionValues}
									activeElement={activeElement}
									sectionValues={sectionValues}
									type={propertiesType}
								/>
								<ColAction
									key={`col_action_${activeElement.element.column_uid}`}
									setSectionValues={setSectionValues}
									activeElement={activeElement}
									sectionValues={sectionValues}
								/>
								{/* <Popover
									key={`popover_${activeElement.element.column_uid}`}
									setSectionValues={setSectionValues}
									activeElement={activeElement}
									sectionValues={sectionValues}
								/> */}
								{activeElement.element.widget_type === 'label' && (
									<LabelStyle
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'text' && (
									<TextProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'textArea' && (
									<TextAreaProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'number' && (
									<NumberProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'date' && (
									<DateProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'dropdown' && (
									<DropDownProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'table' && (
									<TableProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
										pageRoute={pageRoute}
									/>
								)}
								{activeElement.element.widget_type === 'radio' && (
									<RadioProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'toggle' && (
									<SwitchProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'checkbox' && (
									<CheckBoxProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'button' && (
									<ButtonProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
										pageRoute={pageRoute}
									/>
								)}
								{activeElement.element.widget_type === 'upload' && (
									<FileUploadProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'collapse' && (
									<CollapseProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'modal' && (
									<ModalProperties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'image' && (
									<ImagePropeties
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
								{activeElement.element.widget_type === 'flow' && (
									<StepsProperites
										key={activeElement.element.column_uid}
										setSectionValues={setSectionValues}
										activeElement={activeElement}
										sectionValues={sectionValues}
									/>
								)}
							</div>
						}
					</>
				)
			}
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={titleColor}
					onChange={event => setTitleColor(get(event, 'hex', ''))}
				/>
			</Modal>
		</div>
	);
};

export default Settings;

import {Col, InputNumber} from 'antd';
import React, { FC } from 'react';

type NumberCompProperties = {
	properties: any;
	dataSource: any;
}

const NumberComp: FC<NumberCompProperties> = ({properties}) => {
	const {label, number} = properties.column_properties;
	const {max, min, placeholder, step} = number;
	const style = label.label_style;
	return (
		<Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{...style}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<InputNumber
				placeholder={placeholder}
				max={max}
				min={min}
				step={
					step.includes('.')
						? parseFloat(step)
						: parseInt(step, 10)
				}
			/>
		</Col>
	);
};

export default NumberComp;

/* eslint-disable max-len */
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../icons/logo.svg';
import klLogo from '../icons/kl.png';
import { get } from 'lodash';
import './style.css';
import { profileTrackerRoute } from '../Navbar/Topbar/Topbar';
import { updateAPI } from '../api/api';
import { notification } from 'antd';

interface iProps {
	appName: { name: any, url: any }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Login = (props: iProps) => {
	const navigate = useNavigate();
	const { appName } = props;
	const [value, setValue] = useState({
		email: '',
		password: '',
	});
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		document.title = appName.name || 'Profile Tracker';
	}, [appName]);
	const handleChange = (event: any): void => {
		setValue({ ...value, [event.target.name]: event.target.value });
	};

	const handleSubmit = (e: React.FormEvent): void => {
		e.preventDefault();
		setLoading(true);
		updateAPI('/login', value)
			.then(result => {
				if (result.data.success) {
					const { data } = result;
					const permissionData = get(data, 'data');
					const modifiedPermissions = permissionData.permissions.map((item: any) => ({ [item.module_name]: { ...item } }));
					localStorage.setItem('token', get(data, 'data.token'));
					localStorage.setItem('user', value.email);
					localStorage.setItem('permissions', JSON.stringify(modifiedPermissions));
					const lastLocation = localStorage.getItem('location');
					notification.success({ message: 'Login success', placement: 'topRight' });
					setTimeout(() => {
						if (lastLocation) {
							navigate(lastLocation);
						} else {
							localStorage.setItem('userLoginId', data.data.id);
							navigate(`${appName.url}`);
						}

						window.location.reload();
					}, 500);
				} else {
					notification.error({ message: result.data.message, placement: 'topRight' });
				}

				setLoading(false);
			})
			.catch((error: any) => {
				notification.error({ message: error.message, placement: 'topRight' });
				setLoading(false);
			});
	};

	return (
		<div>
			<div className='wrapper fadeInDown'>
				<div id='formContent'>
					<h2 className='active-login'> Sign In </h2>
					<div className='fadeIn first' style={{ height: '77px', margin: 'auto' }}>
						<img
							src={profileTrackerRoute ? klLogo : logo} alt='KL Forms' style={{ height: '100%', width: profileTrackerRoute ? 'auto' : '100%' }} />
					</div>
					<div>
						<h2>{appName.name}</h2>
					</div>
					<form
						style={
							{ display: 'flex', flexDirection: 'column', gap: '1.2rem', alignItems: 'center' }}
						onSubmit={handleSubmit}
					>
						<input
							id='login'
							className='fadeIn second'
							onChange={
								(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
							name='email'
							type='email'
							placeholder='Email'
						/>
						<input
							name='password'
							type='password'
							onChange={
								(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
							className='fadeIn third'
							placeholder='password'
						/>
						<input
							type='submit'
							className='fadeIn fourth cursor-pointer'
							value='Log In'
							disabled={loading}
						/>
					</form>
				</div>
			</div>

		</div>
	);
};

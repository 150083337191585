/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Topbar from './Navbar/Topbar/Topbar';
import { Lookup } from './lookup/lookup';
import { FormsList } from './forms/form-list';
import { UserForm } from './User/form';
import { Login } from './login/Index';
import Success from './success';
import PageCreator from './components/page-creator';
import { ProfileTracker } from './profile-tracker';
import { ProfileDisplay } from './profile-tracker/detail';
import LayoutCreator from './components/layout/layout';
import CreatePage from './components/pages/create-pages';
import { Col, ConfigProvider, message, Row } from 'antd';
import MenuCreator from './components/menu/menu-creator';
import LayoutPreview from './components/layout/preview';
import { getPermission } from './shared/permissions';
import AppsAndWorkFLowCreator from './components/app';
import CreateWorkFLowActions from './components/app/workflow/create-workflow-actions';
import { getAPI } from './api/api';
import { getHostName } from './shared/formatting';
import { AppsList } from './apps-list';

export interface IAppProps { }

const token = localStorage.getItem('token');

const App: React.FunctionComponent<IAppProps> = () => {
	const [fullScreen, setFullScreen] = useState(true);
	const [themeColor, setThemeColor] = useState('');
	const permissions = getPermission('UsersList');
	const [appName, setAppName] = useState({ name: '', url: '' });
	const { is_read } = permissions;

	useEffect(() => {
		let timeoutId: any;

		const logout = () => {
			localStorage.clear();
			window.location.reload();
			window.history.pushState({ urlPath: '/' }, '', '/');
		};

		const resetTimer = () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			timeoutId = setTimeout(() => {
				logout();
			}, 2700000);
		};

		const handleUserActivity = () => {
			resetTimer();
		};

		resetTimer();

		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);

		return () => {
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
			clearTimeout(timeoutId);
		};
	}, []);

	useEffect(() => {
		if (appName.url) {
			if (token && window.location.pathname === '/') {
				window.history.pushState({ urlPath: `/${appName.url}` },
					'',
					`/${appName.url}`);
				window.location.reload();
			} else if (!token && window.location.pathname !== '/') {
				window.history.pushState({ urlPath: '/' }, '', '/');
				window.location.reload();
			}
		}
	}, [appName]);

	const getAllApps = async (value: string) => {
		try {
			const response = await getAPI(`/apps/get-app/${value}`);
			const result = response.data;
			const [{ name, description }] = result.data;
			return result.data.length ? { name, description } : {};
		} catch (error: any) {
			message.error(error.message);
		}
	};

	useEffect(() => {
		(async () => {
			if (window.location.hostname.includes('localhost')) {
				const { name = '', description = '' }: any = await getAllApps('devops');
				setAppName({ name: description, url: name });
			} else {
				const value = getHostName(window.location.origin);
				const { name = '', description = '' }: any = await getAllApps(value);
				setAppName({ name: description, url: name });
			}
		})();
	}, []);
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: themeColor || '#1677ff',
				},
			}}
		>
			<BrowserRouter>
				{token
					? (
						<>
							<Row justify={'center'}>
								<Col md={fullScreen ? 24 : 20} xs={24}>
									<Col xs={24}>
										<Topbar />
										<Routes>
											<Route path='lookup' element={<Lookup />} />
											<Route path='forms' element={<FormsList />} />
											<Route path='forms/:uid' element={<UserForm />} />
											{is_read && <Route path='profile-tracker' element={<ProfileTracker setFullScreen={setFullScreen} />} />}
											<Route path='profile-tracker/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='asset-tracker' element={<ProfileTracker setFullScreen={setFullScreen} />} />
											<Route path='asset-tracker/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='tfw-asset-tracker' element={<ProfileTracker setFullScreen={setFullScreen} />} />
											<Route path='tfw-asset-tracker/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='devops-service-request' element={<ProfileTracker setFullScreen={setFullScreen} />} />
											<Route path='leave-tracker/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='leave-tracker' element={<ProfileTracker setFullScreen={setFullScreen} />}/>
											<Route path='wfh-tracker/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='wfh-tracker' element={<ProfileTracker setFullScreen={setFullScreen} />}/>
											<Route path='service-management' element={<ProfileTracker setFullScreen={setFullScreen} />} />
											<Route path='service-management/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='devops-service-request/:uid' element={<ProfileDisplay setFullScreen={setFullScreen} />} />
											<Route path='apps' element={<AppsAndWorkFLowCreator setFullScreen={setFullScreen} setThemeColor={setThemeColor} />} />
											<Route path='workflow' element={<AppsAndWorkFLowCreator setFullScreen={setFullScreen} setThemeColor={setThemeColor} />} />
											<Route path='workflow_actions/:workflow_id' element={<CreateWorkFLowActions setFullScreen={setFullScreen} setThemeColor={setThemeColor} />} />
											<Route path='pages/:application_uid' element={<CreatePage setFullScreen={setFullScreen} />} />
											<Route path='pages' element={<CreatePage setFullScreen={setFullScreen} />} />
											<Route path='page_creator/:page_uid' element={<PageCreator setFullScreen={setFullScreen} />} />
											<Route path='menus/:application_uid' element={<MenuCreator setFullScreen={setFullScreen} setThemeColor={setThemeColor} />} />
											<Route path='layout/:application_uid' element={<LayoutCreator />} />
											<Route path='layout/:layout_type/:application_uid' element={<LayoutPreview />} />
											<Route path='success' element={<Success />} />
											<Route path='apps-list' element={<AppsList />} />
										</Routes>
									</Col>
								</Col>
							</Row>
						</>
					)
					: (
						<Routes>
							<Route path='forms/:uid' element={<UserForm />} />
							<Route path='/' element={<Login appName={appName} />} />
							<Route path='/success' element={<Success />} />
						</Routes>
					)
				}
			</BrowserRouter>
		</ConfigProvider>
	);
};

export default App;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { find } from 'lodash';

export const safeJSONParse = (input: any, defaultVal = {}) => {
	let json;
	if (input !== null && typeof input === 'object') {
		return input;
	}

	try {
		json = JSON.parse(input);
	} catch {
		json = defaultVal;
	}

	return json || defaultVal;
};

export const safeJSONStringify = (input: any, defaultVal = '') => {
	let json;
	if (typeof input === 'string') {
		return input;
	}

	try {
		json = JSON.stringify(input);
	} catch {
		json = undefined;
	}

	return json || defaultVal || '';
};

export const getPath = (value: String) => {
	const pathSplit = value.split('/');
	const path = find(pathSplit, 1);
	return path ? path : '/';
};

export const getHostName = (value: string) => {
	const val = value.split('/')[2];
	const checkWords = ['dev'];
	const val2 = val.split('.');
	const data = val2[0].split('-')
		.filter(word => !checkWords.includes(word)).join('-');
	return data;
};

export default {};

import {Input} from 'antd';
import React, {Dispatch, SetStateAction, useEffect, useState, FC} from 'react';

type LabelNameProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelName: FC<LabelNameProps> = ({
	activeElement,
	setChangeType,
	setValue,
}) => {
	const {label_name = ''} = activeElement.element.column_properties.label;
	const [labelName, setLabelName] = useState(label_name);
	const handleLabelName = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setLabelName(e.target.value);
	};

	useEffect(() => {
		setChangeType('label_name');
		setValue(labelName);
	}, [labelName]);

	return (
		<div>
			<Input
				value={labelName}
				placeholder='Enter label....'
				onChange={handleLabelName}
			/>
		</div>
	);
};

export default LabelName;

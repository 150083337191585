import { Col, Table } from 'antd';
import React, {FC} from 'react';

type TableCompProps = {
	properties: any;
	dataSource: any;
}

const TableComp: FC<TableCompProps> = ({ properties }) => {
	const {
		label,
		table,
	} = properties.column_properties;
	const style = label.label_style;
	const { columns } = table;

	return (
		<Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
			{
				label.show_label && (
					<label
						htmlFor={label.label_name}
						style={{
							...style,
							textTransform: 'capitalize',
						}}
					>
						{label.label_name || 'Title'}
					</label>
				)
			}
			<Col span={24}>
				<Table
					dataSource={[]}
					columns={columns}
					scroll={{x: true}}
					pagination={{
						position: [],
						defaultPageSize: undefined,
						pageSizeOptions: [],
					}}
				/>
			</Col>
		</Col>
	);
};

export default TableComp;


import {Input, Modal} from 'antd';
import {get} from 'lodash';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';
import {SketchPicker} from 'react-color';

type BackgroundColorProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const BackgroundColor:FC<BackgroundColorProps> = (
	{
		setChangeType,
		setValue,
		activeElement,
		type,
	}) => {
	const {backgroundColor = ''} = activeElement.element[`${type}_style`];
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [bgColor, setBgColor] = useState(backgroundColor);
	useEffect(() => {
		setChangeType('backgroundColor');
		setValue(bgColor);
	}, [bgColor]);

	return (
		<table>
			<tbody>
				<tr>
					<td>Background Color</td>
					<td>
						<Input
							className='color-picker'
							placeholder='#000000'
							value={bgColor}
							onClick={() => {
								setIsModalVisible(true);
							}}
						/>
					</td>
				</tr>
			</tbody>
			<Modal
				title='Color Picker'
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => {
					setIsModalVisible(false);
					setBgColor('');
				}}
				width='269px'
				destroyOnClose
			>
				<SketchPicker
					color={bgColor}
					onChange={
						event => {
							setBgColor(get(event, 'hex', ''));
						}
					}
				/>
			</Modal>
		</table>
	);
};

export default BackgroundColor;

import { Col, Collapse } from 'antd';
import React, { Dispatch, SetStateAction, FC } from 'react';
import EditorRow from '../editor/editor-row';
import { isEmpty } from 'lodash';

type CollapseCompProps = {
    col: any;
    getActiveRowColumn: any;
    addRowColumn: any;
	deleteColumn: any;
	deleteRow: any;
	dataSource: any;
	setPropertiesType: Dispatch<SetStateAction<string>>;
}

const { Panel } = Collapse;
const CollapseComp: FC <CollapseCompProps> = ({
	col,
	getActiveRowColumn,
	deleteColumn,
	deleteRow,
	addRowColumn,
	dataSource,
	setPropertiesType,
}) => {
	const {collapse: {
		row = [],
		panel = [],
		accordion = false,
	}} = col.column_properties;

	return (
		<Col span={24}>
			<Collapse accordion={accordion}>
				{
					(!isEmpty(panel) && !isEmpty(row)) ? (
						panel.map((item: any, index: number) => (
							<Panel
							// eslint-disable-next-line max-len
								header={`${item.header.header_text || 'This is panel header'}`}
								key={item.panel_uid}
								style={{...item.header.header_style,
									...item.panel_style,
								}}
							>
								{
									<EditorRow
										key={index}
										row={row[index]}
										getActiveRowColumn={
											getActiveRowColumn
										}
										indexRow={index}
										dataSource={dataSource}
										deleteColumn={deleteColumn}
										deleteRow={deleteRow}
										addRowColumn={addRowColumn}
										setPropertiesType={setPropertiesType}
									/>
								}
							</Panel>
						))

					) : (<Panel key={''} header={undefined}></Panel>)
				}
			</Collapse>
		</Col>
	);
};

export default CollapseComp;

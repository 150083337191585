
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import { useEffect } from 'react';
import { camelCase, capitalCase } from 'text-case';
import { AttachFile } from '@mui/icons-material';
import {
	FormGroup, FormLabel, Grid, IconButton,
	Card, CardContent, Select, MenuItem, SelectChangeEvent, TextField,
	Radio,
	RadioGroup,
	FormControlLabel,
} from '@mui/material';
import TextFieldComp from '../shared/components/textfield/textfield';
import { getPermission } from '../shared/permissions';
import { get } from 'lodash';
import { lookupActionResponse } from '../api/lookup-response';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const permissions = getPermission('UsersList');
const { is_update } = permissions;

export const DetailsForm = (props: any) => {
	const { form = [], data, setData, setForm } = props;

	useEffect(() => {
		const args = {
			form: form,
			setform: setForm,
		};
		lookupActionResponse(args);
	}, []);

	const handleKeyDown = (event: any) => {
		const { key } = event;
		const validChars = /^[\w\s@.-]+$/;

		if (!validChars.test(key)) {
			event.preventDefault();
		}
	};

	const handleOnChange = (e: any): void => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.currentTarget.value;
		const newFormData = { ...data };
		newFormData[fieldName] = fieldValue;
		setData(newFormData);
	};

	const handleFileChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const { files }: any = e.currentTarget;
		const fieldName = e.currentTarget.name;
		const newFormData = { ...data };
		newFormData[fieldName] = files[0];
		setData(newFormData);
	};

	const handleSelectChange = (e: SelectChangeEvent): void => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value;
		const newFormData = { ...data };
		newFormData[fieldName] = fieldValue;

		setData(newFormData);
	};

	const handleDateChange = (e: any, name: string) => {
		const date = moment(get(e, '$d')).format('YYYY-MM-DD');
		setData({ ...data, [name]: date });
	};

	const getAttachment = () => {
		const formFilter = form.filter((item: any) => item.fieldType === 'fileupload');
		const fileData = formFilter ? data[camelCase(get(formFilter, '[0].fieldName', ''))] : '';
		const fileName = fileData ? typeof fileData === 'string' ? fileData.split('/')[fileData.split('/').length - 1] : get(fileData, 'name', '') : '';
		return fileName;
	};

	const name = get(data, 'name', '');

	return (
		<>
			{name && (
				<h2 className='edit-name'>{name}</h2>
			)}
			{form.map((item: any, i: number) => (
				<Grid container key={i} className='Profile Tracker'>
					{(item.fieldProps.type === 'number'
						|| item.fieldProps.type === 'tel')
						? (
							<Grid item xs={12} className='Profile-tracker input-scroll' sx={{ paddingBottom: 2 }}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)}
										className={item.fieldProps.required ? 'control-label' : ''}>
										{capitalCase(item.fieldName)}</FormLabel>
									<TextFieldComp {...item.fieldProps} name={camelCase(item.fieldName)}
										disabled={!parseInt(is_update, 10)} placeholder={`Enter ${item.fieldName}`} value={data[camelCase(item.fieldName)]} onChange={handleOnChange} InputProps={{
											style: {
												height: '45px',
												paddingBottom: 0,
											},
										}} />
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'text' || item.fieldProps.type === 'email')
						? (
							<Grid item xs={12} className='Profile-tracker' sx={{ paddingBottom: 2 }}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)}
										className={item.fieldProps.required ? 'control-label' : ''}>
										{capitalCase(item.fieldName)}</FormLabel>
									<TextFieldComp {...item.fieldProps} name={camelCase(item.fieldName)}
										disabled={item.fieldName === 'Asset Number' ? true : !parseInt(is_update, 10)} placeholder={`Enter ${item.fieldName}`} value={data[camelCase(item.fieldName)]} onChange={handleOnChange} InputProps={{
											style: {
												height: '45px',
												paddingBottom: 0,
											},
										}} onKeyDown={handleKeyDown} />
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'select')
						? (<Grid item xs={12} className='Profile-tracker' sx={{ paddingBottom: 2 }}>
							<FormGroup>
								<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
								<Select name={camelCase(item.fieldName)} onChange={handleSelectChange} className='dropDown' placeholder={`Enter ${item.fieldName}`}
									value={String(data[camelCase(item.fieldName)])} >
									{(item.value || []).map((val: any) => (
										// eslint-disable-next-line react/jsx-key
										<MenuItem value={val} >{val}</MenuItem>
									))}
								</Select>
								{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
							</FormGroup>
						</Grid>
						)
						: null}
					{(item.fieldProps.type === 'date')
						? (
							<Grid item xs={12} className='Profile-tracker' sx={{ paddingBottom: 2 }}>
								<FormGroup>
									<FormLabel htmlFor={camelCase(item.fieldName)}
										className={item.fieldProps.required ? 'control-label' : ''}>
										{capitalCase(item.fieldName)}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker label='' value={get(data, `${camelCase(item.fieldName)}`, null)} onChange={(e: any) => handleDateChange(e, camelCase(item.fieldName))}
											className='form-group-input'
											{...item.fieldProps}
											disabled={!parseInt(is_update, 10)}
											renderInput={params => <TextField {...params} error={false}/>} />
									</LocalizationProvider>
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'radio')
						? (<Grid item xs={12}>
							<FormGroup style={{paddingBottom: '11px'}}>
								<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
								<RadioGroup {...item.fieldProps} row name={camelCase(item.fieldName)} onChange={handleOnChange} value={get(data, `${camelCase(item.fieldName)}`, null)}
									className='radio-group'>
									{item.options.map((val: any) => (
										<>
											<FormControlLabel name={camelCase(item.fieldName)} control={<Radio />} label={val.name} value={val.value}/>
										</>
									))}
								</RadioGroup>
								{item.fieldProps.helperText && <p className='error-label'>{item.fieldProps.helperText}</p>}
							</FormGroup>
						</Grid>
						)
						: null}
					{(item.fieldProps.type === 'textarea')
						? (
							<Grid item xs={12} key={item.key}>
								<FormGroup>
									<FormLabel className={item.fieldProps.required ? 'control-label' : ''} htmlFor={camelCase(item.fieldName)}>{item.fieldName}</FormLabel>
									<TextFieldComp {...item.fieldProps} name={camelCase(item.fieldName)} placeholder={`Enter ${item.fieldName}`} multiline={true} minRows={3} onChange={handleOnChange}
										value={data[camelCase(item.fieldName)]}/>
								</FormGroup>
							</Grid>
						) : null}
					{(item.fieldProps.type === 'fileupload')
						? (<Grid item xs={12}>
							<Card key={item.key}>
								<CardContent>
									<FormLabel htmlFor={camelCase(item.fieldName)} className={item.fieldProps.required ? 'control-label' : ''}>{capitalCase(item.fieldName)}</FormLabel>
									<label htmlFor='icon-button-file'>
										{is_update ? <input className='display-none' name={camelCase(item.fieldName)} onChange={handleFileChange} accept='*' id='icon-button-file' type='file' /> : ''}
										<IconButton color='primary' aria-label='upload picture' component='span'>
											<AttachFile />
										</IconButton>
										<a href={data[camelCase(item.fieldName)]} target='_blank' rel='noreferrer'>{getAttachment()}</a>
									</label>
								</CardContent>
							</Card>
						</Grid>
						)
						: null}
				</Grid>
			))
			}
		</>
	);
};


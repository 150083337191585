/* eslint-disable max-len */
import { DialogContent, CardContent } from '@mui/material';
import { Card } from '@mui/material';
import React, { FC, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { updateAPI } from '../api/api';
import _ from 'lodash';
import { currentUser } from '../api/api';

type LaneDataProps = {
    laneInfo: any;
    profileTrackerData: any;
	path: any;
}
const LaneData: FC<LaneDataProps> = ({laneInfo, profileTrackerData, path}) => {
	const [profiles, setProfiles] = useState(profileTrackerData);
	const handleDragEnd = (result: DropResult): void => {
		const status = result.destination?.droppableId;
		const uid = result.draggableId;
		const tableName = _.snakeCase(path);
		const params = {
			uid,
			status,
			modified_by: currentUser,
			created_by: currentUser,
		};
		updateAPI(`/laneview/laneCardData?tableName=${tableName}`, params).then(res => {
			setProfiles(res.data.data);
		});
	};

	return (
		<div>
			<DialogContent sx={{ display: { xs: 'block', md: 'flex' } }}>
				<DragDropContext
					onDragEnd={e => {
						handleDragEnd(e);
					}}
				>
					{laneInfo.map((info: any, index: number): any =>
						<Droppable key={index} droppableId={info.items[0]}>
							{provided => (
								<Card {...provided.droppableProps} ref={provided.innerRef} sx={{ width: { sm: '100%', md: '35%' }, boxShadow: 3, margin: '10px', padding: '15px', border: 0, borderRadius: 1, overflow: 'auto', scrollbarColor: 'red', ScrollbarTrackColor: 'white' }} key={index}>
									<div style={{ fontWeight: 'bold', textTransform: 'capitalize', color: 'rgb(24, 144, 255)' }}>{info.name}</div>
									<div style={{ marginTop: '20px' }}>{info.items.map((laneField: any): any => {
										const filteredLaneData = profiles.filter((res: any) => res.status === laneField);
										return filteredLaneData.map((i: any, index: number) =>
											<Draggable key={i} draggableId={i.uid} index={index}>
												{provided => (
													<Card {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} key={i} sx={{ marginTop: '5px', boxShadow: 3, cursor: 'pointer' }}> {/* onClick= */}
														<CardContent sx={{ padding: '25px' }}>
															<div><h4 style={{ textTransform: 'capitalize' }}>{i.assignedTo}</h4></div>
															<div style={{ marginTop: '10px', color: '#626262', fontSize: '14px' }}>
																<p style={{ textTransform: 'capitalize' }}><i className='fa fa-file-text'/> {i.requestBrief}</p>
																<p style={{ textTransform: 'capitalize' }}><i className='fa fa-regular fa-briefcase' /> {i.projectName}</p>
																<p style={{ textTransform: 'capitalize' }}><i className='fa fa-regular fa-clock' /> {i.totalHours}</p>
															</div>
															<div style={{ marginTop: '10px', textAlign: 'center', color: 'rgb(24, 144, 255)', fontSize: '14px' }}>{i.status}</div></CardContent>
													</Card>
												)}
											</Draggable>,
										);
									})}</div>
									{provided.placeholder}
								</Card>
							)}
						</Droppable>,
					)}
				</DragDropContext>
			</DialogContent>
		</div>
	);
};

export default LaneData;

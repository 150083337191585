
/* eslint-disable max-len */
import { Col, Popover, Tooltip} from 'antd';
import React, {useState, Dispatch, SetStateAction, FC, CSSProperties} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import ButtonComp from '../editor-elements/button';
import CheckBoxComp from '../editor-elements/check-box';
import DateComp from '../editor-elements/date';
import DropdownComp from '../editor-elements/dropdown';
import NumberComp from '../editor-elements/number';
import RadioComp from '../editor-elements/radio';
import TableComp from '../editor-elements/table';
import TextComp from '../editor-elements/text';
import TextAreaComp from '../editor-elements/text-area';
import FileUploadComp from '../editor-elements/file-upload';
import './style.css';
import { CheckOutlined, CopyOutlined, DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import EditorRow from './editor-row';
import LabelComp from '../editor-elements/label';
import SwitchComp from '../editor-elements/switch';

import CollapseComp from '../editor-elements/collapse';
import ProgressComp from '../editor-elements/progress';
import ModalComp from '../editor-elements/modal';
import ImageComp from '../editor-elements/image';
import StepsComp from '../editor-elements/steps';

type EditorColumnProps = {
    col: any
    indexCol: number;
    getActiveRowColumn: any;
    addRowColumn: any;
	deleteColumn: any;
	deleteRow: any;
	dataSource: any;
	col_count: number;
	setPropertiesType: Dispatch<SetStateAction<string>>;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any): CSSProperties => ({
	userSelect: 'none',
	border: isDragging ? '2px dashed #ddd' : 'none',
	background: isDragging ? '#eee' : 'inherit',
	height: '100%',
	transition: 'background 0.2s ease-in',
	...draggableStyle,
});

const EditorColumn: FC<EditorColumnProps> = ({
	col,
	indexCol,
	deleteColumn,
	deleteRow,
	getActiveRowColumn,
	dataSource,
	addRowColumn,
	col_count,
	setPropertiesType,
}) => {
	const {
		column_style: {
			margin = '',
			padding = '',
			borderRadius = '',
			backgroundColor = '',
			width = '',
			borderWidth = '',
			borderColor = '',
			borderStyle = '',
			height = null,
		},
	} = col;
	const style = {
		margin,
		padding,
		borderRadius,
		backgroundColor,
		borderWidth,
		borderColor,
		borderStyle,
		height,
	};
	const [showIcon, setShowIcon] = useState(false);
	const uiElements = (col: any, dataSource: any): React.ReactNode => (
		<div>
			{col.widget_type === 'label' && <LabelComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'text' && <TextComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'textArea' && <TextAreaComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'number' && <NumberComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'date' && <DateComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'dropdown' && <DropdownComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'table' && <TableComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'radio' && <RadioComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'toggle' && <SwitchComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'checkbox' && <CheckBoxComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'button' && <ButtonComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'upload' && <FileUploadComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'progress' && <ProgressComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'image' && <ImageComp properties={col} dataSource={dataSource} />}
			{col.widget_type === 'flow' && <StepsComp properties={col} dataSource={dataSource} />}
		</div>
	);

	return (
		<Col
			span={width || 24 / col_count}
			style={{ ...style, height: style.height || '100%'}}
			key={col.column_uid}
			id={col.column_uid}
			onClick={(e: React.MouseEvent<HTMLElement>) => {
				e.stopPropagation();
				getActiveRowColumn(col.column_uid, 'column');
			}}
			onMouseOver = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(true);
				}
			}
			onMouseOut = {
				(event: React.MouseEvent<HTMLInputElement>) => {
					event.stopPropagation();
					setShowIcon(false);
				}
			}
		>
			<Col
				span={24}
				className={showIcon ? 'editor-column show' : 'editor-column'}
				style={{height: style.height || '100%'}}
			>
				<div
					className='icon column'
					style={{
						display: showIcon ? 'block' : 'none',
					}}
				>
					<Popover placement='top' trigger='hover' content={(
						<div style={{display: 'flex', justifyContent: 'space-between', gap: '15px', cursor: 'pointer'}}>
							<span>
								<Tooltip title='Add Row' placement='topLeft'>
									<PlusOutlined onClick={() => addRowColumn({
										element_uid: col.column_uid,
										type: 'add-row',
									})} />
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Delete Column' placement='topLeft'>
									<DeleteOutlined onClick={() => {
										deleteColumn(col.column_uid);
									}} />
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Select Column' placement='topLeft'>
									<CheckOutlined onClick={() => {
										setPropertiesType('column');
										getActiveRowColumn(col.column_uid, 'column');
									}} />
								</Tooltip>
							</span>
							<span>
								<Tooltip title='Copy Row' placement='topLeft'>
									<CopyOutlined onClick={() => addRowColumn({
										element_uid: col.column_uid,
										type: 'copy-column',
									})} />
								</Tooltip>
							</span>
						</div>
					)}>
						<MenuOutlined />
					</Popover>
				</div>
				{
					(col.row) ? (
						col.row.map((row: any, index: number) => (
							<EditorRow
								key={index}
								row={row}
								getActiveRowColumn={
									getActiveRowColumn
								}
								indexRow={index}
								dataSource={dataSource}
								deleteColumn={deleteColumn}
								deleteRow={deleteRow}
								addRowColumn={addRowColumn}
								setPropertiesType={setPropertiesType}
							/>
						))
					) : col.widget_type === 'collapse' ? (
						<CollapseComp
							col={col}
							dataSource={dataSource}
							deleteColumn={deleteColumn}
							deleteRow={deleteRow}
							addRowColumn={addRowColumn}
							setPropertiesType={setPropertiesType}
							getActiveRowColumn={getActiveRowColumn}
						/>
					) : col.widget_type === 'modal' ? (
						<ModalComp
							col={col}
							dataSource={dataSource}
							deleteColumn={deleteColumn}
							deleteRow={deleteRow}
							addRowColumn={addRowColumn}
							setPropertiesType={setPropertiesType}
							getActiveRowColumn={getActiveRowColumn}
						/>
					) : (
						<Droppable
							droppableId={col.column_uid}
							key={col.column_uid}
						>
							{provided => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									key={indexCol}
									style={{height: style.height || '100%'}}
								>
									<Draggable
										key={indexCol}
										draggableId={col.column_uid}
										index={indexCol}
										isDragDisabled
									>
										{(provided, snapshots) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className=''
												style={getItemStyle(
													snapshots.isDragging,
													provided.draggableProps.style,
												)}
												key={indexCol}
											>
												{
													<Col span={24} style={{height: style.height || '100%'}}>
														{
															<>
																{uiElements(col, dataSource)}
															</>
														}
													</Col>
												}
											</div>
										)}
									</Draggable>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					)
				}
			</Col>
		</Col>
	);
};

export default EditorColumn;

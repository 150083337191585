import {Select} from 'antd';
import React, {Dispatch, SetStateAction, useState, useEffect, FC} from 'react';

type LabelFonstTransformProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	setValue: Dispatch<SetStateAction<any>>;
	activeElement: any;
}

const LabelFonstTransform: FC<LabelFonstTransformProps> = ({
	activeElement,
	setChangeType,
	setValue,
}: LabelFonstTransformProps) => {
	const {
		fontStyle = '',
	} = activeElement.element.column_properties.label.label_style;
	const [style, setStyle] = useState(fontStyle);
	const handleChange = (value: string): void => {
		setStyle(value);
	};

	useEffect(() => {
		setChangeType('textTransform');
		setValue(style);
	}, [style]);

	return (
		<div>
			<Select
				style={{width: '100%'}}
				value={style}
				onChange={handleChange}
				options={[
					{
						value: 'capitalize',
						label: 'Capitalize',
					},
					{
						value: 'uppercase',
						label: 'Uppercase',
					},
					{
						value: 'lowercase',
						label: 'Lowercase',
					},
				]}
			/>
		</div>
	);
};

export default LabelFonstTransform;

/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {
	Breadcrumb, Layout,
	theme, Row, Image,
	message, Menu, Popover, Tooltip } from 'antd';
import { getAPI } from '../../../api/api';
import { isEmpty } from 'lodash';
import Icon from '../../../components/icons/get-icon';
import '../style.css';
import { PlusOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { SubMenu, Item } = Menu;
type LayoutProps = {
  layoutProperties: any;
  setPropertyType: Dispatch<SetStateAction<any>>;
};
const Layout1 = ({ layoutProperties, setPropertyType }: LayoutProps): any => {
	const {
		show_breadcrumb,
		logo: {	app_logo, logo_height, logo_width, logo_style },
		menu: {menu_width, menu_style},
		header: { show_header, header_height, show_header_menu },
		footer: {show_footer} } = layoutProperties;
	const { application_uid } = useParams();
	const navigate = useNavigate();
	const [menuValues, setMenuValues] = useState<any>([]);
	const [menuName, setMenuName] = useState('');
	const [menuId, setMenuId] = useState('');
	const [menuData, setMenuData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [menuProperties, setMenuProperties] = useState<any>({});
	const [showIcon, setShowIcon] = useState(false);

	const {
		token: { colorBgContainer },
	} = theme.useToken();

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const fetchData = async () => {
		setLoading(true);

		await getAPI(`/menus/menu-json/${application_uid}`).then(async (res: any) => {
			if (res.data.success) {
				const {data = []}: any = res.data;
				if (!isEmpty(data)) {
					setMenuData(data);
					setMenuId(data[0].menuUid);
					setMenuName(data[0].menuName);
					setMenuValues(JSON.parse(data[0].menuJson));
					setMenuProperties(JSON.parse(data[0].menuStyles));
				}
			} else {
				message.error('Something went wrong');
			}

			setLoading(false);
		});
	};

	const menuRecursion = (menus: any): any =>
		(menus.map((menu: any, index: number) => {
			const {icon, text} = menuProperties;
			if (!isEmpty(menu.sub_menu_item)) {
				return (
					<SubMenu key={menu.menu_item_uid}
						title={
							<div>
								<div>
									<div style={{display: 'flex', gap: '10px'}}>
										{
											!isEmpty(menu.menu_item_icon) && (
												<span style={{...icon}}>
													<Icon
														type={
															menu.menu_item_icon
														}
													/>
												</span>
											)
										}
										<span style={{...text, width: '100%'}}>
											{menu.menu_item_name}
										</span>
									</div>
								</div>
							</div>
						}>
						{menuRecursion(menu.sub_menu_item)}
					</SubMenu>
				);
			}

			return (
				<Item key={menu.menu_item_uid}>
					<div>
						<Link
							to={`/${menu.menu_item_route}`}>
							<div style={{display: 'flex', gap: '10px'}}>
								{
									!isEmpty(menu.menu_item_icon) && (
										<span style={{...icon}}>
											<Icon type={menu.menu_item_icon} />
										</span>
									)
								}
								<span style={{...text, width: '100%'}}>
									{menu.menu_item_name}
								</span>
							</div>
						</Link>
					</div>
				</Item>
			);
		}));

	const handleLogo = (): any => {
		setPropertyType('logo');
	};

	const handleMenu = ():any => {
		setPropertyType('menu');
		if (isEmpty(menuValues)) {
			navigate(`/menus/${application_uid}`);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);
	return (
		<Layout className='layout layout1'>
			{show_header && (
				<Header style={{height: header_height || 64}}>
					<Row className='header-row'style={{height: '100%'}}>
						<div className='logo'
							onClick={handleLogo}
							style={{
								width: logo_width === null ? '15%'
									: `${logo_width}%`,
								height: logo_height === null ? '100%'
									: `${logo_height}%`,
								...logo_style}}>
							{ app_logo === ''
								? <p className='logo-text'>Add Logo</p>
								: <Image src={app_logo}
									preview= {false}
									style={{width: '100%', height: '100%'}}
									alt='Logo'/>}
						</div>
						{show_header_menu && <div
							className='menu'
							style={{width: menu_width === null ? '85%'
								: `${menu_width}%`,
							...menu_style}}
							onClick={handleMenu}
							onMouseOver = {
								(event: React.MouseEvent<HTMLInputElement>) => {
									event.stopPropagation();
									setShowIcon(true);
								}
							}
							onMouseOut = {
								(event: React.MouseEvent<HTMLInputElement>) => {
									event.stopPropagation();
									setShowIcon(false);
								}
							}
						>{ isEmpty(menuValues) ? 'Add Menu'
								: <>
									<Popover
										placement='bottom'
										trigger='hover'
										style={{display: showIcon
											? 'block' : 'none'}}
										content={(
											<div style={{display: 'flex',
												justifyContent: 'space-between',
												gap: '15px',
												cursor: 'pointer'}}>
												<span>
													<Tooltip
														title='Edit Menu'
														placement='topLeft'>
														<PlusOutlined onClick={
															() =>
																navigate(`/menus/${application_uid}`)
														} />
													</Tooltip>
												</span>
											</div>
										)}>
										<Menu
											mode='horizontal'
											className='menu'
											theme={menuProperties.theme === ''
												? 'light'
												: menuProperties.theme}
											style={{height: '100%'}}
										> { menuRecursion(menuValues) }
										</Menu>
									</Popover>
								</>}
						</div> }
					</Row>
				</Header>
			)}
			<Content
				style={{
					padding: '0 50px',
					margin: 0,
					minHeight: 280,
					background: 'rgb(237 237 237)',
				}}
				onClick={() =>
					navigate(`/pages/${application_uid}`)}
			>
				{show_breadcrumb && <Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Home</Breadcrumb.Item>
					<Breadcrumb.Item>List</Breadcrumb.Item>
					<Breadcrumb.Item>App</Breadcrumb.Item>
				</Breadcrumb> }
				<div
					className='site-layout-content'
					style={{ background: colorBgContainer }}
				>
                    Content
				</div>
			</Content>
			{show_footer && (
				<Footer >
                  Footer
				</Footer>
			)}
		</Layout>
	);
};

export default Layout1;

import {Input} from 'antd';
import React, {useState, Dispatch, SetStateAction, FC} from 'react';

type DataFieldProps = {
	setChangeType: Dispatch<SetStateAction<any>>;
	// eslint-disable-next-line max-len
	setValue: Dispatch<SetStateAction<string | number | string[] | null | boolean>>;
	type: string;
	activeElement: any;
}

const DataField: FC<DataFieldProps> = ({
	setChangeType,
	setValue,
	activeElement,
	type,
}) => {
	const {
		data_field = '',
		data_source = '',
	} = activeElement.element[`${type}_properties`];
	const [dataField, setDataField] = useState(data_field);
	// eslint-disable-next-line no-unused-vars
	const [dataSource, setDataSource] = useState(data_source);
	const handleChange = (value: string, type: string): void => {
		if (type) {
		// eslint-disable-next-line default-case
			switch (type) {
				case 'data_source': setDataSource(value);
					break;
				case 'data_field': setDataField(value);
					break;
			}

			setValue(value);
			setChangeType(type);
		}
	};

	return (
		<>
			{/* <table>
				<tbody>
					<tr>
						<td colSpan={2} style={{padding: '0'}}>
							<thead>
								<tr>
									<th colSpan={2}>
										<p>Data Source</p>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colSpan={2}>
										<Input
											placeholder='Data Source....'
											value={dataSource}
											// eslint-disable-next-line max-len
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'data_source')}
										/>
									</td>
								</tr>
							</tbody>
						</td>
					</tr>
				</tbody>
			</table> */}
			<table>
				<tbody>
					<tr>
						<td colSpan={2} style={{padding: '0'}}>
							<thead>
								<tr>
									<th colSpan={2}>
										<p>Data Field</p>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td colSpan={2}>
										<Input
											value={dataField}
											placeholder='Data Field....'
											// eslint-disable-next-line max-len
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'data_field')}
										/>
									</td>
								</tr>
							</tbody>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default DataField;
